import React from "react";

import withContexts from "@/scripts/withContext.js";

import Button from "@/components/button/Button.js";

import styles from "./FacetActions.module.sass";

const FacetActionsItem = ({ langContext: { dict }, label, clickAction, disabled }) => {
	return (
		<li className={styles["actions__item"]}>
			<Button
				variant="link"
				onClick={() => clickAction()}
				className={styles["actions__button"]}
				disabled={disabled}
			>
				{label}
			</Button>
		</li>
	);
};

export default withContexts(FacetActionsItem);
