import React from 'react';

import withContexts from '@/scripts/withContext.js';

import styles from '@/components/pagination/Pagination.module.sass';

const PaginationListItem = ({
  pageItem,
  currentPage,
  setPageByNum,
  langContext: { dict }
}) => (
  <li className={styles['pagination__list-item']}>
    {typeof pageItem === 'number' ? (
      <button
        onClick={() => setPageByNum(pageItem)}
        className={`${styles['pagination__item-button']} ${
          currentPage === pageItem
            ? styles['pagination__item-button--active']
            : ''
        }`}
        title={
          currentPage === pageItem
            ? dict.pagination.currentPage
            : dict.pagination.pageNumber
        }
        disabled={currentPage === pageItem}
      >
        {pageItem}
      </button>
    ) : (
      <span className={styles['pagination__item-separator']}>{pageItem}</span>
    )}
  </li>
);

export default withContexts(PaginationListItem);
