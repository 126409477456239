import React, { Component } from "react";
import PropTypes from "prop-types";
import "./AutoInput.module.sass";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";

import styles from "@/components/autoInput/AutoInput.module.sass";

class AutoInput extends Component {
	static propTypes = {
		disabled: PropTypes.bool,
		variant: PropTypes.string,
		customClass: PropTypes.string,
		placeholder: PropTypes.string,
		customError: PropTypes.object,
		label: PropTypes.string,
		type: PropTypes.string,
		suggestions: PropTypes.array,
		getAutocompleteList: PropTypes.func.isRequired
	};

	wrapperRef = React.createRef();
	inputRef = React.createRef();
	listItemRef = React.createRef();

	componentDidMount() {
		document.addEventListener("click", this.handleClick);
		this.wrapperRef.current.addEventListener("keydown", this.handleKeyPress);
		this.inputRef.current.addEventListener("keydown", this.handleKeyPressInput);
	}

	componentWillUnmount() {
		const { resetSuggestionsList } = this.props;

		resetSuggestionsList();

		document.removeEventListener("click", this.handleClick);
		this.wrapperRef.current.removeEventListener("keydown", this.handleKeyPress);
		this.inputRef.current.removeEventListener(
			"keydown",
			this.handleKeyPressInput
		);
	}

	shouldComponentUpdate(nextProps) {
		return (
			nextProps.input.value !== this.props.input.value ||
			nextProps.suggestions !== this.props.suggestions
		);
	}

	handleClick = e => {
		const { resetSuggestionsList } = this.props;

		if (
			this.wrapperRef.current &&
			!this.wrapperRef.current.contains(e.target)
		) {
			resetSuggestionsList();
		}
	};

	handleKeyPress = e => {
		const { resetSuggestionsList } = this.props;

		if (e.keyCode === 27) {
			resetSuggestionsList();
			this.inputRef.current.focus();
		}
	};

	handleKeyPressInput = e => {
		if (e.keyCode === 40) {
			e.preventDefault();
			this.listItemRef.current.focus();
		}
	};

	setInputValue = item => {
		const { input, resetSuggestionsList } = this.props;

		resetSuggestionsList();
		input.onChange(item.replace(/<[^>]*>/gi, ""));
		this.inputRef.current.focus();
	};

	render() {
		const {
			disabled = false,
			variant = "primary",
			input,
			label,
			type,
			placeholder,
			customError = "",
			customClass,
			getAutocompleteList,
			suggestions,
			autofocus,
			meta: { touched, error, submitFailed }
		} = this.props;

		const showInputList = suggestions && !!suggestions.length && !!input.value;

		return (
			<div
				className={`${styles["auto-input"]} ${customClass} ${styles[variant]}`}
				ref={this.wrapperRef}
			>
				<Form.Label className={styles["auto-input__label"]}>
					<span className={styles["auto-input__label-span"]}>
						{label}
					</span>
					<Form.Control
						{...input}
						type={type}
						ref={this.inputRef}
						placeholder={placeholder}
						onChange={e => {
							getAutocompleteList(e.target.value);
							input.onChange(e);
						}}
						className={styles["auto-input__form-control"]}
						disabled={disabled}
						autoFocus={autofocus} />
				</Form.Label>
				{touched && error && submitFailed && (
					<div className={styles["auto-input__field-error"]}>
						{customError[error]}
					</div>
				)}
				<Dropdown show={showInputList}>
					<Dropdown.Menu className={styles["auto-input__list"]}>
						{suggestions &&
							suggestions.map((item, index) => (
								<Dropdown.Item
									key={item + index}
									href="#"
									ref={index === 0 ? this.listItemRef : null}
									onClick={() => this.setInputValue(item)}
									className={styles["auto-input__list-button"]}
									dangerouslySetInnerHTML={{
										__html: item
									}}
								></Dropdown.Item>
							))}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	}
}

export default AutoInput;
