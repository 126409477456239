import React from "react";
import withContexts from "../../scripts/withContext.js";
import styles from "@/components/search/Search.module.sass";

import logo from "@/assets/images/search/logo_uwr.svg";

const SearchMainHeader = ({ langContext: { dict } }) => {
	return (
		<div className={styles["search__header"]}>
			<div className={styles["search__logo"]}>
				<img src={logo} alt={dict.search.image_alt} />
			</div>
			<h1 className={styles["search__motto"]}>
				<span>{dict.search.title}</span>
			</h1>
		</div>
	);
};

export default withContexts(SearchMainHeader);
