import React from "react";

import withContexts from "@/scripts/withContext.js";

import Button from "@/components/button/Button.js";

import styles from "./FacetChosen.module.sass";

const FacetChosenItem = ({ facetValue, facetMissing, langContext: { dict }, removeAction, changeMissingTime, index, facetName }) => {
	const prefix = facetName === "time" ? (index === 0 ? "from" : "to") : null;
	const facetValueDisplay =
		prefix && facetValue.startsWith("-") ? `${facetValue.substr(1)} ${dict.facets.form.era.bc}` : facetValue;

	return (
		<li className={styles["chosen__list-item"]}>
			<span className={styles["chosen__list-item--text"]}>
				{!facetMissing && prefix && <span>{dict.facets.form[prefix]}: </span>}
				{dict.facets.labels[facetValueDisplay] ? dict.facets.labels[facetValueDisplay] : facetValueDisplay}
			</span>
			<Button
				variant="transparent"
				className={styles["chosen__list-item--delete"]}
				onClick={() => {
					if (facetMissing) changeMissingTime(true);
					removeAction(facetValue, facetName, prefix ? prefix : null);
				}}
			>
				<i className="icon-close" />
			</Button>
		</li>
	);
};

export default withContexts(FacetChosenItem);
