function parseQuery(query) {

    const arrayQ = query.split(' AND ').join('|||AND|||').split(' OR ').join('|||OR|||').split('|||');
    const parseQ = [];
    
    for (let i = 0; i < arrayQ.length; i++) {
        const singleQ = {};

        if (arrayQ[i].includes(':')) {
            const splitSingleQ = arrayQ[i].split(':');
            const type = splitSingleQ[0];
            const value = splitSingleQ[1].substring(1, splitSingleQ[1].length - 1);
            const conj = (arrayQ[i-1]) ? arrayQ[i-1] : null;
            
            if (type === 'time') {
                const date = value.split('TO');

                singleQ['dateFromBC'] = date[0].includes('-');
                singleQ['dateFrom'] = date[0].replace('-', '');
                singleQ['dateToBC'] = date[1].includes('-');
                singleQ['dateTo'] = date[1].replace('-', '');
            } else {
                singleQ['value'] = value;
            }

            singleQ['type'] = type;
            singleQ['conj'] = conj;

            parseQ.push(singleQ);

        } else if (!(arrayQ[i].includes('AND') || arrayQ[i].includes('OR'))) {
            singleQ['type'] = 'allFields';
            singleQ['value'] = arrayQ[i];
            singleQ['conj'] = (arrayQ[i-1]) ? arrayQ[i-1] : null;
            parseQ.push(singleQ);
        }
    }

    return parseQ;
}

export default parseQuery;
