import React from "react";
import Form from "react-bootstrap/Form";

import withContexts from "@/scripts/withContext.js";

import styles from "./FacetSearch.module.sass";

const FacetSearchInput = ({ disabled = false, input, label, placeholder }) => {
	return (
		<>
			<Form.Label htmlFor={input.name} srOnly>
				{label}
			</Form.Label>
			<Form.Control
				{...input}
				type="text"
				id={input.name}
				placeholder={placeholder}
				onChange={e => {
					input.onChange(e);
				}}
				className={styles["search-form__input"]}
				disabled={disabled}
			/>
		</>
	);
};

export default withContexts(FacetSearchInput);
