import React, { Component } from "react";
import { change, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import withContexts from "@/scripts/withContext.js";
import { initialValues, getCurrentFacet, getCurrentFacetValue, replacerReg } from "./utils/formsService";
import debounce from "@/scripts/debounce.js";

import Dropdown from "react-bootstrap/Dropdown";

import FacetForm from "./FacetForm/FacetForm";
import FacetChosen from "./FacetChosen/FacetChosen";
import FacetSearch from "./FacetSearch/FacetSearch.js";

import styles from "./Facet.module.sass";

class FacetRegular extends Component {
	state = {
		active: false,
		currentFilter: ""
	};

	componentDidUpdate(prevProps) {
		const { location, facet, change } = this.props;

		if (prevProps.location.search !== location.search) {
			this.setState({ currentFilter: "" });
			change(`${facet}Search`, "key", "");
		}
	}

	submitFacetForm = value => {
		const { setFacet, facet } = this.props;

		const facetValue = Object.keys(value).reduce((arr, item) => {
			const regex = new RegExp(replacerReg, "g");
			const newItem = item.replace(regex, ".");
			return value[item] ? [...arr, newItem] : arr;
		}, []);

		setFacet({ [facet]: facetValue });
		this.setState({ active: false });
	};

	removeFacetForm = (facetValue, facetName) => {
		const { facet, setFacet, resetFacet, searchFacets } = this.props;
		const currentFacet = getCurrentFacet(searchFacets, facetName);

		const filteredArr = currentFacet[`f.${facetName}`].filter(item => item !== facetValue);

		if (filteredArr.length) {
			setFacet({ [facet]: filteredArr });
		} else {
			resetFacet(facet);
		}
	};

	toggleDropdown = () => {
		this.setState(state => ({
			active: !state.active
		}));
	};

	setCurrentFilter = debounce(value => {
		if (this.state.currentFilter !== value) {
			this.setState({
				currentFilter: value.key
			});
		}
	}, 500);

	selectAll = () => {
		const { facet, value, change } = this.props;
		const { currentFilter } = this.state;
		const currentFacetValue = getCurrentFacetValue(currentFilter, value);

		currentFacetValue.forEach(item => {
			change(facet, item.name, true);
		});
	};

	resetAll = () => {
		const { facet, value, change } = this.props;
		const { currentFilter } = this.state;
		const currentFacetValue = getCurrentFacetValue(currentFilter, value);

		currentFacetValue.forEach(item => {
			change(facet, item.name, false);
		});
	};

	render() {
		const {
			langContext: { dict },
			facet,
			value,
			searchFacets,
			resetFacet,
			values
		} = this.props;

		const { currentFilter, active } = this.state;
		const currentFacet = getCurrentFacet(searchFacets, facet);
		const currentFacetValue = getCurrentFacetValue(currentFilter, value);

		const isAllSelected =
			values && Object.keys(values).filter(item => values[item]).length === Object.keys(value).length;
		const isAllUnselected =
			!values || !Object.keys(values).length || !Object.keys(values).filter(item => values[item]).length;

		return (
			<div className={styles["facet"]}>
				<Dropdown show={active} onToggle={this.toggleDropdown} className={styles["facet__dropdown"]}>
					<Dropdown.Toggle
						className={styles["facet__toggle"]}
						variant="facet"
						disabled={!value || !value.length}
						id="facet"
					>
						{dict.fields[facet]}
					</Dropdown.Toggle>
					<Dropdown.Menu className={styles["facet__menu"]} >

							<FacetSearch onSubmit={this.setCurrentFilter} form={`${facet}Search`} />
							{!!currentFacetValue.length && (
								<FacetForm
									onSubmit={this.submitFacetForm}
									form={facet}
									initialValues={initialValues(currentFacet, facet)}
									selectAll={this.selectAll}
									resetAll={this.resetAll}
									facetItem={currentFacetValue}
									isAllSelected={isAllSelected}
									isAllUnselected={isAllUnselected}
									isFacetSet={
										currentFacet && currentFacet[`f.${facet}`] && currentFacet[`f.${facet}`].length
									}
								/>
							)}
							{!currentFacetValue.length && (
								<div className={styles["facet__no-facet"]}>{dict.facets.no_facets}</div>
							)}
						
					</Dropdown.Menu>
					<FacetChosen
						removeFacetForm={this.removeFacetForm}
						clearFacetForm={() => resetFacet(facet)}
						facetName={facet}
						facetValues={currentFacet}
					/>
				</Dropdown>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	values: getFormValues(ownProps.facet)(state)
});

export default withRouter(withContexts(connect(mapStateToProps, { change })(FacetRegular)));
