import React from "react";
import { withRouter } from "react-router";
import Dropdown from "@/components/dropdown/Dropdown";

import withContexts from "@/scripts/withContext.js";
import menuPrefixes from "@/scripts/indexesPrefix.js";

import styles from "./IndexesPrefix.module.sass";

const IndexesPrefix = ({ langContext: { dict }, setPrefix, currentPrefix }) => {
    const menuPrefixesWithLabels = menuPrefixes.map(prefix => {
        return {
            ...prefix,
            label: prefix.name === "all" ?
                dict.indexes.literals
                : prefix.name
        }
    });

    const filteredPrefixes = menuPrefixesWithLabels.filter(prefix =>
        (prefix.name === "all" && currentPrefix)
        || (prefix.name !== "all" && currentPrefix !== prefix.name));

    return (
        <div className={styles.prefix}>
            <Dropdown
                options={filteredPrefixes}
                id="prefixDropdown"
                variant="regular"
                toggle={currentPrefix ? currentPrefix : dict.indexes.literals}
                triggerAction={setPrefix}
            />
        </div>
    );
}


export default withContexts(withRouter(IndexesPrefix));
