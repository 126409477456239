import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Breadcrumbs.module.sass';

const Breadcrumbs = ({ breadcrumbsList }) => {
  const getBreadcrumbsLink = (item, index) => {
    if (item.label === 'home') {
      return (
        <li key={index} className={`${styles['breadcrumbs__list-item']}`}>
          <Link
            to={'/'}
            className={`${styles['breadcrumbs__list-item--home']}`}
          >
            <span>Home</span>
            <i className="icon-home"></i>
          </Link>
        </li>
      );
    }

    return (
      <li key={index} className={`${styles['breadcrumbs__list-item']}`}>
        {item.link ? (
          <Link
            to={item.link}
            className={`${styles['breadcrumbs__list-item--link']}`}
          >
            {item.label}
          </Link>
        ) : (
          <span className={`${styles['breadcrumbs__list-item--label']}`}>
            {item.label}
          </span>
        )}
      </li>
    );
  };

  return (
    <div className={`${styles['breadcrumbs']}`}>
      <ul className={`${styles['breadcrumbs__list']}`}>
        {breadcrumbsList.map((breadcrumbsListItem, index) =>
          breadcrumbsListItem
            ? getBreadcrumbsLink(breadcrumbsListItem, index)
            : false
        )}
      </ul>
    </div>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any.isRequired,
      link: PropTypes.string
    })
  )
};

export default Breadcrumbs;
