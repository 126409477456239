import React, { useState } from "react";

import styles from "@/containers/Results/Results.module.sass";

const ItemImg = ({thumb, title, url}) => {

    const [imgError, setImgError] = useState(false)

    const Img = () => {
        return <div
                    className={styles["results__item-img"]}
                    {...(thumb ? {style: { backgroundImage: `url(${thumb})`}} : {})}
                >
                    
                    {thumb && 		
                        <img src={thumb} 
                            alt={title}
                            onError={
                                () => {
                                    setImgError(true)
                                }
                            }
                        />
                    }

                    {(!thumb || imgError) &&
                        <>
                            <span className="d-none">{title}</span>
                            <i className="icon-image"></i
                        ></>
                    }
                    
                </div>
    }

    return url ? (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer" 
        >
            
            {Img()}

        </a>
    ) : Img();
}

export default ItemImg