import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as Actions from "@/actions";
import withContexts from "@/scripts/withContext.js";
import {
	setSearchParams,
	setUrlFromParams,
	setPageSize,
	setPageByNum,
	setFacet,
	setCategory,
	resetCategory,
	resetFacet,
	resetAllFacets,
	resetQuery
} from "@/scripts/resultsService.js";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import ResultsTitle from "@/containers/Results/ResultsTitle.js";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import ResultsSidebar from "@/containers/Results/ResultsSidebar/Sidebar.js";
import ResultsMain from "@/containers/Results/ResultsMain.js";
import ResultsNoResults from "@/containers/Results/ResultsNoResults.js";

import loadingImg from '@/assets/images/loading.svg'

import styles from "@/containers/Results/Results.module.sass";

class Results extends Component {
	static propTypes = {
		actions: PropTypes.object,
		location: PropTypes.object,
		searchParams: PropTypes.shape({
			q: PropTypes.string,
			page: PropTypes.number,
			pageSize: PropTypes.number
		}),
		isFetching: PropTypes.bool,
		searchCategory: PropTypes.string,
		searchFacets: PropTypes.arrayOf(PropTypes.object),
		totalPages: PropTypes.number,
		results: PropTypes.arrayOf(PropTypes.object),
		facets: PropTypes.arrayOf(PropTypes.object)
	};

	componentDidMount() {
		const {
			location: { search }
		} = this.props;

		this.loadResults(search);
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props;

		if (location.search !== prevProps.location.search) {
			this.loadResults(location.search);
		}
	}

	componentWillUnmount() {
		const { actions } = this.props;

		actions.resetSearchParams();
	}

	loadResults = searchLocation => {
		const { actions, searchParams } = this.props;
		const newSearchParams = setSearchParams(searchLocation, searchParams);

		actions.setSearchParams({ ...newSearchParams });

		const searchUrl = setUrlFromParams({
			...newSearchParams,
			params: {
				...newSearchParams.params,
				page: newSearchParams.params.page - 1
			}
		});

		actions.loadResults(searchUrl);
	};

	updateUrl = newParams => {
		const { history } = this.props;
		const searchUrl = setUrlFromParams(newParams);

		history.push({
			pathname: "/results",
			search: `${searchUrl}`
		});
	};

	setPageSize = size => {
		const { searchParams, searchCategory, searchFacets } = this.props;
		setPageSize(size, this.updateUrl, {
			searchParams,
			searchCategory,
			searchFacets
		});
	};

	setPageByNum = num => {
		const { searchParams, searchCategory, searchFacets } = this.props;

		setPageByNum(num, this.updateUrl, {
			searchParams,
			searchCategory,
			searchFacets
		});
	};

	setFacet = (facet, missing) => {
		const { searchParams, searchCategory, searchFacets } = this.props;

		setFacet(facet, this.updateUrl, {
			searchParams,
			searchCategory,
			searchFacets
		}, missing);
	};

	resetFacet = facet => {
		const { searchParams, searchCategory, searchFacets } = this.props;

		resetFacet(facet, this.updateUrl, {
			searchParams,
			searchCategory,
			searchFacets
		});
	};

	resetAllFacets = () => {
		const { searchParams, searchCategory } = this.props;

		resetAllFacets(this.updateUrl, {
			searchParams,
			searchCategory
		});
	};

	setCategory = obj => {
		const { searchParams } = this.props;

		setCategory(obj, this.updateUrl, {
			searchParams
		});
	};

	resetCategory = () => {
		const { searchParams } = this.props;

		resetCategory(this.updateUrl, {
			searchParams
		});
	};

	resetQuery = () => {
		resetQuery(this.updateUrl);
	};

	render() {
		const {
			results,
			langContext: { dict },
			searchParams,
			searchCategory,
			searchFacets,
			totalPages,
			category,
			facets,
			count,
			links,
			isFetching
		} = this.props;

		const breadcrumbsList = [{ label: "home" }, { label: [dict.results.search_results] }];

		return (
			<div className={styles["results"]}>
				<Container>
					<Row>
						<Breadcrumbs breadcrumbsList={breadcrumbsList} />
						<ResultsTitle />
					</Row>
				</Container>
				<hr className={styles["results__line"]} />

				{isFetching ? ( 
					<div className={styles["results__loading"]}>
						<div>
							<img src={loadingImg}/>
						</div>
					</div>
				) : (
				<Container>
					<Row className={styles["results__content"]}>
						<div className={styles["results__sidebar"]}>
							<ResultsSidebar
								searchParams={searchParams}
								searchCategory={searchCategory}
								searchFacets={searchFacets}
								facets={facets}
								category={category}
								count={count}
								links={links}
								setFacet={this.setFacet}
								setCategory={this.setCategory}
								resetFacet={this.resetFacet}
								resetCategory={this.resetCategory}
								resetAllFacets={this.resetAllFacets}
								isFetching={isFetching}
								resetQuery={this.resetQuery}
							/>
						</div>
						
							
						{results.length ? (
							<ResultsMain
								results={results}
								setPageByNum={this.setPageByNum}
								setPageSize={this.setPageSize}
								searchParams={searchParams}
								totalPages={totalPages}
							/>
						) : (
							<ResultsNoResults />
						)}
							
						
					</Row>
				</Container>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	results: state.results.results,
	facets: state.results.facets,
	category: state.results.category,
	count: state.results.count,
	links: state.results.links,
	isFetching: state.results.isFetching,
	searchParams: state.search.params,
	searchCategory: state.search["f.category"],
	searchFacets: state.search.facets,
	totalPages: state.search.totalPages
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch)
});

export default withContexts(withRouter(connect(mapStateToProps, mapDispatchToProps)(Results)));
