import React from "react";
import Form from "react-bootstrap/Form";

import Checkbox from "react-simple-checkbox";

import withContexts from "@/scripts/withContext";

import styles from "@/containers/Results/ResultsSidebar/facets/Facet/FacetForm/FacetFormDateFields/FacetFormDateFields.module.sass";

const DateCheckbox = ({ input, label, actionChange, count, meta, meta: { touched, error, submitFailed }, langContext: { dict } }) => (
	<div className={styles["checkbox"]}>
		<Checkbox
			{...input}
			id={label}
			color={{ backgroundColor: "#fff" }}
			checked={input.value}
			onChange={() => { 
				actionChange(input.value)
				input.onChange(!input.value)
			}}
			className="checkbox--light"
			label={label}
			size="2"
		/>
		<Form.Label htmlFor={label} className={styles["checkbox__label"]} onClick={() => input.onChange(input.value)}>
			<span className={styles["checkbox__label--text"]}>{label}</span>
			<span className={styles["checkbox__label--count"]}>{count}</span>
		</Form.Label>
		{touched && error && submitFailed && (
			<span className={styles["date__error"]}>{dict.facets.form.errors[error]}</span>
		)}
	</div>
);

export default withContexts(DateCheckbox);
