import React from 'react';

import withContexts from '@/scripts/withContext.js';

import styles from '@/components/pagination/Pagination.module.sass';

const PaginationPrevious = ({ setPreviousPage, langContext: { dict } }) => (
  <button
    className={styles['pagination__previous']}
    onClick={() => setPreviousPage()}
    title={dict.pagination.prevTitle}
  >
    <span>{dict.pagination.prev}</span>
  </button>
);

export default withContexts(PaginationPrevious);
