import React from "react";
import { Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";

import withContexts from "../../scripts/withContext.js";

import Button from "../../components/button/Button.js";
import AdvancedSearchFormQueryField from "./AdvancedSearchFormQueryField.js";
import AdvancedSearchFormQuerySelect from "./AdvancedSearchFormQuerySelect.js";

import styles from "./AdvancedSearch.module.sass";

const AdvancedSearchFormFieldset = ({
	fields,
	conjOptions,
	types,
	era,
	typeFieldVal,
	suggestions,
	getAutocompleteList,
	resetAdvancedSearchSuggestions,
	langContext: { dict }
}) => {
	const addNewField = () => {
		fields.push({
			type: types[fields.length],
			query: "",
			conj: "and"
		});
	};

	return (
		<ul className={styles["advanced-search__array"]}>
			{fields.map((member, index) => (
				<li key={index} className={styles["advanced-search__field"]}>
					<div className={styles["advanced-search__field--inner"]}>
						<div className={styles["advanced-search__field--type"]}>
							<Field
								name={`${member}.type`}
								component={AdvancedSearchFormQuerySelect}
								label={dict.advanced_search.form.type}
								options={types}
								dict={dict.advanced_search.form.types}
							/>
						</div>
						<div className={styles["advanced-search__field--query-conj"]}>
							<div className={styles["advanced-search__field--query"]}>
								<AdvancedSearchFormQueryField
									type={typeFieldVal[index]}
									member={member}
									suggestions={
										suggestions[index]
											? suggestions[index][typeFieldVal[index]]
											: null
									}
									era={era}
									getAutocompleteList={prefix => 
										getAutocompleteList(prefix, fields.get(index).type, index)						
									}
									resetAdvancedSearchSuggestions={() =>
										resetAdvancedSearchSuggestions(index)
									}
								/>
							</div>
							<div className={styles["advanced-search__field--conj"]}>
								{index !== fields.length - 1 && (
									<Field
										name={`${member}.conj`}
										component={AdvancedSearchFormQuerySelect}
										label={dict.advanced_search.form.conj}
										options={conjOptions}
										dict={dict.advanced_search.form.conjs}
									/>
								)}
							</div>
						</div>
					</div>
					{member.type}
					{fields.length > 1 && (
						<Button
							type="button"
							title="Remove Member"
							onClick={() => fields.remove(index)}
							variant="link"
							className={styles["advanced-search__field-button"]}
						>
							{dict.advanced_search.form.remove}
						</Button>
					)}
				</li>
			))}
			<li className={styles["advanced-search__add"]}>
				{fields.length < 10 && (
					<Button
						onClick={() => addNewField()}
						className={styles["advanced-search__add-button"]}
					>
						<span className="icon-add"></span>
						{dict.advanced_search.form.add}
					</Button>
				)}
			</li>
		</ul>
	);
};

const mapStateToProps = (state, ownProps) => {
	const selector = formValueSelector(ownProps.meta.form);
	const getFieldTypeSelector = name => selector(state, `${name}.type`);

	return {
		typeFieldVal: ownProps.fields
			.getAll()
			.map((e, index) =>
				getFieldTypeSelector(`${ownProps.fields.name}[${index}]`)
			)
	};
};

export default connect(mapStateToProps)(
	withContexts(AdvancedSearchFormFieldset)
);
