import React from "react";

import withContexts from "@/scripts/withContext.js";

import styles from "@/containers/Results/Results.module.sass";

const ResultsTitle = ({ langContext: { dict } }) => (
	<h1 className={styles["results__title"]}>{dict.results.search_results}</h1>
);
export default withContexts(ResultsTitle);
