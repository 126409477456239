import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import withContexts from '@/scripts/withContext.js';

import "./Error.sass";

const Error = ({
        langContext: {dict}
    }) => (
        <Container>
            <Row>
                <div className="error">
                    <h3 className="error__header">{dict.error.header}</h3>
                    <h2 className="error__code">{dict.error.code}</h2>
                    <p className="error__causes">{dict.error.causes}</p>
                    <ul className="error__causes-list">
                        <li className="error__causes-list--item">{dict.error.cause.first}</li>
                        <li className="error__causes-list--item">{dict.error.cause.second}</li>
                        <li className="error__causes-list--item">{dict.error.cause.third}</li>
                    </ul>
                    <p className="error__apologize">{dict.error.apologize}</p>
                    <div>
                        <Link to={'/'} className="error__back">{dict.error.back}</Link>
                    </div>
                </div>
            </Row>
        </Container>        
    );
  
  export default withContexts(Error);