import {
	validateDateRange,
	isNotInRange,
	isNotProperFormat
} from "./dateRangeValidation.js";

export default function validate(values) {
	const errors = {};

	if (values.searchQueries) {
		if (
			values.searchQueries.filter(elem => !elem.to && !elem.from && !elem.query)
				.length === values.searchQueries.length
		) {
			errors._error = "no_fields";
		} else {
			const queryArrayErrors = [];

			values.searchQueries.forEach((field, index) => {
				const fieldErrors = {};
				const { type, to, from, timeline } = field;

				if (type === "time" && !timeline) {
					if (to && isNotInRange(to)) {
						fieldErrors.to = isNotInRange(to);
					}
					if (to && isNotProperFormat(to)) {
						fieldErrors.to = isNotProperFormat(to);
					}
					if (from && isNotInRange(from)) {
						fieldErrors.from = isNotInRange(from);
					}
					if (from && isNotProperFormat(from)) {
						fieldErrors.from = isNotProperFormat(from);
					}
					if (to && from && validateDateRange(field)) {
						fieldErrors.from = validateDateRange(field);
					}
				}

				if (Object.keys(fieldErrors).length) {
					queryArrayErrors[index] = fieldErrors;
				}
			});

			if (queryArrayErrors.length) {
				errors.searchQueries = queryArrayErrors;
			}
		}
	}

	return errors;
}
