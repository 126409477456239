import Main from "../containers/Main/Main.js";
import GetToKnowLeopoldina from "../containers/GetToKnowLeopoldina/GetToKnowLeopoldina.js";
import Indexes from "../containers/Indexes/Indexes.js";
import AdvancedSearch from "../containers/AdvancedSearch/AdvancedSearch.js";
import { Redirect } from "react-router-dom";
import Results from "../containers/Results/Results.js";
import Error from "@/components/error/Error.js";

export default [
	{
		id: "main",
		path: "/",
		exact: true,
		component: Main
	},
	{
		id: "getToKnowLeopoldina",
		path: "/get-to-know-leopoldina",
		component: Redirect,
		exact: true,
		to: "/get-to-know-leopoldina/about-project"
	},
	{
		id: "get-to-know-leopoldina",
		path: "/get-to-know-leopoldina/:type",
		exact: true,
		component: GetToKnowLeopoldina
	},
	{
		id: "results",
		path: "/results",
		exact: true,
		component: Results
	},
	{
		id: "index",
		path: "/index/:type",
		exact: true,
		component: Indexes
	},
	{
		id: "advanced-search",
		path: "/advanced-search",
		exact: true,
		component: AdvancedSearch
	},
	{
		id: "error",
		component: Error
	}
];
