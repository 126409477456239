import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import * as wpScripts from "@/scripts/wpObjectScripts.js";

import styles from "./GetToKnowLeopoldina.module.sass";
import GetToKnowLeopoldinaTable from "./GetToKnowLeopoldinaTable.js";

const GetToKnowLeopoldinaSubpage = ({ pageData }) => {
	if (!pageData.content) {
		return null;
	}

	const list = pageData !== undefined ? pageData.acf : [];

	const items = pageData ? wpScripts.getItems(list) : [];
	const tableList = items.map((listItem, index) => {
		return <GetToKnowLeopoldinaTable {...listItem} key={index} pageData={pageData} />;
	});

	return (
		<article className={styles.app__article}>
			<h3 className={styles["app__article-title"]}>{pageData && pageData.title.rendered}</h3>
			<div
				className={styles["app__article-content"]}
				dangerouslySetInnerHTML={{
					__html: pageData && pageData.content.rendered
				}}
			></div>
			{Object.entries(pageData.acf).length !== 0 && (
				<div className={styles["app__table"]}>
					<h3 className={styles["app__table-title"]}>{pageData.acf.leopoldina_table_title}</h3>
					<div className={styles["app__table"]}>
						<div className={styles["app__table-header"]}>
							<span className={styles["app__table-header--column"]}>
								{pageData.acf.leopoldina_column_1_title}
							</span>
							<span className={styles["app__table-header--column"]}>
								{pageData.acf.leopoldina_column_2_title}
							</span>
							{pageData.acf.leopoldina_column_3_title && (
								<span className={styles["app__table-header--column"]}>
									{pageData.acf.leopoldina_column_3_title}
								</span>
							)}
						</div>

						<ul className={styles["app__table-content"]}>{tableList}</ul>
					</div>
				</div>
			)}
		</article>
	);
};

export default withRouter(connect()(GetToKnowLeopoldinaSubpage));
