import moment from "moment";

export const isNormalExceeding = (dateFrom, dateTo) => {
	return moment(dateTo, "Y.MM.DD") < moment(dateFrom, "Y.MM.DD");
};

export const isEqual = (dateFrom, dateTo) => {
	return moment(dateTo, "Y.MM.DD") === moment(dateFrom, "Y.MM.DD");
};

export const validateDateRange = values => {
	const { from, to, fromEra, toEra } = values;
	const isNormalExceedingCurrent = isNormalExceeding(from, to);
	const isEqualCurrent = isEqual(from, to);

	if (
		(isNormalExceedingCurrent && fromEra !== "bc" && toEra !== "bc") ||
		(!isNormalExceedingCurrent &&
			!isEqualCurrent &&
			fromEra === "bc" &&
			toEra === "bc") ||
		(fromEra !== "bc" && toEra === "bc" && from)
	) {
		return "to_exceeds_from";
	}
};

export const isNotInRange = date => {
	if (date) {
		if (moment(new Date(), "Y.MM.DD") < moment(date, "Y.MM.DD")) {
			return "out_of_range";
		}
	}
};

export const isNotProperFormat = date => {
	if (date) {
		const re = /^(\d){1,4}(\.|$)(\d){0,2}(\.|$)(\d){0,2}$/;

		if (!(re.test(date) && moment(date, "Y.MM.DD").isValid())) {
			return "format";
		}
	}
};
