import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import 'normalize.css';
import './styles/main.sass';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import { history } from './scripts/history.js';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import rootReducer from './reducers';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import loadingBarMiddleware from './scripts/loadingBarMiddleware';
import { CookiesProvider } from 'react-cookie';

const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware, loadingBarMiddleware)
);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <CookiesProvider>
        <Router history={history}>
          <App />
        </Router>
      </CookiesProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
