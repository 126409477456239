import React from "react";
import TruncateMarkup from 'react-truncate-markup';
import ReactHtmlParser from 'react-html-parser';

import withContexts from "@/scripts/withContext.js";
import Button from "@/components/button/Button.js";

import styles from "@/containers/Results/Results.module.sass";


const singleMetadata = ({label, value, index, langContext: {dict}}) => {
    const desc = (typeof value === 'object') ? ((value.length > 1) ? value : value[0]) : value;

    if (!desc) {
        return null;
    }

    const showTextAsUrl = (val, lab) => {
        const regexp = /^http/;
        let descr = val

        if (typeof val === 'object' && Object.keys(descr).includes('name')) {
            return <>{descr.name} ({dict.roles[descr.role]})</>
        }

        if (lab === dict.fields.doi) {
            descr = regexp.test(val) ? val : `http://dx.doi.org/${val}`
        }
    
        return regexp.test(descr) ? (
            <>
                <Button
                    variant="link-external"
                    href={descr}
                    target="_blank"
                    className={styles["results__item-external-link"]}
                >
                    {descr}
                    <i className="icon-external" />
                </Button>
            </>
        ) : (
            <>{descr}</>
        );
    };


    return (
        <li className={"flex-column flex-lg-row " + styles["results__item-metadata--item"]} key={index}>
            <span className={styles["results__item-metadata--label"]}>
                {label}
                { (label === dict.fields.group) && <i
                    className="icon-question"
                    data-tip={dict.results.groupInfo}
                    data-type="info"
                    data-class="tt"
                    data-place="right"
                    data-iscapture="true"
                />}
                :
            </span>
            <div className={styles["results__item-metadata--desc"]}>
                {label === dict.fields.description ? (
                    <TruncateMarkup lines={3} key={index}>
                        <div>{ ReactHtmlParser(desc) }</div>
                    </TruncateMarkup>
                ) : (
                    (typeof desc === 'object' && desc.length > 1) ? (
                        desc.map((p,index) => {
                            return <span key={`${p}_${index}`}>{showTextAsUrl(p, label)}</span>
                        })
                    ) : (
                        <span>{showTextAsUrl(desc, label)}</span>
                    )
                )}

            </div> 
        </li>
    );
};

export default withContexts(singleMetadata);
