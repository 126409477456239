import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import { reducer as formReducer } from "redux-form";
import app from "./app";
import search from "./search";
import results from "./results";
import mainPage from "./mainPage";
import getToKnowLeopoldina from "./getToKnowLeopoldina";
import indexes from "./indexes";
import advancedSearch from "./advancedSearch";
import map from "./map";

export default combineReducers({
	app,
	search,
	results,
	form: formReducer,
	mainPage,
	getToKnowLeopoldina,
	indexes,
	advancedSearch,
	map,
	loadingBar: loadingBarReducer
});
