import { transformToObjArr } from "@/scripts/resultsService.js";

const initialState = {
	results: [],
	count: {
		results: 0,
		geo: 0,
		europeana: 0
	},
	links: {
		europeana: ""
	},
	facets: [],
	category: {},
	isFetching: false,
	missingValues: {},
};

export default function results(state = initialState, action) {
	switch (action.type) {
		case "REQUEST_LOAD_RESULTS":
			return {
				...state,
				isFetching: true
			};
		case "RECEIVE_LOAD_RESULTS":
			const {
				count,
				documents,
				europeanaLink,
				europeanaNumFound,
				facets,
				geoCount,
				missingValues
			} = action.data;

			const newFacets = transformToObjArr(facets).map(facet => {
				return { ...facet, value: transformToObjArr(facet.value) };
			});

			const regularFacets = newFacets.filter(
				facet => facet.label !== "category"
			);

			const category = newFacets.filter(facet => facet.label === "category")[0];

			return {
				...state,
				results: documents,
				count: {
					results: count,
					geo: geoCount,
					europeana: europeanaNumFound
				},
				links: {
					europeana: europeanaLink
				},
				facets: [...regularFacets],
				category: category,
				isFetching: false,
				missingValues: missingValues
			};
		default:
			return state;
	}
}
