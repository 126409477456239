import React, { Component } from "react";

import withContexts from "@/scripts/withContext.js";
import { initialValuesDate, getCurrentFacet, getDateValues } from "./utils/formsService";
import { connect } from "react-redux";
import { change, getFormValues } from "redux-form";

import Dropdown from "react-bootstrap/Dropdown";

import FacetFormDate from "./FacetForm/FacetFormDate";
import FacetChosen from "./FacetChosen/FacetChosen";

import styles from "./Facet.module.sass";

class FacetDate extends Component {
	state = {
		active: false,
		activeMissingTime: true
	};

	submitDateFacetForm = value => {
		const { setFacet, facet } = this.props;

		setFacet({ [facet]: getDateValues(value, facet, value.timeline) }, { [`missing`]: value.timeline ? 'time' : '' });
		this.setState({ active: false });
	};

	removeFacetForm = (facetValue, facetName, prefix) => {
		const { facet, setFacet, resetFacet, searchFacets } = this.props;
		const currentFacet = getCurrentFacet(searchFacets, facetName);

		const filteredArr = currentFacet[`f.${facetName}`];

		if (prefix === "from") {
			filteredArr[0] = "*";
		}

		if (prefix === "to") {
			filteredArr[1] = "*";
		}

		if (filteredArr.filter(item => item !== "*").length) {
			setFacet({ [facet]: filteredArr });
		} else {
			resetFacet(facet);
		}
	};

	toggleDropdown = () => {
		this.setState(state => ({
			active: !state.active
		}));
	};

	resetAll = () => {
		const { facet, change } = this.props;

		change(facet, "from", "");
		change(facet, "fromEra", "ad");
		change(facet, "to", "");
		change(facet, "toEra", "ad");
		change(facet, "timeline", false);
	};

	componentDidMount() {
		const {missing} = this.props;
		this.changeMissingTime(missing !== 'time');
	}

	changeMissingTime = value => {
		this.setState({ activeMissingTime: value });
	};

	render() {
		const {
			langContext: { dict },
			facet,
			value,
			searchFacets,
			resetFacet,
			missing,
			missingValues,
			values
		} = this.props;
		const { active, activeMissingTime } = this.state;
		const currentFacet = getCurrentFacet(searchFacets, facet);
		const missingTime = missing === 'time';
		const isSelected = values && (!!values.from || !!values.to || values.timeline !== missingTime );

		return (
			<div className={styles["facet"]}>
				<Dropdown show={active} onToggle={this.toggleDropdown}>
					<Dropdown.Toggle className={styles["facet__toggle"]} variant="facet">
						{dict.fields[facet]}
					</Dropdown.Toggle>
					<Dropdown.Menu className={styles["facet__menu"]}>
						<FacetFormDate
							onSubmit={this.submitDateFacetForm}
							submitForm={this.submitDateFacetForm}
							initialValues={initialValuesDate(currentFacet, facet, missingTime)}
							form={facet}
							resetAll={this.resetAll}
							isSelected={isSelected}
							facetValue={value}
							missingValues={missingValues}
							changeMissingTime={this.changeMissingTime} 
							activeMissingTime={activeMissingTime} 
						/>
					</Dropdown.Menu>
					<FacetChosen
						removeFacetForm={this.removeFacetForm}
						clearFacetForm={() => resetFacet(facet)}
						facetName={facet}
						facetValues={currentFacet}
						facetMissing={missing}
						changeMissingTime={this.changeMissingTime} 
					/>
				</Dropdown>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	values: getFormValues(ownProps.facet)(state),
	missingValues: state.results.missingValues,
	missing: state.search.missing,
});

export default withContexts(connect(mapStateToProps, { change })(FacetDate));
