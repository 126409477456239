import React from "react";
import Form from "react-bootstrap/Form";

import withContexts from "@/scripts/withContext";

import styles from "@/containers/Results/ResultsSidebar/facets/Facet/FacetForm/FacetFormDateFields/FacetFormDateFields.module.sass";

const DateSelect = ({ input, options, label, meta: { touched, error, submitFailed }, langContext: { dict } }) => (
	<div className={styles["select"]}>
		<Form.Label srOnly className={styles["select__label"]}>
			{label}
		</Form.Label>
		<Form.Control as="select" {...input} className={styles["date__form-control"]}>
			{options.map(option => (
				<option key={option} value={option}>
					{dict.facets.form.era[option]}
				</option>
			))}
		</Form.Control>
		{touched && error && submitFailed && (
			<span className={styles["date__error"]}>{dict.facets.form.errors[error]}</span>
		)}
	</div>
);

export default withContexts(DateSelect);
