import React from "react";

import withContexts from "@/scripts/withContext.js";

import Button from "@/components/button/Button.js";

import styles from "./Categories.module.sass";

const CategoriesInfo = ({ langContext: { dict }, isActive, searchCategory, resetCategory }) => (
	<div
		className={`${styles["categories__catalogue-top"]} 
                        ${
							isActive && searchCategory
								? `${styles["categories__catalogue-top--selected"]}`
								: `${styles["categories__catalogue-top--clear"]}`
						}`}
	>
		<span className={styles["categories__catalogue-title"]}>{dict.sidebar.filters.catalogue}</span>
		<span className={styles["categories__catalogue-selected"]}>
			{dict.sidebar.filters.selectedCatalogue}:
			<span className={styles["categories__catalogue-selected--span"]}>{dict.fields[searchCategory]}</span>
		</span>
		<Button
			variant="link"
			onClick={() => resetCategory()}
			disabled={!searchCategory}
			className={styles["categories__catalogue-button"]}
		>
			{dict.sidebar.info.clear}
		</Button>
	</div>
);

export default withContexts(CategoriesInfo);
