import React, { Component } from "react";
import withContexts from "@/scripts/withContext.js";
import { connect } from "react-redux";
import queryString from "query-string";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import debounce from "@/scripts/debounce.js";
import SearchForm from "@/components/search/SearchForm.js";
import * as Actions from "@/actions";

class SearchFormContainer extends Component {
	submitSearchForm = searchQuery => {
		const { history, location, actions } = this.props;
		actions.resetSearchParams();
		history.push({
			pathname: "/results",
			search: "?" + queryString.stringify(searchQuery),
			state: {
				prevPath: location.pathname
			}
		});
	};

	getAutocompleteList = debounce(prefix => {
		const { actions } = this.props;

		if (prefix) {
			actions.getSuggestions("primary", prefix);
		}
	}, 500);

	render() {
		const { suggestions, actions, variantClass, additionalClass, formName } = this.props;

		return (
			<div className={additionalClass}>
				<SearchForm
					getAutocompleteList={this.getAutocompleteList}
					onSubmit={this.submitSearchForm}
					suggestions={suggestions}
					resetSearchSuggestions={actions.resetSearchSuggestions}
					variantClass={variantClass}
					form={formName}
				></SearchForm>
			</div>
		);
	}
}

SearchFormContainer.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired
	}).isRequired
};

const mapStateToProps = state => ({
	suggestions: state.search.suggestions
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withContexts(withRouter(SearchFormContainer)));
