import React from "react";
import { withRouter } from "react-router";

import IndexesMenuInfo from "./IndexesMenuInfo";
import IndexesPrefix from "./IndexesPrefix/IndexesPrefix";

import withContexts from "@/scripts/withContext.js";

import styles from "./IndexesMenu.module.sass";

const IndexesMenu = ({ match, numberOfResults, isFetching, langContext: { dict }, setPrefix, currentPrefix }) => (
	<aside className={styles.menu}>
		<div className={`${styles["menu__container"]}`}>
			<IndexesMenuInfo
				label={dict.indexes.number_of_values}
				info={!isFetching ? numberOfResults.toLocaleString("ru-RU") : null}
			/>
			<IndexesMenuInfo label={dict.indexes.choosen_index} info={dict.indexes[match.params.type]} />
			<IndexesPrefix setPrefix={setPrefix} currentPrefix={currentPrefix} />
		</div>
	</aside>
);

export default withContexts(withRouter(IndexesMenu));
