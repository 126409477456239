import React, { Component } from "react";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import * as Actions from "@/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import withContexts from "@/scripts/withContext.js";

import { setParams, setUrlFromParams, setPageSize, setPageByNum } from "@/scripts/indexesService.js";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import IndexesMenu from "./IndexesMenu/IndexesMenu";
import IndexesList from "./IndexesList/IndexesList";
import IndexesNav from "@/containers/Indexes/IndexesNav/IndexesNav.js";
import IndexesNavBottom from "@/containers/Indexes/IndexesNav/IndexesNavBottom.js";
import IndexesNoResults from "@/containers/Indexes/IndexesNoResults.js";
import styles from "./Indexes.module.sass";

class Indexes extends Component {
	static propTypes = {
		actions: PropTypes.object,
		location: PropTypes.object,
		searchParams: PropTypes.shape({
			prefix: PropTypes.string,
			page: PropTypes.number,
			pageSize: PropTypes.number
		}),
		isFetching: PropTypes.bool,
		totalPages: PropTypes.number
	};
	componentDidMount() {
		const { location } = this.props;
		this.loadIndexes(location.search);
	}

	componentDidUpdate(prevProps) {
		const { match, location } = this.props;
		if (
			prevProps.match.params.type !== match.params.type ||
			this.props.location.search !== prevProps.location.search
		) {
			this.loadIndexes(location.search);
		}
	}

	setPrefix = prefix => {
		const {
			location,
			history,
			match: {
				params: { type }
			}
		} = this.props;
		const prefixValue = prefix.name === "all" ? "" : prefix.name;

		history.push({
			pathname: "/index/" + type,
			search: `&page=1&pageSize=30&prefix=${prefixValue}`,
			state: {
				prevPath: location.pathname
			}
		});
	};

	loadIndexes = searchLocation => {
		const { match, actions, searchParams } = this.props;
		const newSearchParams = setParams(searchLocation, searchParams);
		actions.setParams({ ...newSearchParams });

		const searchUrl = setUrlFromParams(match.params.type, {
			...newSearchParams,
			params: {
				...newSearchParams.params,
				page: newSearchParams.params.page - 1
			}
		});

		actions.loadIndexes(searchUrl);
	};

	updateUrl = newParams => {
		const { history, location, match } = this.props;
		const searchUrl = setUrlFromParams(match.params.type, newParams);
		history.push({
			pathname: "/index/" + match.params.type,
			search: `${searchUrl}`,
			state: {
				prevPath: location.pathname
			}
		});
	};

	setPageSize = size => {
		const { searchParams } = this.props;
		setPageSize(size, this.updateUrl, {
			searchParams
		});
	};

	setPageByNum = num => {
		const { searchParams } = this.props;

		setPageByNum(num, this.updateUrl, {
			searchParams
		});
	};

	render() {
		const {
			indexes,
			numberOfResults,
			match,
			isFetching,
			prefix,
			totalPages,
			searchParams,
			langContext: { dict }
		} = this.props;

		const breadcrumbsList = [
			{ label: "home" },
			{ label: [dict.indexes.titles] },
			{ label: [dict.indexes[match.params.type]] }
		];
		return (
			<div className={styles.page}>
				<Container>
					<Row>
						<Breadcrumbs breadcrumbsList={breadcrumbsList} />
						<h1 className={styles.page__title}>{dict.indexes.titles}</h1>
					</Row>
				</Container>
				<div className={styles.page__line}></div>
				<Container>
					<Row>
						<div className={styles.page__content}>
							<IndexesMenu
								indexes={indexes}
								numberOfResults={numberOfResults}
								isFetching={isFetching}
								setPrefix={this.setPrefix}
								currentPrefix={prefix}
							/>

							{indexes && totalPages && (
								<>
									{indexes.length ? (
										<div className={styles.page__main}>
											<IndexesNav
												setPageByNum={this.setPageByNum}
												setPageSize={this.setPageSize}
												searchParams={searchParams}
												totalPages={totalPages}
											/>
											<IndexesList indexes={indexes} />
											<IndexesNavBottom
												setPageByNum={this.setPageByNum}
												searchParams={searchParams}
												totalPages={totalPages}
											/>
										</div>
									) : (
										<IndexesNoResults />
									)}
								</>
							)}
						</div>
					</Row>
				</Container>
			</div>
		);
	}
}

Indexes.propTypes = {
	actions: PropTypes.object,
	indexes: PropTypes.array,
	numberOfResults: PropTypes.number,
	prefix: PropTypes.string,
	isFetching: PropTypes.bool
};

const mapStateToProps = state => ({
	indexes: state.indexes.indexes,
	numberOfResults: state.indexes.numberOfResults,
	prefix: state.indexes.params.prefix,
	isFetching: state.indexes.isFetching,
	totalPages: state.indexes.totalPages,
	searchParams: state.indexes.params
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch)
});

export default withContexts(withRouter(connect(mapStateToProps, mapDispatchToProps)(Indexes)));
