import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as Actions from "@/actions";
import withContexts from "@/scripts/withContext.js";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

import ImageLink from "@/components/imageLink/ImageLink.js";
import HeaderTopBar from "./HeaderTopBar";
import HeaderMenu from "./HeaderMenu";
import "./Header.sass";

import SearchFormContainer from "@/components/search/SearchFormContainer.js";

import logo from "@/images/logo.svg";
import logo_eu from "@/assets/images/search/logo_eu.svg";
import logo_uwr from "@/assets/images/search/logo_uwr.svg";

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			scrollPoss: 0,
			sticky: false,
			menuExpanded: false
		};
	}

	componentDidMount() {
		const { actions, currentLang } = this.props;
		actions.getHeaderMenu(currentLang);
		actions.getQuestionnaireLink(currentLang.id);

		window.addEventListener("scroll", this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	componentDidUpdate(prevProps) {
		const { actions, currentLang } = this.props;

		if (currentLang !== prevProps.currentLang) {
			actions.getHeaderMenu(currentLang.id);
			actions.getQuestionnaireLink(currentLang.id);
		}
	}

	handleScroll = () => {
		const currentScrollPos = window.pageYOffset;
		const sticky = currentScrollPos >= 1;

		if (currentScrollPos !== this.state.scrollPoss) {
			this.setState({
				scrollPoss: currentScrollPos,
				sticky
			});
		}
	};

	handleMenuExpanded = () => this.setState({ menuExpanded: !this.state.menuExpanded });

	render() {
		const {
			setLang,
			currentLang,
			menuData,
			location,
			setContrast,
			setFontSize,
			questionnairePage,
			langContext: { dict }
		} = this.props;

		return (
			<div className={`header__wrapper header__wrapper--${this.state.sticky ? "sticky" : ""}`}>
				<header className={this.state.sticky ? "header__sticky" : ""}>
					<HeaderTopBar
						setLang={setLang}
						currentLang={currentLang}
						setContrast={setContrast}
						fontSize={setFontSize}
						data={menuData}
						questionnairePage={questionnairePage}
					/>
					<Navbar
						bg="light"
						expand="lg"
						className="header__bar"
						collapseOnSelect
						expanded={this.state.menuExpanded}
						onToggle={this.handleMenuExpanded}
					>
						<Container className="header__container">
							<div className="header__bar-logo">
								<div className="header__bar-logo--box header__bar-logo--box-eu">
									<img
										src={logo_eu}
										className="header__bar-logo--img-eu"
										alt={dict.headers.eu_logo_alt}
									/>
								</div>
								<div className="header__bar-logo--box header__bar-logo--box-uwr">
									<ImageLink
										src={logo_uwr}
										href="https://uni.wroc.pl/"
										className="header__bar-logo--img-uwr"
										alt={dict.headers.uwr_logo_alt}
										target="_blank"
									/>
								</div>
								<div className="header__bar-logo--box  header__bar-logo--box-leo">
									<ImageLink
										src={logo}
										href="/"
										className="header__bar-logo--img-leo"
										alt={dict.headers.logo_alt}
									/>
								</div>
							</div>
							{location.pathname !== "/advanced-search" && (
								<div className="header__bar-search">
									<SearchFormContainer
										variantClass="header"
										formName="searchHeader"
										additionalClass={location.pathname === "/" ? "mainPage" : ""}
									/>
								</div>
							)}
							<HeaderMenu data={menuData} closeMenu={this.handleMenuExpanded} />
						</Container>
					</Navbar>
				</header>
			</div>
		);
	}
}

Header.propTypes = {
	type: PropTypes.oneOf(["main", "secondary"]),
	menuData: PropTypes.arrayOf(PropTypes.object),
	questionnairePage: PropTypes.object
};

const mapStateToProps = state => ({
	menuData: state.mainPage.menuData,
	questionnairePage: state.app.questionnairePage
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch)
});

export default withRouter(withContexts(connect(mapStateToProps, mapDispatchToProps)(Header)));
