import React from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

import withContexts from "@/scripts/withContext.js";

import styles from "./IndexesList.module.sass";

const IndexesList = ({ indexes, match }) => {
	return (
		<article className={styles.results}>
			<div className={`${styles["results__content"]}`}>
				<ul className={`${styles["results__list"]}`}>
					{indexes.map(result => (
						<li key={result.value} className={`${styles["results__list-item"]}`}>
							<NavLink
								to={`/results?q=${match.params.type}:("${result.value}")`}
								className={`${styles["results__list-item--link"]}`}
							>
								{result.value}
							</NavLink>
						</li>
					))}
				</ul>
			</div>
		</article>
	);
};

export default withContexts(withRouter(IndexesList));
