import React from "react";

import withContexts from "@/scripts/withContext.js";

import styles from "@/containers/Indexes/Indexes.module.sass";

const IndexesNoResults = ({ langContext: { dict } }) => (
	<div className={styles["page__no-results"]}>{dict.results.no_results}</div>
);

export default withContexts(IndexesNoResults);
