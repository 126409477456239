import React from 'react';

import withContexts from "@/scripts/withContext.js";

import styles from "./Info.module.sass";

const SidebarInfoAdvSearch = ({
    langContext: { dict },
    singleQuery
}) => (
    <div className={styles["sidebar-info__textbox--item"]}>
        { singleQuery.conj && 
            <span className={styles["sidebar-info__textbox--conj"]}>
                {dict.advanced_search.form.conjs[singleQuery.conj.toLowerCase()]}
            </span>
        }
        { (singleQuery.type && singleQuery.type !== '-time') && 
            <span className={styles["sidebar-info__textbox--type"]}> 
                {dict.advanced_search.form.types[singleQuery.type]}:
            </span> 
        }

        { (singleQuery.type !== '-time') ? (
            (singleQuery.type !== 'time') ? (
                <strong>{singleQuery.value}</strong>
            ) : (
                <div>
                    <strong>
                        {singleQuery.dateFrom} 
                        {singleQuery.dateFromBC && 
                            <span className={styles["sidebar-info__textbox--era"]}>
                                {dict.advanced_search.form.era.bc}
                            </span>
                        }
                    </strong> - <strong>
                        {singleQuery.dateTo}
                        {singleQuery.dateToBC && 
                            <span className={styles["sidebar-info__textbox--era"]}>
                                {dict.advanced_search.form.era.bc}
                            </span>
                        }
                    </strong>
                </div>
            )
        ): (
            <strong>{dict.advanced_search.form.timeline}</strong>
        )}
    </div>
)

export default withContexts(SidebarInfoAdvSearch);
