import React from 'react';
import PropTypes from 'prop-types';
import styles from './ImageLink.module.sass';

const ImageLink = ({
  disabled = false,
  href,
  target,
  src,
  alt,
  className
}) => (
  <a className={`${styles.ImageLink}`}
    disabled={disabled}
    href={href}
    target={target}
  >
    <img src={src} alt={alt} className={className}/>
  </a>
);

ImageLink.propTypes = {
  disabled: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default ImageLink;
