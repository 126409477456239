import React from 'react';
// import PropTypes from 'prop-types';

import FooterBottomBar from './FooterBottomBar';
import FooterPartners from './FooterPartners';
import './Footer.sass';

const Footer = () => (
  <footer>
    <FooterPartners />
    <FooterBottomBar />
  </footer>
);

Footer.propTypes = {};

export default Footer;
