import React, { useState, useMemo } from "react";

import Button from "@/components/button/Button.js";
import withContexts from "@/scripts/withContext.js";

import fields from "@/fields.json";

import ResultsListItemBottom from "./ResultsListItemBottom";
import ResultsListItemSingleMetadata from "./ResultsListItemSingleMetadata";
import ResultsListItemImg from "./ResultsListItemImg";

import styles from "@/containers/Results/Results.module.sass";

const ResultsListItem = ({result, langContext: {dict}}) => {

	const [isOpen, setIsOpen] = useState(false)

	const getCategoryTitle = (category) => {
		const tempTitleObj = Object.values(fields.fieldSets).filter(item => item.categoryName === category )[0]

		switch (tempTitleObj.mainField) {
			case 'person':
				return result[tempTitleObj.mainField] ? `${result[tempTitleObj.mainField][0].name} (${dict.roles[result[tempTitleObj.mainField][0].role]})` : ''
			case 'group':
				return result[tempTitleObj.mainField] ? `${result[tempTitleObj.mainField][0].name} (${dict.roles[result[tempTitleObj.mainField][0].role]})` : ''
			default:
				return result[tempTitleObj.mainField]
		  }
	}

	const getCategoryRelatedMetadata = (category, status) => {
		const array = [];

		const categoryFieldsArray = Object.values(fields.fieldSets).find(x => x.categoryName === category).otherFields;

		return categoryFieldsArray.map((itemMetadata, i) => {

			if (itemMetadata === 'accessRights') {
				if (Object.keys(result).includes('licence')) return false;
			}

			if (itemMetadata === 'rights') {
				if (Object.keys(result).includes('licence', 'accessRights')) return false;
			}

			if (itemMetadata === 'doi') {
				if (Object.keys(result).includes('shareUrl')) return false;
			}

			if (!result[itemMetadata] || (typeof result[itemMetadata] === 'object' && !result[itemMetadata].length) ) return false;

			array.push(i)

			if (status === 'primary') {
				if (array.indexOf(i) <= 3) return <ResultsListItemSingleMetadata key={i} label={dict.fields[itemMetadata]} value={result[itemMetadata]} index={i} />
			} else {
				if (array.indexOf(i) > 3) return <ResultsListItemSingleMetadata key={i} label={dict.fields[itemMetadata]} value={result[itemMetadata]} index={i} />
			}

			return false;
		});
	};

	return (
		<li className={styles["results__item"]}>
			<div className={styles["results__item-content"]}>
				{result.resourceUrl ? (
					<Button
						variant="link-external"
						href={result.resourceUrl}
						target="_blank"
						className={styles["results__item-title"]}
					>
						<span dangerouslySetInnerHTML={{__html: getCategoryTitle(result.category)}}></span>
						<i className="icon-external" />
					</Button>
				) : (
					<h3 className={styles["results__item-title--no-link"]} dangerouslySetInnerHTML={{__html: getCategoryTitle(result.category)}}></h3>
				)}

				{result.category === 'objects' && ( !!result.creator.length || !!result.recordedBy.length) &&
					<div
						className={styles["results__item-authors"]}
					>
						{ !!result.creator.length &&
							result.creator.map((author, index) => {
								return <span key={`${author}_${index}`}>{author}</span>
							})
						}

						{ !!result.recordedBy.length &&
							result.recordedBy.map((author, index) => {
								return <span key={`${author}_${index}`}>{author}</span>
							})
						}

						{ !!result.contributor.length &&
							result.contributor.map((author, index) => {
								return <span key={`${author}_${index}`}>{author}</span>
							})
						}
					</div>
				}

				{useMemo(() => <ResultsListItemImg thumb={result.thumbnailUrl} title={getCategoryTitle(result.category)} url={result.resourceUrl}/>)}

				<div className={styles["results__item-metadata"]}>
					{
						(
							((result.publicationType === 'ARTICLE') && (result.journal !== '') && !!result.journal) ||
							(
								(
									( result.publicationType === 'CHAPTER') ||
									( result.publicationType === 'BOOK')
								) ||
								(
									(result.publisher && !!result.publisher.length) &&

									(
										(result.country && !!result.country.length) ||
										(result.place && !!result.place.length)

									)
								)
							)
						) &&

					<div className={styles["results__item-metadata--info"]}>

						{ (result.publicationType === 'CHAPTER' &&
						(((result.editor && !!result.editor.length) && result.collection ) || result.collection)) &&
							<div className={styles["results__item-metadata--chapter"]}>
								{`${dict.results.in} `}
								{ ((result.editor && !!result.editor.length) && result.collection ) &&
									<>
									{
										(result.editor && !!result.editor.length) && result.editor.map((editor, index) => {
											return <span key={`${editor}_${index}`}>{editor} {dict.results.ed}</span>
										})
									}
									</>
								}
								{ result.collection && <span>{result.collection}</span>}
							</div>
						}

						{
							(
								(
									( result.publicationType === 'CHAPTER') ||
									( result.publicationType === 'BOOK')
								) ||
								(
									(result.publisher && !!result.publisher.length) &&

									(
										(result.country && !!result.country.length) ||
										(result.place && !!result.place.length)

									)
								)
							) &&
							<div className={styles["results__item-metadata--publisher"]}>
								{ result.publisher && !!result.publisher.length &&
									result.publisher.map((publisher, index) => {
										return <span key={`${publisher}_${index}`}><em>{publisher}</em></span>
									})
								}
								{ result.country && !!result.country.length &&
									result.country.map((country, index) => {
										return <span key={`${country}_${index}`}>{country}</span>
									})
								}
								{ result.place && !!result.place.length &&
									result.place.map((place, index) => {
										return <span key={`${place}_${index}`}>{place}</span>
									})
								}
								{ ((result.time && !!result.time.length) && (((result.journal === '') && result.journal) || !result.journal)) &&
									result.time.map((time, index) => {
										return <span key={`${time}_${index}`}>{time}</span>
									})
								}
							</div>
}

						{ ((result.publicationType === 'ARTICLE') && (result.journal !== '') && !!result.journal) &&
							<div className={styles["results__item-metadata--newspaper"]}>
								<em>{ result.journal }</em>{(result.volume || result.issue) && `, `} { result.volume && result.volume }{ (result.volume && result.issue) && `/`}{ result.issue && result.issue }{ result.time && `, ${result.time}` }{result.pages && `, ${result.pages}`}
							</div>
						}
					</div>

					}

					<ul className={styles["results__item-metadata--list"]} >
						{useMemo(() => getCategoryRelatedMetadata(result.category, 'primary'))}
					</ul>

					<ul className={`${styles["results__item-metadata--list"]} ${styles["results__item-metadata--list-secondary"]} ${isOpen &&
							`${styles["results__item-metadata--list-active"]}`}`} >
						{useMemo(() => getCategoryRelatedMetadata(result.category))}
					</ul>

					<Button
						onClick={() => setIsOpen(!isOpen)}
						variant="link"
						className={`${styles["results__item-metadata--btn"]} ${isOpen &&
							`${styles["results__item-metadata--btn-active"]}`}`}
					>
						{isOpen ? dict.collapse : dict.expand}
						<i className="icon-chevron-down" />
					</Button>

				</div>
			</div>
			<ResultsListItemBottom result={result} />
		</li>
	);
}

export default withContexts(ResultsListItem);
