import { Field, reduxForm } from "redux-form";
import React, { Component } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import { withRouter } from "react-router";
import Button from "@/components/button/Button";
import AutoInput from "@/components/autoInput/AutoInput.js";
import withContexts from "@/scripts/withContext.js";
import AdvancedSearchLink from "@/components/search/AdvancedSearchLink.js";
import styles from "@/components/search/Search.module.sass";
import CheckboxEuropeana from "@/components/search/CheckboxEuropeana.js";

const required = value => (value ? undefined : "Required");

class SearchForm extends Component {
	componentDidUpdate(prevProps) {
		const { location, reset } = this.props;
		if (location.pathname !== prevProps.location.pathname) {
			reset();
		}
	}

	render() {
		const {
			handleSubmit,
			valid,
			pristine,
			submitting,
			getAutocompleteList,
			suggestions,
			resetSearchSuggestions,
			variantClass,
			langContext: { dict }
		} = this.props;

		return (
			<form
				onSubmit={handleSubmit}
				className={`${styles["search__form"]} ${styles[variantClass]}`}
				autoComplete="off"
			>
				<InputGroup className={styles["search__inputgroup"]}>
					<div className={`${styles["search__form-wrapper"]} ${styles[variantClass]}`}>
						<Field
							name="q"
							component={AutoInput}
							type="text"
							placeholder={dict.search.placeholder}
							aria-label={dict.search.placeholder}
							customClass={`${styles["search__input"]} ${styles[variantClass]}`}
							variant="primary"
							getAutocompleteList={getAutocompleteList}
							resetSuggestionsList={resetSearchSuggestions}
							suggestions={suggestions}
							validate={[required]}
							autofocus={true}
							label={dict.search.placeholder}
						/>
						<InputGroup.Append>
							<Button
								type="submit"
								variant="gray"
								className={`${styles["search__button"]} ${styles[variantClass]}`}
								disabled={!valid || pristine || submitting}
								value="ss"
							>
								<span>{dict.advanced_search.form.search}</span>
								<i className="icon-search" />
							</Button>
						</InputGroup.Append>
					</div>
					<div className={`${styles["search__form-wrapper-links"]} ${styles[variantClass]}`}>
						<Field
							name="europeana"
							type="checkbox"
							id="europeana"
							component={CheckboxEuropeana}
							variant={variantClass}
						/>
						<Field name="AdvancedSearchLink" component={AdvancedSearchLink} variant={variantClass} />
					</div>
				</InputGroup>
			</form>
		);
	}
}

export default withContexts(
	withRouter(
		reduxForm({
			initialValues: {
				q: ""
			}
		})(SearchForm)
	)
);
