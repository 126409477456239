import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import styles from './SelectedResources.module.sass';

const SelectedResourcesItem = ({
    title,
    image,
    desc,
    link
}) => (
    <li className={styles["resources__block"]}>
        <Link className={styles["resources__block-link"]} to={link}>
            <figure className={styles["resources__block-top"]}>
                {image && image.length && image.map((img) =>
                    <div 
                        className={styles["resources__block-top--img"]} 
                        style={{ backgroundImage: "url(" + (img.url) + ")" }}
                        key={img.id}
                    />)}
                {desc && 
                    <span className={styles["resources__block-top--desc"]}>
                        <p className={styles["resources__block-top--text"]}>{desc}</p>
                    </span>}
            </figure>
            <div className={styles["resources__block-bottom"]}>
                {title && 
                    <h3 className={styles["resources__block-bottom--title"]}>
                        <span>{title}</span>
                    </h3>}
                <i className={`${styles[ "resources__block-bottom--icon"]} ${'icon-arrow-right-full'}`}/>
            </div>
        </Link>
    </li>
);

export default withRouter(SelectedResourcesItem);