import React from "react";

import withContexts from "@/scripts/withContext.js";

import FacetDate from "./FacetDate";
import FacetRegular from "./FacetRegular";

import styles from "./Facet.module.sass";

const Facet = ({ facet, value, searchFacets, setFacet, setMissing, resetFacet }) => {
	if (facet === "time") {
		return (
			<div className={styles["facet"]}>
				<FacetDate
					facet={facet}
					value={value}
					resetFacet={resetFacet}
					searchFacets={searchFacets}
					setFacet={setFacet}
					setMissing={setMissing}
				/>
			</div>
		);
	}

	return (
		<div className={styles["facet"]}>
			<FacetRegular
				facet={facet}
				resetFacet={resetFacet}
				setFacet={setFacet}
				value={value}
				searchFacets={searchFacets}
			/>
		</div>
	);
};

export default withContexts(Facet);
