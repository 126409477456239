import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import withContexts from '@/scripts/withContext.js';
import Button from '@/components/button/Button.js';

import styles from './GetToKnowLeopoldina.module.sass';

const GetToKnowLeopoldinaTable = ({
    title,
    portal,
    link,
    pageData,
    langContext: { dict }
}) => (

    <li className={styles['app__table-content--item']}>
        <span className={styles['app__table-content--column']}>
            <span className={styles['app__table-content--column-text']}>
                {pageData.acf.leopoldina_column_1_title}:
            </span>
            <span className={styles['app__table-content--column']} 
                dangerouslySetInnerHTML={{
                __html: title
                }} />
        </span>
        
        <span className={styles['app__table-content--column']}>
            <span className={styles['app__table-content--column-text']}>
                {pageData.acf.leopoldina_column_2_title}:
            </span>
            <Button variant="link-external" href={portal} target="_blank" className={styles['app__table-content--portal']}>
                {portal}
                <i className="icon-external"></i>
            </Button>
        </span>
        {link && 
            <span className={styles['app__table-content--column']}>
                <span className={styles['app__table-content--column-text']}>
                    {pageData.acf.leopoldina_column_3_title}:
                </span>
                <Link to={link} className={styles['app__table-content--link']}>
                    {dict.know_leopoldina.table.resources}
                </Link>
            </span>
        }
    </li>
);

export default withContexts(withRouter(GetToKnowLeopoldinaTable));