import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import withContexts from "@/scripts/withContext.js";

import CopyButton from "@/components/copyButton/copyButton.js";
import Button from '@/components/button/Button.js';

import styles from "@/containers/Results/Results.module.sass";

class ResultsListItemBottom extends Component {
	getIconType = type => {
		switch (type) {
			case "with_geo":
				return <i className="icon-geolocation" />;
			case "plan_digit_repr":
				return <i className="icon-image-time" />;
			case "with_digit_repr":
				return <i className="icon-image" />;
			case "no_digit_repr":
				return <i className="icon-image-close" />;
			case "limit_access":
				return <i className="icon-image-locked" />;
			default:
				return false;
		}
	};

	render() {
		const {
			result,
			langContext: { dict }
		} = this.props;

		const url = result.shareUrl ? result.shareUrl : result.resourceUrl;

		return (
			<div className={styles["results__item-bottom"]}>

				<div className={styles["results__item-bottom--left"]}>
					{result.contentType.map((item, index) => {
						if (!(item === 'plan_digit_repr' || item === 'with_digit_repr')) return (
							<div key={index} className={styles["results__item-bottom--type"]}>
								{this.getIconType(item)}
								{dict.results[item]}
							</div>
							)
					})}
				</div>

				{url && (
					<div className={styles["results__item-bottom--right"]}>

						<CopyButton text={url} className={styles["results__item-bottom--copy"]}>
							{dict.results.copy}
						</CopyButton>

						<span className={styles["results__share"]}>
							<Button 
								variant="link" 
								className={styles["results__share-button"]}
								href={`https://facebook.com/sharer/sharer.php?u=${url}`}
								target="_blank"
							>
								<i className="icon-share" />
								{dict.results.share_object}
							</Button>
						</span>

					</div>
				)}

			</div>
		);
	}
}

ResultsListItemBottom.propTypes = {
	result: PropTypes.object
};

export default withContexts(withRouter(connect()(ResultsListItemBottom)));
