import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Actions from "../../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import withContexts from "../../scripts/withContext.js";
import { bindActionCreators } from "redux";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "./GetToKnowLeopoldina.module.sass";

import GetToKnowLeopoldinaSubpage from "./GetToKnowLeopoldinaSubpage";
import GetToKnowLeopoldinaMenu from "./GetToKnowLeopoldinaMenu";

const GetToKnowLeopoldina = ({ actions, match, langContext, pageData, menuData }) => {
	const { getKnowLeopoldinaSubpage } = actions;
	const { dict } = langContext;
	const breadcrumbsList = [
		{ label: "home" },
		{ label: [dict.know_leopoldina.title] },
		{ label: pageData.title.rendered }
	];

	const handleFetchingData = (menuArray, fetchingAction, subPageId, loadingStatus) => {
		if (menuArray.length) {
			fetchingAction(subPageId);
		}
		loadingStatus(false);
	};

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		handleFetchingData(menuData, getKnowLeopoldinaSubpage, match.params.type, setIsLoading);
	}, [menuData, match.params.type]);

	return (
		<div className={styles.app}>
			<Container>
				<Row>
					<Breadcrumbs breadcrumbsList={breadcrumbsList} />
					<h1 className={styles.app__title}>{dict.know_leopoldina.title}</h1>
				</Row>
			</Container>

			<div className={styles.app__line} />

			<div className={styles.app__content}>
				<Container>
					<Row className={styles.app__container}>
						<GetToKnowLeopoldinaMenu />
						{isLoading ? (
							<div>{dict.map.loading}</div>
						) : (
							pageData && <GetToKnowLeopoldinaSubpage pageData={pageData} id={match.params} />
						)}
					</Row>
				</Container>
			</div>
		</div>
	);
};

GetToKnowLeopoldina.propTypes = {
	currentLang: PropTypes.string,
	actions: PropTypes.object,
	pageData: PropTypes.object,
	menuData: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => ({
	pageData: state.getToKnowLeopoldina.pageData,
	currentLang: state.app.currentLang,
	menuData: state.mainPage.menuData
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch)
});

export default withContexts(withRouter(connect(mapStateToProps, mapDispatchToProps)(GetToKnowLeopoldina)));
