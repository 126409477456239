import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from '../../components/button/Button.js';
import { Link } from 'react-router-dom';
import withContexts from '../../scripts/withContext.js';

import './Cookies.sass';

const Cookies = ({setCookie, langContext: {dict}}) => {
  return (
    
    <div className="cookies">
      <Container>
        <Row>
          <div className="cookies__left">
            <span className="cookies__left-text">
              <i className="cookies__left-icon icon-cookies"/>
              {dict.cookies.desc}
              <Link to={dict.cookies.link1_path} className="cookies__left-link">{dict.cookies.link1}</Link>
              {dict.cookies.and}
              <Link to={dict.cookies.link2_path} className="cookies__left-link">{dict.cookies.link2}</Link>.
            </span>
          </div>
          <Button variant="rounded" onClick={setCookie} className="cookies__btn">{dict.cookies.accept}</Button>
        </Row>
      </Container>
    </div>
  );
}

export default withContexts(Cookies);