import React from "react";

import withContexts from "@/scripts/withContext.js";

import FacetActionsItem from "./FacetActionsItem";

import styles from "./FacetActions.module.sass";

const FacetDateActions = ({ langContext: { dict }, resetAll, submitting, isSelected }) => {
	return (
		<ul className={styles["actions"]}>
			<FacetActionsItem
				clickAction={resetAll}
				disabled={!isSelected || submitting}
				label={dict.sidebar.filters.clear}
			/>
		</ul>
	);
};

export default withContexts(FacetDateActions);
