import fetch from "isomorphic-fetch";

const apiAddress = process.env.REACT_APP_API;
const wpApiAddress = process.env.REACT_APP_API_WP;

//APP

const SET_CURRENT_LANGUAGE = "SET_CURRENT_LANGUAGE";
export const setNewLang = lang => ({
	type: SET_CURRENT_LANGUAGE,
	lang
});

const REQUEST_LOAD_APP = "REQUEST_LOAD_APP";
export const requestLoadApp = () => ({
	type: REQUEST_LOAD_APP,
	isLoading: true
});

const SET_APP_ERROR = "SET_APP_ERROR";
export const setAppError = err => ({
	type: SET_APP_ERROR,
	isLoading: false,
	err
});

const RESET_APP_ERROR = "RESET_APP_ERROR";
export const resetAppError = () => ({
	type: RESET_APP_ERROR,
	isLoading: false
});

// SEARCH

const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS";
export const setSearchParams = params => ({
	type: SET_SEARCH_PARAMS,
	params
});

const RESET_SEARCH_PARAMS = "RESET_SEARCH_PARAMS";
export const resetSearchParams = () => ({
	type: RESET_SEARCH_PARAMS
});

//RESULTS

const REQUEST_LOAD_RESULTS = "REQUEST_LOAD_RESULTS";
export const requestLoadResults = () => ({
	type: REQUEST_LOAD_RESULTS,
	isLoading: true
});

const RECEIVE_LOAD_RESULTS = "RECEIVE_LOAD_RESULTS";
export const receiveLoadResults = data => ({
	type: RECEIVE_LOAD_RESULTS,
	isLoading: false,
	data
});

export const loadResults = queryStr => {
	return (dispatch, getState) => {
		dispatch(requestLoadResults());

		fetch(`${apiAddress}/api/results${queryStr}`, {
			accept: "application/json"
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				dispatch(receiveLoadResults(json));
				dispatch(setSearchParams({ ...getState().search, count: json.count }));
			})
			.catch(err => {
				dispatch(setAppError(err));
			});
	};
};

//INDEXES

const REQUEST_LOAD_INDEXES = "REQUEST_LOAD_INDEXES";
export const requestLoadIndexes = () => ({
	type: REQUEST_LOAD_INDEXES,
	isLoading: true
});

const RECEIVE_LOAD_INDEXES = "RECEIVE_LOAD_INDEXES";
export const receiveLoadIndexes = data => ({
	type: RECEIVE_LOAD_INDEXES,
	isLoading: false,
	data
});

const SET_PARAMS = "SET_PARAMS";
export const setParams = params => ({
	type: SET_PARAMS,
	params
});

export const loadIndexes = search => {
	return dispatch => {
		dispatch(requestLoadIndexes());
		fetch(`${apiAddress}/api/indexes?${search}`, {
			accept: "application/json"
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				dispatch(receiveLoadIndexes(json));
				dispatch(setParams());
			})
			.catch(err => {
				dispatch(setAppError(err));
			});
	};
};

//menu from wp

const RECEIVE_HEADER_MENU = "RECEIVE_HEADER_MENU";
const receiveHeaderMenu = (data, err) => ({
	type: RECEIVE_HEADER_MENU,
	data,
	err
});

export const getHeaderMenu = currentLang => {
	const getLangNum = lang => {
		switch (lang) {
			case "pl":
			default:
				return "9";
			case "en":
				return "10";
		}
	};

	return dispatch => {
		dispatch(resetAppError());
		fetch(wpApiAddress + `/wp-json/wp-api-menus/v2/menus/${getLangNum(currentLang)}`)
			.then(response => {
				return response.json();
			})
			.then(json => dispatch(receiveHeaderMenu(json)))
			.catch(err => dispatch(setAppError(err)));
	};
};

//pages from wp

const REQUEST_KNOW_LEOPOLDINA_SUBPAGE = "REQUEST_KNOW_LEOPOLDINA_SUBPAGE";
const requestKnowLeopoldinaSubpage = () => ({
	type: REQUEST_KNOW_LEOPOLDINA_SUBPAGE,
	isLoading: true
});

const RECEIVE_KNOW_LEOPOLDINA_SUBPAGE = "RECEIVE_KNOW_LEOPOLDINA_SUBPAGE";
const receiveKnowLeopoldinaSubpage = data => ({
	type: RECEIVE_KNOW_LEOPOLDINA_SUBPAGE,
	isLoading: false,
	data
});

export const getKnowLeopoldinaSubpage = id => {
	const getMenuItems = (obj, propName) => obj[propName];
	const getProperItem = (arr, propName, valueToCompare) => arr.find(el => el[propName] === valueToCompare);

	return (dispatch, getState) => {
		dispatch(resetAppError());
		dispatch(requestKnowLeopoldinaSubpage());
		fetch(
			wpApiAddress +
				`/wp-json/wp/v2/pages/${
					getProperItem(getMenuItems(getState().mainPage, "menuData"), "xfn", id).object_id
				}`
		)
			.then(response => response.json())
			.then(json => dispatch(receiveKnowLeopoldinaSubpage(json)))
			.catch(err => dispatch(setAppError(err)));
	};
};

//selected resources from wp
const RECEIVE_SELECTED_RESOURCES = "RECEIVE_SELECTED_RESOURCES";
const receiveSelectedResources = (data, err) => ({
	type: RECEIVE_SELECTED_RESOURCES,
	data,
	err
});

const REQUEST_SELECTED_RESOURCES = "REQUEST_SELECTED_RESOURCES";
const requestSelectedResources = () => ({
	type: REQUEST_SELECTED_RESOURCES
});

export const getSelectedResourcesPage = currentLang => {
	const getLangNum = currentLang => {
		switch (currentLang) {
			case "pl":
			default:
				return "96";
			case "en":
				return "98";
		}
	};

	return dispatch => {
		dispatch(resetAppError());
		dispatch(requestSelectedResources());
		fetch(wpApiAddress + `/wp-json/wp/v2/pages/${getLangNum(currentLang)}`)
			.then(response => {
				return response.json();
			})
			.then(json => dispatch(receiveSelectedResources(json)))
			.catch(err => dispatch(setAppError(err)));
	};
};

//questionnaire from wp
const RECEIVE_QUESTIONNAIRE = "RECEIVE_QUESTIONNAIRE";
const receiveQuestionnaire = (data, err) => ({
	type: RECEIVE_QUESTIONNAIRE,
	data,
	err
});

const REQUEST_QUESTIONNAIRE = "REQUEST_QUESTIONNAIRE";
const requestQuestionnaire = () => ({
	type: REQUEST_QUESTIONNAIRE
});

export const getQuestionnaireLink = currentLang => {
	const getLangNum = currentLang => {
		switch (currentLang) {
			case "pl":
			default:
				return "149";
			case "en":
				return "154";
		}
	};

	return dispatch => {
		dispatch(resetAppError());
		dispatch(requestQuestionnaire());
		fetch(wpApiAddress + `/wp-json/wp/v2/pages/${getLangNum(currentLang)}`)
			.then(response => {
				return response.json();
			})
			.then(json => dispatch(receiveQuestionnaire(json)))
			.catch(err => dispatch(setAppError(err)));
	};
};

//suggestions

const RECEIVE_ADVANCED_SEARCH_SUGGESTIONS = "RECEIVE_ADVANCED_SEARCH_SUGGESTIONS";

const receiveAdvancedSearchSuggestions = (data, index) => ({
	type: RECEIVE_ADVANCED_SEARCH_SUGGESTIONS,
	data,
	index
});

const RECEIVE_SEARCH_SUGGESTIONS = "RECEIVE_SEARCH_SUGGESTIONS";
const receiveSearchSuggestions = data => ({
	type: RECEIVE_SEARCH_SUGGESTIONS,
	data
});

const REQUEST_SEARCH_SUGGESTIONS = "REQUEST_SEARCH_SUGGESTIONS";
const requestSearchSuggestions = () => ({
	type: REQUEST_SEARCH_SUGGESTIONS
});

const REQUEST_ADVANCED_SEARCH_SUGGESTIONS = "REQUEST_ADVANCED_SEARCH_SUGGESTIONS";
const requestAdvancedSearchSuggestions = () => ({
	type: REQUEST_ADVANCED_SEARCH_SUGGESTIONS
});

const RESET_ADVANCED_SEARCH_SUGGESTIONS = "RESET_ADVANCED_SEARCH_SUGGESTIONS";
export const resetAdvancedSearchSuggestions = index => ({
	type: RESET_ADVANCED_SEARCH_SUGGESTIONS,
	index
});

const RESET_SEARCH_SUGGESTIONS = "RESET_SEARCH_SUGGESTIONS";
export const resetSearchSuggestions = () => ({
	type: RESET_SEARCH_SUGGESTIONS
});

const suggestionsLimit = process.env.REACT_APP_SUGGESTIONS_LIMIT;

export const getSuggestions = (field, prefix, index) => {
	return dispatch => {
		if (field === "primary") {
			dispatch(requestSearchSuggestions());
		} else {
			dispatch(requestAdvancedSearchSuggestions());
		}

		fetch(
			`${apiAddress}/api/suggestions?${
				field !== "primary" ? `field=${field}&` : ""
			}limit=${suggestionsLimit}&prefix=${prefix}`
		)
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				if (field === "primary" && typeof index === "undefined") {
					dispatch(receiveSearchSuggestions(json));
				} else {
					dispatch(receiveAdvancedSearchSuggestions(json, index));
				}
			})
			.catch(err => {
				dispatch(setAppError(err));
			});
	};
};

//contrast
const SET_CONTRAST = "SET_CONTRAST";
export const setContrast = isContrast => ({
	type: SET_CONTRAST,
	isContrast
});

//font size
const SET_FONT_SIZE = "SET_FONT_SIZE";
export const setFontSize = fontSize => ({
	type: SET_FONT_SIZE,
	fontSize
});
// MAP

const REQUEST_LOAD_POINTS = "REQUEST_LOAD_POINTS";
export const requestLoadPoints = () => ({
	type: REQUEST_LOAD_POINTS,
	isLoading: true
});

const RECEIVE_LOAD_POINTS = "RECEIVE_LOAD_POINTS";
export const receiveLoadPoints = data => ({
	type: RECEIVE_LOAD_POINTS,
	isLoading: false,
	data
});

export const loadPoints = queryStr => {
	return dispatch => {
		dispatch(requestLoadPoints());

		fetch(`${apiAddress}/api/points${queryStr}`, {
			accept: "application/json"
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				dispatch(receiveLoadPoints(json));
			})
			.catch(err => {
				dispatch(setAppError(err));
			});
	};
};

const REQUEST_LOAD_LOCATIONS = "REQUEST_LOAD_LOCATIONS";
export const requestLoadLocations = () => ({
	type: REQUEST_LOAD_LOCATIONS,
	isLoading: true
});

const RECEIVE_LOAD_LOCATIONS = "RECEIVE_LOAD_LOCATIONS";
export const receiveLoadLocations = data => ({
	type: RECEIVE_LOAD_LOCATIONS,
	isLoading: false,
	data
});

export const loadLocations = queryStr => {
	return dispatch => {
		dispatch(requestLoadLocations());

		fetch(`${apiAddress}/api/locations${queryStr}`, {
			accept: "application/json"
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				dispatch(receiveLoadLocations(json));
			})
			.catch(err => {
				dispatch(setAppError(err));
			});
	};
};
