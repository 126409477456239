import React from "react";
import Checkbox from "react-simple-checkbox";
import Form from "react-bootstrap/Form";

import withContexts from "@/scripts/withContext.js";
import styles from "@/components/search/Search.module.sass";

const CheckboxEuropeana = ({ input, langContext: { dict }, variant }) => {
	return (
		<div className={`${styles["search__europeana"]} ${variant ? styles[variant] : ""}`}>
			<Form.Label className={styles["search__europeana__label"]}>
				<span className={styles["search__europeana__label--span"]}>{dict.search.search_in_europeana}</span>
				<Checkbox
					{...input}
					color={{ backgroundColor: "#fff" }}
					checked={input.value}
					onChange={input.onChange}
					className={`${styles["search__checkbox"]} ${
						variant === "header" ? `checkbox--light` : "checkbox--europeana"
					}`}
					size="2"
				/>
				<span>{dict.search.search_in_europeana}</span>
				<i 
					className="icon-question" 
					data-tip={dict.search.search_in_europeana_tip}
					data-type="info"
					data-class="tt"
					data-place="right"
					data-effect="solid"
				/>
				
			</Form.Label>
		</div>
	);
};

export default withContexts(CheckboxEuropeana);
