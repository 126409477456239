import React from "react";

import { Link } from "react-router-dom";

import withContexts from "../../scripts/withContext.js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "../../components/button/Button.js";

const FooterBottomBar = ({ langContext: { dict } }) => (
	<div className="footer__bottom-bar">
		<Container>
			<Row>
				<div className="footer__bottom-bar--container">
					<span className="footer__bottom-bar--text">{dict.footers.copyright}</span>
					<Button
						variant="link-external"
						href={dict.footers.university_link}
						target="_blank"
						className="footer__bottom-bar--link"
					>
						{dict.footers.university}
						<sup>
							<i className="icon-external"></i>
						</sup>
					</Button>

					<Link variant="link" to={dict.footers.policy_privacy_link} className="footer__bottom-bar--link">
						{dict.footers.policy_privacy}
					</Link>
					<Link
						variant="link"
						to={dict.footers.accessibility_statement_link}
						className="footer__bottom-bar--link"
					>
						{dict.footers.accessibility_statement}
					</Link>
				</div>
				<div className="footer__bottom-bar--container-right">
					<span className="footer__bottom-bar--author">
						{dict.footers.credits}
						<Button
							variant="link-external"
							href={dict.footers.pcss_link}
							target="_blank"
							className="footer__bottom-bar--link"
						>
							<span className="footer__bottom-bar--author-full">{dict.footers.pcss_long}</span>
							<span className="footer__bottom-bar--author-small">{dict.footers.pcss_short}</span>
							<sup>
								<i className="icon-external"></i>
							</sup>
						</Button>
					</span>
				</div>
			</Row>
		</Container>
	</div>
);

export default withContexts(FooterBottomBar);
