import React from "react";
import { reduxForm, SubmissionError } from "redux-form";

import validate from "@/scripts/dateFacetFormValidation.js";
import withContexts from "@/scripts/withContext.js";

import Button from "@/components/button/Button.js";
import FacetFormDateFields from "./FacetFormDateFields/FacetFormDateFields";
import FacetDateActions from "@/containers/Results/ResultsSidebar/facets/Facet/FacetActions/FacetDateActions";

import styles from "./FacetForm.module.sass";

const FacetFormDate = ({
	langContext: { dict },
	handleSubmit,
	isSelected,
	submitForm,
	pristine,
	submitting,
	error,
	resetAll,
	missingValues,
	changeMissingTime,
	activeMissingTime
}) => (
	<form
		onSubmit={handleSubmit(values => {
			return new Promise((resolve, reject) => {
				const errors = validate(values);
				if (Object.keys(errors).length) {
					reject(new SubmissionError(errors));
				} else {
					resolve(values);
				}
			}).then(val => submitForm(val));
		})}
	>
		<div className={styles["form__head"]}>
			<FacetDateActions submitting={submitting} resetAll={resetAll} isSelected={isSelected} />
		</div>
		<div className={styles["form__content"]}>
			<FacetFormDateFields 
				error={error} 
				missingValues={missingValues} 
				changeMissingTime={changeMissingTime} 
				activeMissingTime={activeMissingTime} 
				/>
		</div>
		<div className={styles["form__bottom"]}>
			<Button
				className={styles["form__submit"]}
				type="submit"
				disabled={(!isSelected && !pristine) || pristine || submitting}
			>
				{dict.sidebar.filters.apply}
			</Button>
		</div>
	</form>
);


export default withContexts(
	reduxForm({
		enableReinitialize: true
	})(FacetFormDate)
);
