const initialState = {
	points: [],
	locations: [],
    isFetchingPoints: false,
    isFetchingLocations: false
};

export default function results(state = initialState, action) {
	switch (action.type) {
        case "REQUEST_LOAD_POINTS":
			return {
				...state,
				isFetchingPoints: true
			};
		case "RECEIVE_LOAD_POINTS":
			return {
				...state,
				points: action.data.locations,
				isFetchingPoints: false
			};
		case "REQUEST_LOAD_LOCATIONS":
			return {
				...state,
				locations: [],
				isFetchingLocations: true
			};
		case "RECEIVE_LOAD_LOCATIONS":
			return {
				...state,
				locations: action.data.locations,
				isFetchingLocations: false
			};
		default:
			return state;
	}
}
