import React from "react";

import withContexts from "@/scripts/withContext.js";

import Button from "@/components/button/Button.js";

import styles from "./Categories.module.sass";

const CategoriesHeader = ({ langContext: { dict }, isActive, toggleActive }) => (
	<div
		className={`${styles["categories__title-container"]} ${
			isActive ? `${styles["categories__title-container--hidden"]}` : ""
		}`}
	>
		<h3 className={styles["categories__title"]}>{dict.sidebar.filters.filters}</h3>
		<Button
			onClick={() => toggleActive()}
			variant="link"
			className={`${styles["categories__catalogue-collapse"]} ${
				isActive ? `${styles["categories__catalogue-collapse--hidden"]}` : ""
			}`}
		>
			{isActive ? dict.expand : dict.collapse}
			<i className="icon-chevron-down"></i>
		</Button>
	</div>
);

export default withContexts(CategoriesHeader);
