import React from "react";
import { Field, reduxForm } from "redux-form";

import Button from "@/components/button/Button.js";
import FacetSearchInput from "./FacetSearchInput";

import withContexts from "@/scripts/withContext.js";

import styles from "./FacetSearch.module.sass";

const FacetSearch = ({ langContext: { dict }, handleSubmit, pristine, submitting }) => (
	<form onSubmit={handleSubmit} className={styles["search-form"]}>
		<div className={styles["search-form__field"]}>
			<Field
				type="text"
				name="key"
				component={FacetSearchInput}
				label={dict.facets.search.submit}
				placeholder={dict.facets.search.placeholder}
			/>
			<div className={styles["search-form__icon"]}>
				<span className="icon-search"></span>
			</div>
		</div>
		<Button className={styles["search-form__submit"]} type="submit" disabled={pristine || submitting}>
			{dict.facets.search.submit}
		</Button>
	</form>
);

export default withContexts(
	reduxForm({
		enableReinitialize: true,
		onChange: (values, action, props) => {
			props.onSubmit(values);
		}
	})(FacetSearch)
);
