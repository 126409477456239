import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { withRouter } from "react-router";
import ReactGA from "react-ga";
import LoadingBar from "react-redux-loading-bar";

import Container from "react-bootstrap/Container";

import dict from "@/constants/dict";
import { LangContext } from "@/scripts/context";
import routes from "@/scripts/routes";
import * as Actions from "@/actions";

import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import CookiesBar from "@/components/cookies/Cookies";

import styles from "./App.module.sass";

const googleAnalyticsID = process.env.REACT_APP_GA_TAG_ID;
const googleAnalyticsID2 = process.env.REACT_APP_GA_TAG_ID2;
const googleAnalyticsID3 = process.env.REACT_APP_GA_TAG_ID3;

const ReactAnalyticsInit = () => {
	ReactGA.initialize(
		[
			{
				trackingId: googleAnalyticsID,
				gaOptions: {
					name: "tracker1"
				}
			},
			{
				trackingId: googleAnalyticsID2,
				gaOptions: {
					name: "tracker2"
				}
			},
			{
				trackingId: googleAnalyticsID3,
				gaOptions: {
					name: "tracker3"
				}
			}
		],
		{ debug: false, alwaysSendToDefaultTracker: false }
	);
};

const ReactAnalyticsBeacon = location => {
	ReactGA.pageview(location.pathname + location.search, ["tracker1"]);
	ReactGA.pageview(location.pathname + location.search, ["tracker2"]);
	ReactGA.pageview(location.pathname + location.search, ["tracker3"]);
};

class App extends Component {
	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			isSetCookies: cookies.get("policy"),
			fontSize: ""
		};
	}

	static getDerivedStateFromProps(props, state) {
		const pathName = props.history.location.pathname;
		const query = props.history.location.search;

		if (pathName !== state.currentPath || query !== state.query) {
			return {
				currentPath: pathName,
				query: query
			};
		}

		return null;
	}

	componentDidMount() {
		const { actions, cookies, location } = this.props;

		const fontSize = cookies.get("fontSize");
		if (fontSize) {
			actions.setFontSize(fontSize);
		}

		const contrast = cookies.get("contrast") === "true";
		if (contrast) {
			actions.setContrast(contrast);
			document.querySelector("body").classList[contrast ? "add" : "remove"]("contrast");
		}

		ReactAnalyticsInit();
		ReactAnalyticsBeacon(location);
	}

	componentDidUpdate(prevProps) {
		const { fontSize, isContrast, cookies, location } = this.props;

		if (location.pathname !== prevProps.location.pathname || location.search !== prevProps.location.search) {
			window.scrollTo(0, 0);
		}

		const HtmlSelectorClassList = document.querySelector("html").classList;

		if (prevProps.fontSize !== fontSize) {
			HtmlSelectorClassList.remove(prevProps.fontSize);
			HtmlSelectorClassList.add(fontSize);
			cookies.set("fontSize", fontSize, { path: "/" });
		}

		if (prevProps.isContrast !== isContrast) {
			document.querySelector("body").classList[isContrast ? "add" : "remove"]("contrast");
			cookies.set("contrast", isContrast, { path: "/" });
		}

		ReactAnalyticsBeacon(location);
	}

	setCookie = () => {
		const { cookies } = this.props;

		cookies.set("policy", true, { path: "/" });
		this.setState({ isSetCookies: true });
	};

	getComponent = (type, props, to, currentLang) => {
		const ItemComponent = type;

		return <ItemComponent {...props} to={to} currentLang={currentLang} />;
	};

	setLang = newLang => {
		const { actions } = this.props;

		actions.setNewLang(newLang);
	};

	setContrast = () => {
		const { actions, isContrast } = this.props;

		actions.setContrast(!isContrast);
	};

	setFontSize = () => {
		const { actions, fontSize } = this.props;

		switch (fontSize) {
			case "font-medium":
				return actions.setFontSize("font-big");
			case "font-big":
				return actions.setFontSize("font-normal");
			default:
				return actions.setFontSize("font-medium");
		}
	};

	render() {
		const { isSetCookies, currentPath } = this.state;
		const { langs, isContrast } = this.props;
		const currentLang = langs.filter(lang => lang.active)[0];
		const currentDict = dict[`${currentLang.id}`];

		return (
			<div className={styles.app} onScroll={this.handleScroll}>
				<LangContext.Provider value={{ langs: langs, currentLang: currentLang, dict: currentDict }}>
					<Container fluid className={styles.app__container}>
						<LoadingBar
							showFastActions
							style={{
								backgroundColor: "#c3a558",
								height: "3px",
								position: "fixed",
								top: "0",
								left: "0"
							}}
							updateTime={100}
							maxProgress={100}
							progressIncrease={10}
						/>
						<Header
							type={`${currentPath === "/" ? "main" : "secondary"}`}
							langs={langs}
							setLang={this.setLang}
							setContrast={this.setContrast}
							currentLang={currentLang}
							isContrast={isContrast}
							setFontSize={this.setFontSize}
						/>
						<div className={styles.app__main}>
							<Suspense fallback={<div>loading...</div>}>
								<Switch>
									{routes.map(route => (
										<Route
											key={route.id}
											exact={route.exact}
											path={route.path}
											render={props => this.getComponent(route.component, props, route.to)}
										/>
									))}
								</Switch>
							</Suspense>
						</div>
						<Footer type={`${currentPath === "/" ? "main" : "secondary"}`} />

						{!isSetCookies && <CookiesBar setCookie={this.setCookie} />}
					</Container>
				</LangContext.Provider>
			</div>
		);
	}
}

App.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			path: PropTypes.string,
			exact: PropTypes.bool,
			component: PropTypes.object
		}).isRequired
	),
	cookies: instanceOf(Cookies).isRequired,
	langs: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string,
			active: PropTypes.bool
		})
	),
	currentLang: PropTypes.string,
	isContrast: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	langs: state.app.langs,
	currentLang: state.app.currentLang,
	isContrast: state.app.isContrast,
	fontSize: state.app.fontSize
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch)
});

export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(App)));
