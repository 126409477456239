import React, { Component } from "react";

import withContexts from "@/scripts/withContext.js";

import CategoriesForm from "./CategoriesForm.js";
import CategoriesHeader from "./CategoriesHeader.js";
import CategoriesInfo from "./CategoriesInfo.js";

import styles from "./Categories.module.sass";

class Categories extends Component {
	state = {
		active: false
	};

	submitFiltersCategoriesForm = e => {
		const { setCategory } = this.props;
		e.preventDefault();

		setCategory();
	};

	toggleActive = () => {
		this.setState(state => {
			return { active: !state.active };
		});
	};

	render() {
		const { setCategory, resetCategory, searchCategory, category } = this.props;
		const { active } = this.state;

		return (
			<div>
				<CategoriesHeader isActive={active} toggleActive={this.toggleActive} />
				<div
					className={`${styles["categories__catalogue"]} ${
						active ? `${styles["categories__catalogue--hidden"]}` : ""
					}`}
				>
					<CategoriesInfo isActive={active} searchCategory={searchCategory} resetCategory={resetCategory} />
					{category && category.value && category.value.length && (
						<CategoriesForm
							onSubmit={setCategory}
							setCategory={setCategory}
							initialValues={{ category: searchCategory }}
							category={category}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default withContexts(Categories);
