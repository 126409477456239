import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import * as Actions from "@/actions";
import withContexts from "@/scripts/withContext.js";
import CopyButton from "@/components/copyButton/copyButton.js";
import Button from "@/components/button/Button.js";
import SidebarInfoAdvSearch from "@/containers/Results/ResultsSidebar/info/InfoAdvSearch.js";

import GeoMap from "@/components/geomap/GeoMap.js";

import styles from "./Info.module.sass";

Modal.setAppElement("#root");

class SidebarInfo extends Component {
	state = {
		fromAdvanced: false,
		showModal: false
	};

	handleOpenModal = this.handleOpenModal.bind(this);
	handleCloseModal = this.handleCloseModal.bind(this);

	handleOpenModal() {
		this.setState({ showModal: true });
	}

	handleCloseModal() {
		this.setState({ showModal: false });
	}

	componentDidMount() {
		const { location, history } = this.props;

		if (
			typeof location.state !== "undefined" &&
			location.state !== null &&
			location.state.prevPath === "/advanced-search"
		) {
			this.setState(() => {
				return { fromAdvanced: true };
			});
		}

		history.replace({ ...location, pathname: location.pathname, state: null });
	}
	componentDidUpdate(prevProps) {
		const { location } = this.props;

		if (prevProps.location.search !== this.props.location.search) {
			this.setState(() => {
				return {
					fromAdvanced:
						typeof location.state !== "undefined" &&
						location.state !== null &&
						location.state.prevPath === "/advanced-search"
				};
			});
		}
	}

	resetQuery = () => {
		const { resetQuery } = this.props;

		this.setState({ fromAdvanced: false });

		resetQuery();
    };
    
    isAdvancedSearchQuery = (query) => {
        const isAdvancedSearchRegEx = /\b:|\b AND |\b OR /g;

        return isAdvancedSearchRegEx.test(query);
    }

    checkQuery = (query) => {
        const {
            langContext: { dict }
        } = this.props;
        
        return (query === '*') ? dict.sidebar.info.allResources : query;
    } 

	render() {
		const {
			langContext: { dict },
			count,
			links,
			searchParams: { q },
			isFetching,
			parseQuery,
			europeana
		} = this.props;

		const { fromAdvanced } = this.state;

		const url = window.location.href;

        return (
            <div className={styles["sidebar-info"]}>
                <div className={styles["sidebar-info__head"]}>
                    <h2 className={styles["sidebar-info__text--title"]}>
                        {dict.sidebar.info.title}
                    </h2>
                    <div>
                        <ul className={styles["sidebar-info__actions"]}>
                            {fromAdvanced && window.performance.navigation.type !== 1 &&
                                <li className={styles["sidebar-info__actions--item"]}>
                                    <Link to={'/advanced-search'} className={styles["sidebar-info__actions--button"]}>
                                        {dict.sidebar.info.edit}
                                    </Link>
                                </li>
                            }
                            <li className={styles["sidebar-info__actions--item"]}>
                                <Button
                                    variant="link"
                                    onClick={this.resetQuery}
                                    className={styles["sidebar-info__actions--button"]}
                                >
                                    {dict.sidebar.info.clear}
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles["sidebar-info__content"]}>
                    <div className={styles["sidebar-info__block"]}>
                    { (this.isAdvancedSearchQuery(q)) ?
                        (
                            <div className={styles["sidebar-info__textbox"]}>
                                {parseQuery.map((singleQuery, idx) => (
                                    <SidebarInfoAdvSearch singleQuery={singleQuery} key={idx} />
                                ))}
                            </div>
                        ) : (
                            <p className={styles["sidebar-info__textbox"]}>{this.checkQuery(q)}</p>
                        )
                    }
                    </div>
                    <div className={styles["sidebar-info__block"]}>
                        <p className={styles["sidebar-info__textbox"]}>
                            <strong>
                                {dict.sidebar.info.number_of_objects_in_leopoldina}:
							</strong>
                            {!isFetching && (
                                <span className={styles["sidebar-info__textbox--query"]}>
                                    {count.results.toLocaleString("ru-RU")}
                                </span>
                            )}
                        </p>
                        <p className={styles["sidebar-info__textbox"]}>
                            <strong>
                                {dict.sidebar.info.including_objects_with_geolocation}
                                <i
                                    className="icon-question"
                                    data-tip={
                                        dict.sidebar.info.including_objects_with_geolocation_tip
                                    }
                                    data-type="info"
                                    data-class="tt"
                                    data-place="bottom"
                                    data-iscapture="true"
                                />
                                :
							</strong>
							<span className={styles["sidebar-info__textbox--query"]}>
								{count.geo.toLocaleString("ru-RU")}
							</span>
						</p>
						{!!count.geo && (
							<Button
								variant="link"
								onClick={this.handleOpenModal}
								className={styles["sidebar-info__block--link"]}
							>
								{dict.sidebar.info.show_on_map}
							</Button>
						)}
					</div>
					{europeana && (
					<div className={styles["sidebar-info__block"]}>
						<p className={styles["sidebar-info__textbox"]}>
							<strong>{dict.sidebar.info.number_of_objects_in_europeana}:</strong>
							<span className={styles["sidebar-info__textbox--query"]}>
								{count.europeana.toLocaleString("ru-RU")}
							</span>
						</p>
						{links.europeana && (
							<Button
								variant="link"
								target="_blank"
								href={links.europeana}
								className={styles["sidebar-info__block--link"]}
							>
								{dict.sidebar.info.go_to_europeana}
								<i className="icon-external" />
							</Button>
						)}
					</div>
					)}
					<div className={styles["sidebar-info__block"]}>
						<CopyButton text={url} className={styles["sidebar-info__block--link"]}>
							{dict.sidebar.info.copy_query_link}
						</CopyButton>
					</div>
				</div>
				<Modal
					isOpen={this.state.showModal}
					contentLabel={dict.map.title}
					className={styles["sidebar-info__showmap"]}
				>
					<GeoMap closeModal={this.handleCloseModal}></GeoMap>
				</Modal>
			</div>
		);
	}
}

SidebarInfo.propTypes = {
    count: PropTypes.object,
    links: PropTypes.object,
    parseQuery: PropTypes.array,
    europeana: PropTypes.bool,
};

const mapStateToProps = state => ({
    count: state.results.count,
    links: state.results.links,
    parseQuery: state.search.parseQuery,
    europeana: state.search.europeana
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default withContexts(withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarInfo)));
