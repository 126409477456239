import React from 'react';

import withContexts from '@/scripts/withContext.js';

import styles from '@/components/pagination/Pagination.module.sass';

const PaginationTotal = ({
  totalPages,
  currentPage,
  setPageByNum,
  langContext: { dict }
}) => (
  <div className={styles['pagination__total']}>
    <span className={styles['pagination__total-label']}>
      {dict.pagination.from}
    </span>
    <button
      className={`${styles['pagination__item-button']} ${
        currentPage === totalPages
          ? styles['pagination__item-button--disabled']
          : ''
      }`}
      onClick={() => setPageByNum(totalPages)}
      title={dict.pagination.lastPage}
    >
      {totalPages}
    </button>
  </div>
);

export default withContexts(PaginationTotal);
