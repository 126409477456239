import React from 'react';
import PropTypes from 'prop-types';
import './Button.sass';
import ButtonBootstrap from 'react-bootstrap/Button';

const Button = ({
  disabled = false,
  active = false,
  variant = 'primary',
  size,
  children,
  href,
  target,
  className,
  onClick,
  type = 'button'
}) => (
  <ButtonBootstrap
    variant={variant}
    type={type}
    disabled={disabled}
    size={size}
    active={active}
    href={href}
    target={target}
    className={className}
    onClick={onClick}
  >
    {children}
  </ButtonBootstrap>
);

Button.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  variant: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;
