import React from "react";

import withContexts from "@/scripts/withContext.js";

import FacetActionsItem from "./FacetActionsItem";

import styles from "./FacetActions.module.sass";

const FacetActions = ({ langContext: { dict }, selectAll, resetAll, submitting, isAllSelected, isAllUnselected }) => {
	return (
		<ul className={styles["actions"]}>
			<FacetActionsItem
				clickAction={selectAll}
				disabled={isAllSelected || submitting}
				label={dict.sidebar.filters.selectAll}
			/>
			<FacetActionsItem
				clickAction={resetAll}
				disabled={isAllUnselected || submitting}
				label={dict.sidebar.filters.clear}
			/>
		</ul>
	);
};

export default withContexts(FacetActions);
