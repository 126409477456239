const initialState = {
	params: {
		prefix: "",
		page: 1,
		pageSize: 30
	},
	totalPages: 1,
	indexes: [],
	numberOfResults: 0,
	isFetching: false
};

export default function indexes(state = initialState, action) {
	switch (action.type) {
		case "SET_PARAMS":
			const { params } = action.params;

			return {
				params: {
					...state.params,
					...params
				}
			};
		case "RECEIVE_LOAD_INDEXES":
			const { indexes, numberOfResults, isFetching } = action.data;

			const totalPages =
				numberOfResults > state.params.pageSize ? Math.ceil(numberOfResults / state.params.pageSize) : 1;
			return {
				...state,
				totalPages: totalPages,
				indexes: indexes,
				numberOfResults: numberOfResults,
				isFetching: isFetching
			};
		case "REQUEST_LOAD_INDEXES":
			return {
				...state,
				isFetching: true
			};
		default:
			return state;
	}
}
