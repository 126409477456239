import React from "react";

import withContexts from "@/scripts/withContext.js";

import PaginationList from "@/components/pagination/PaginationList.js";

import styles from "@/containers/Results/Results.module.sass";

const ResultsNavBottom = ({ setPageByNum, searchParams, totalPages }) => (
	<div
		className={`${styles["results__nav"]} ${styles["results__nav--bottom"]}`}
	>
		<PaginationList
			setPageByNum={setPageByNum}
			searchParams={searchParams}
			totalPages={totalPages}
		/>
	</div>
);

export default withContexts(ResultsNavBottom);
