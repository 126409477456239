import { LangContext } from "./context";
import React from "react";

const withContext = Component => props => (
	<LangContext.Consumer>
		{lang => <Component langContext={lang} {...props} />}
	</LangContext.Consumer>
);

export default withContext;
