import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import * as Actions from "@/actions";
import withContexts from "@/scripts/withContext.js";
import * as wpScripts from "@/scripts/wpObjectScripts.js";

import Container from "react-bootstrap/Container";
import SelectedResourcesItem from "./SelectedResourcesItem.js";

import styles from "./SelectedResources.module.sass";

import decorationLeft from "@/assets/images/main/decoration-left.svg";


class SelectedResources extends Component {
	componentDidMount() {
		const { actions, langContext } = this.props;
		actions.getSelectedResourcesPage(langContext.currentLang.id);
	}

	componentDidUpdate(prevProps) {
		const { actions, langContext, location } = this.props;
		if (langContext.currentLang.id !== prevProps.langContext.currentLang.id || location !== prevProps.location) {
			actions.getSelectedResourcesPage(langContext.currentLang.id);
		}
	}

	render() {
		const {
			langContext: { dict },
			pageData
		} = this.props;

		const boxes = pageData ? wpScripts.getBoxes(pageData) : [];
		const resourcesItems = boxes.map((listItem, index) => {
			return <SelectedResourcesItem {...listItem} key={index} />;
		});

		return (
			<div className={styles.resources}>
				<Container>
					<div className={styles.resources__top}>
						<img
							src={decorationLeft}
							className={styles["resources__top-img"]}
							alt={dict.search.image_decoration_alt1}
						/>
						<h2 className={styles["resources__top-title"]}>{dict.resources.title}</h2>
						<img
							src={decorationLeft}
							className={`${styles["resources__top-img"]} ${styles["resources__top-img--right"]}`}
							alt={dict.search.image_decoration_alt2}
						/>
					</div>
					<div className={styles.resources__content}>
						<ul className={styles["resources__content-blocks"]}>
							{resourcesItems}
							{ pageData && pageData.all_resources_link && <Link to={`${pageData.all_resources_link}`} className={styles["resources__content-link"]}>
								{dict.resources.link}
							</Link>
							}
						</ul>
					</div>
				</Container>
			</div>
		);
	}
}

SelectedResources.propTypes = {
	currentLang: PropTypes.string,
	actions: PropTypes.object,
	pageData: PropTypes.object
};

const mapStateToProps = state => ({
	pageData: state.mainPage.pageData,
	currentLang: state.app.currentLang
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch)
});

export default withContexts(withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectedResources)));
