const getBoxesFromData = (arr, dataObj) => {
    const newArr = [];

    arr.forEach( (listItem) => {
        if (listItem.includes('image')) {
            const index = parseInt(listItem.split('box_').pop().split('_image').shift(), 10) - 1;

            if (newArr[index]) {
                if (newArr[index].image) {
                    newArr[index].image.push(dataObj[listItem]);
                } else {
                    newArr[index].image = [dataObj[listItem]];
                }
            } else {
                newArr.push({image: [dataObj[listItem]]});
            }
        }

        if (listItem.includes('desc')) {
            const index = parseInt(listItem.split('box_').pop().split('_desc').shift(), 10) - 1;

            if (newArr[index]) {
                newArr[index].desc = dataObj[listItem];
            } else {
                newArr.push({desc: dataObj[listItem]});
            }
        }

        if (listItem.includes('title')) {
            const index = parseInt(listItem.split('box_').pop().split('_title').shift(), 10) - 1;

            if (newArr[index]) {
                newArr[index].title = dataObj[listItem];
            } else {
                newArr.push({title: dataObj[listItem]});
            }
        }

        if (listItem.includes('link')) {
            const index = parseInt(listItem.split('box_').pop().split('_link').shift(), 10) - 1;

            if (newArr[index]) {
                newArr[index].link = dataObj[listItem];
            } else {
                newArr.push({link: dataObj[listItem]});
            }
        }
    });

    return newArr;
};

const getListItemsFromData = (arr, dataObj) => {
    const newArr = [];

    arr.forEach( (listItem) => {
        if (listItem.includes('title')) {
            const index = parseInt(listItem.split('list_').pop().split('_title').shift(), 10) - 1;

            if (newArr[index]) {
                newArr[index].title = dataObj[listItem];
            } else {
                newArr.push({title: dataObj[listItem]});
            }
        }

        if (listItem.includes('portal')) {
            const index = parseInt(listItem.split('list_').pop().split('_portal').shift(), 10) - 1;

            if (newArr[index]) {
                newArr[index].portal = dataObj[listItem];
            } else {
                newArr.push({portal: dataObj[listItem]});
            }
        }

        if (listItem.includes('link')) {
            const index = parseInt(listItem.split('list_').pop().split('_link').shift(), 10) - 1;

            if (newArr[index]) {
                newArr[index].link = dataObj[listItem];
            } else {
                newArr.push({link: dataObj[listItem]});
            }
        }
    });

    return newArr;
};

export function getBoxes(dataObj) {
    const keys = Object.keys(dataObj);
    const boxes = keys.filter(key => key.includes('box'));

    return getBoxesFromData(boxes, dataObj);
};

export function getItems(dataObj) {
    const keys = Object.keys(dataObj);
    const items = keys.filter(key => key.includes('list'));

    return getListItemsFromData(items, dataObj);
};