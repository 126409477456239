import React from "react";
import withContexts from "@/scripts/withContext.js";
import { Link } from "react-router-dom";
import styles from "@/components/search/Search.module.sass";

const AdvancedSearchLink = ({ variant, langContext: { dict } }) => {
	return (
		<Link to={"/advanced-search"} className={`${styles["search__advsearch__link"]} ${styles[variant]}`}>
			<span>{dict.search.advanced_search}</span>
		</Link>
	);
};

export default withContexts(AdvancedSearchLink);
