import React, { Component } from "react";

import styles from "./Main.module.sass";
import SearchMain from "./SearchMain";
import SelectedResources from "@/components/selectedResources/SelectedResources";

class Main extends Component {
	render() {
		return (
			<div className={styles.app}>
				<SearchMain />
				<SelectedResources />
			</div>
		);
	}
}

export default Main;
