import React from "react";

import ResultsNav from "@/containers/Results/ResultsNav/ResultsNav.js";
import ResultsNavBottom from "@/containers/Results/ResultsNav/ResultsNavBottom.js";
import ResultsList from "@/containers/Results/ResultsList/ResultsList.js";

import styles from "@/containers/Results/Results.module.sass";

const ResultsMain = ({
	results,
	setPageByNum,
	setPageSize,
	searchParams,
	totalPages
}) => (
	<div className={styles["results__main"]}>
		<ResultsNav
			setPageByNum={setPageByNum}
			setPageSize={setPageSize}
			searchParams={searchParams}
			totalPages={totalPages}
		/>
		<ResultsList results={results} />
		<ResultsNavBottom
			setPageByNum={setPageByNum}
			searchParams={searchParams}
			totalPages={totalPages}
		/>
	</div>
);

export default ResultsMain;
