import React from "react";
import { Field, reduxForm } from "redux-form";

import Button from "@/components/button/Button.js";

import withContexts from "@/scripts/withContext.js";
import FacetFormItem from "./FacetFormItem";
import FacetActions from "@/containers/Results/ResultsSidebar/facets/Facet/FacetActions/FacetActions.js";

import styles from "./FacetForm.module.sass";

const FacetForm = ({
	langContext: { dict },
	isAllSelected,
	isAllUnselected,
	handleSubmit,
	selectAll,
	resetAll,
	facetItem,
	pristine,
	submitting,
	isFacetSet
}) => {
	return (
		<form onSubmit={handleSubmit}>
			<div className={styles["form__head"]}>
				<FacetActions
					selectAll={selectAll}
					resetAll={resetAll}
					submitting={submitting}
					isAllSelected={isAllSelected}
					isAllUnselected={isAllUnselected}
				/>
			</div>
			<div className={styles["form__content"]}>
				<ul className={styles["form__list"]}>
					{facetItem.map(item => (
						<Field
							type="checkbox"
							name={item.name}
							component={FacetFormItem}
							label={dict.facets.labels[item.label] ? dict.facets.labels[item.label] : item.label}
							count={item.value}
							key={item.label}
						/>
					))}
				</ul>
			</div>
			<div className={styles["form__bottom"]}>
				<Button
					className={styles["form__submit"]}
					type="submit"
					disabled={(!isFacetSet && isAllUnselected && !pristine) || pristine || submitting}
				>
					{dict.sidebar.filters.apply}
				</Button>
			</div>
		</form>
	);
};

export default withContexts(
	reduxForm({
		enableReinitialize: true
	})(FacetForm)
);
