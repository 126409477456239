import React from "react";

import withContexts from "@/scripts/withContext.js";

import Pagination from "@/components/pagination/Pagination.js";
import PageSize from "@/components/pageSize/PageSize.js";

import styles from "@/containers/Results/Results.module.sass";

const ResultsNav = ({
	setPageByNum,
	setPageSize,
	searchParams,
	totalPages
}) => (
	<div className={styles["results__nav"]}>
		<div className={styles["results__page-size"]}>
			<PageSize setPageSize={setPageSize} searchParams={searchParams} />
		</div>
		<Pagination
			setPageByNum={setPageByNum}
			searchParams={searchParams}
			totalPages={totalPages}
		/>
	</div>
);

export default withContexts(ResultsNav);
