import React from "react";

import Container from "react-bootstrap/Container";
import ReactTooltip from "react-tooltip";
import SearchFormContainer from "@/components/search/SearchFormContainer.js";
import SearchMainHeader from "./SearchMainHeader";
import styles from "@/components/search/Search.module.sass";

const SearchMain = () => {
	return (
		<section className={styles["search"]}>
			<Container className={styles["search__container"]}>
				<SearchMainHeader />
				<SearchFormContainer variantClass="main" formName="searchMain" />
			</Container>
			<ReactTooltip />
		</section>
	);
};

export default SearchMain;
