import React from "react";

import withContexts from "@/scripts/withContext.js";

import styles from "@/containers/Results/Results.module.sass";

const ResultsNoResults = ({ langContext: { dict } }) => (
	<div className={styles["results__no-results"]}>{dict.results.no_results}</div>
);

export default withContexts(ResultsNoResults);
