import React from "react";

import withContexts from "@/scripts/withContext.js";
import styles from "../IndexesList/IndexesList.module.sass";
import PaginationList from "@/components/pagination/PaginationList.js";

const IndexesNavBottom = ({ setPageByNum, searchParams, totalPages }) => (
	<div className={`${styles["results__nav"]} ${styles["results__nav--bottom"]}`}>
		<PaginationList setPageByNum={setPageByNum} searchParams={searchParams} totalPages={totalPages} />
	</div>
);

export default withContexts(IndexesNavBottom);
