import React from "react";

import ResultsListItem from "./ResultsListItem/ResultsListItem.js";

import styles from "@/containers/Results/Results.module.sass";
import ReactTooltip from "react-tooltip";

const ResultsList = ({ results }) => (
	<ul className={styles["results__list"]}>
		{results.map(result => (
			<ResultsListItem result={result} key={result.id} />
		))}
		<ReactTooltip effect="solid"/>
	</ul>
);

export default ResultsList;
