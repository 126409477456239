export default [
	{
		id: 1,
		name: "about-project"
	},
	{
		id: 2,
		name: "data-sources"
	},
	{
		id: 3,
		name: "digital-services"
	},
	{
		id: 4,
		name: "university-units"
	},
	{
		id: 5,
		name: "partners"
	},
	{
		id: 6,
		name: "privacy-policy"
	},
	{
		id: 7,
		name: "accessibility-statement"
	},
	{
		id: 8,
		name: "how-to-search-in-the-portal"
	},
	{
		id: 9,
		name: "licenses"
	},
	{
		id: 10,
		name: "contact"
	}
];
