import React from "react";
import PropTypes from "prop-types";
import "./Dropdown.sass";
import Dropdown from "react-bootstrap/Dropdown";

const DropdownCustom = ({ triggerAction, id, toggle, options, variant = "regular" }) => (
    <Dropdown>
        <Dropdown.Toggle id={id} variant={variant}>
            {toggle}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-right">
            {options.map((option, index) => (
                <Dropdown.Item
                    key={option.id}
                    eventKey={index + 1}
                    onClick={() => triggerAction(option)}
                >
                    {option.label}
                </Dropdown.Item>
            ))}
        </Dropdown.Menu>
    </Dropdown>
);

DropdownCustom.propTypes = {
    options: PropTypes.array,
    id: PropTypes.string,
    toggle: PropTypes.string,
    variant: PropTypes.string,
    onClick: PropTypes.func
};

export default DropdownCustom;
