import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Actions from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withContexts from '../../scripts/withContext.js';
import { bindActionCreators } from 'redux';
import menuItems from '../../scripts/getToKnowLeopoldinaSubpages.js';
import { NavLink } from 'react-router-dom';
import flattenArray from '../../scripts/flattenArray.js';

import styles from './GetToKnowLeopoldina.module.sass';
import Button from '../../components/button/Button.js';

class GetToKnowLeopoldina extends Component {
  constructor() {
    super();
    this.state = {
      active: false
    };
  }

  componentDidMount() {
    flattenArray(menuItems);
  }

  render() {
    const {
      langContext: { dict }
    } = this.props;

    return (
      <aside className={styles.app__menu}>
        <div className={`${styles['app__menu-list--top']}`}>
          <h2 className={`${styles['app__menu-list--title']}`}>
            {dict.know_leopoldina.menu_title}
          </h2>
          <Button
            onClick={() => this.setState({ active: !this.state.active })}
            variant="link"
            className={`${styles['app__menu-list--btn']} ${this.state
              .active && `${styles['collapseMenu-active']}`}`}
          >
            {this.state.active ? dict.expand : dict.collapse}
            <i className="icon-arrow-down-full"></i>
          </Button>
        </div>
        <ul
          className={`${styles['app__menu-list']} ${this.state.active &&
            `${styles['collapseMenu']}`}`}
        >
          {menuItems.map((item, index) => (
            <li key={index} className={`${styles['app__menu-list--item']}`}>
              <NavLink
                to={`/get-to-know-leopoldina/${item.name}`}
                className={`${styles['app__menu-list--link']}`}
              >
                {dict.know_leopoldina[item.name]}
                <i className="icon-accept"></i>
              </NavLink>
            </li>
          ))}
        </ul>
      </aside>
    );
  }
}

GetToKnowLeopoldina.propTypes = {
  currentLang: PropTypes.string,
  actions: PropTypes.object
};

const mapStateToProps = state => ({
  pageData: state.getToKnowLeopoldina.pageData,
  currentLang: state.app.currentLang
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default withContexts(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(GetToKnowLeopoldina)
  )
);
