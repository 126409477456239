import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './Content.sass';

const Content = ({ type = 'main', children }) => (
  <section className={`content ${type}`}>
    <Container>
      <Row>{children}</Row>
    </Container>
  </section>
);

Content.propTypes = {
  type: PropTypes.oneOf(['main', 'light'])
};

export default Content;
