import React from "react";
import { withRouter } from "react-router";

import withContexts from "@/scripts/withContext.js";

import styles from "./IndexesMenu.module.sass";

const IndexesMenuInfo = ({ label, info }) => (
	<div className={`${styles["menu__list--top"]}`}>
		<h3 className={`${styles["menu__list--title"]}`}>{label}:</h3>
		<h3 className={`${styles["menu__list--text"]}`}>{info}</h3>
	</div>
);

export default withContexts(withRouter(IndexesMenuInfo));
