import React from "react";
import Form from "react-bootstrap/Form";

import withContexts from "@/scripts/withContext";

import styles from "@/containers/Results/ResultsSidebar/facets/Facet/FacetForm/FacetFormDateFields/FacetFormDateFields.module.sass";

const DateInput = ({ input, label, placeholder, langContext: { dict }, meta: { touched, error, submitFailed } }) => (
	<div className={styles["input"]}>
		<Form.Label className={styles["input__label"]} srOnly>
			{label}
		</Form.Label>
		<Form.Control
			{...input}
			placeholder={placeholder}
			pattern="[0-9.]*$"
			onChange={e => {
				if (e.target.validity.valid) {
					input.onChange(e);
				}
			}}
			className={styles["date__form-control"]}
		/>
		{touched && error && submitFailed && (
			<span className={styles["date__error"]}>{dict.facets.form.errors[error]}</span>
		)}
	</div>
);

export default withContexts(DateInput);
