import React from 'react';
// import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from '../../components/button/Button.js';
import withContexts from '../../scripts/withContext.js';
import HeaderTopLang from './HeaderTopLang';
import HeaderAccess from './HeaderAccess';

const HeaderTopBar = ({
  setLang, 
  setContrast,
  fontSize,
  currentLang, 
  questionnairePage,
  langContext: {dict}
}) => (
  <div className="header__top-bar">
    <Container>
      <Row>
        { questionnairePage && questionnairePage.questionnaire_link && <Button variant="link" href={questionnairePage.questionnaire_link} target="_blank" className="header__top-bar--link">{dict.headers.share}</Button>}
        <HeaderAccess setContrast={setContrast} fontSize={fontSize}/>
        <HeaderTopLang setLang={setLang} currentLang={currentLang} />
      </Row>
    </Container>
  </div>
);

HeaderTopBar.propTypes = {};

export default withContexts(HeaderTopBar);
