const initialState = {
  isFetching: false,
  isLoadingError: false,
  pageData: {
    title: {
      rendered: '',
    },
    acf: {}
  }
};

export default function knowLeopoldina(state = initialState, action) {
  switch (action.type) {
    case 'REQUEST_KNOW_LEOPOLDINA_SUBPAGE':
      return {
        ...state,
        isFetching: true,
        isLoadingError: false
      };
    case 'RECEIVE_KNOW_LEOPOLDINA_SUBPAGE':
      return {
        ...state,
        pageData: action.data
      };
    default:
      return state;
  }
}
