import queryString from "query-string";

const newFacet = facet =>
	Object.keys(facet).reduce((obj, key) => {
		return {
			...obj,
			[`f.${key}`]: facet[key]
		};
	}, {});

export const transformToObjArr = obj =>
	Object.keys(obj).map(facet => {
		return {
			label: facet,
			value: obj[`${facet}`]
		};
	});

export const setSearchParams = (query, searchParams) => {
	const queryObj = queryString.parse(query);

	const page = queryObj.page;
	const pageSize = queryObj.pageSize;
	const category = queryObj["f.category"];
	const europeana = queryObj.europeana;
	const missing = queryObj.missing;

	const facets = Object.keys(queryObj)
		.filter(item => item.startsWith("f.") && item !== "f.category")
		.reduce((arr, key) => {
			return [
				...arr,
				{
					[key]: Array.isArray(queryObj[key]) ? queryObj[key] : [queryObj[key]]
				}
			];
		}, []);

	const newParams = {
		params: {
			...searchParams,
			page: page ? parseInt(page, 10) : searchParams.page,
			pageSize: pageSize ? parseInt(pageSize, 10) : searchParams.pageSize,
			q: queryObj.q,
			europeana: queryObj.europeana
		},
		"f.category": category ? category : "",
		facets: facets.length ? facets : [],
		europeana: europeana,
		missing: missing
	};

	return newParams;
};

export const setUrlFromParams = params => {
	const queryParamsStr = queryString.stringify(params.params, {
		arrayFormat: "delimiter"
	});

	const categoryParamsStr = params["f.category"]
		? queryString.stringify(
				{ "f.category": params["f.category"] },
				{
					arrayFormat: "delimiter"
				}
		  )
		: null;

	const facetsParamsStr = params.facets
		? params.facets.reduce((str, obj) => `${str}&${queryString.stringify(obj)}`, "")
		: null;

	const europeanaStr = params.europeana ? "&europeana=true" : null;
	const missingStr = params.missing ? `&missing=${params.missing}` : null;

	return `?${queryParamsStr}${categoryParamsStr ? `&${categoryParamsStr}` : ""}${
		facetsParamsStr ? `${facetsParamsStr}` : ""
	}${europeanaStr ? europeanaStr : ""}${missingStr ? missingStr : ""}`;
};

export const setPageSize = (size, updateUrl, params) => {
	const { searchParams, searchCategory, searchFacets } = params;

	if (searchParams.pageSize !== size) {
		updateUrl({
			params: {
				...searchParams,
				pageSize: size,
				page: 1
			},
			"f.category": searchCategory,
			facets: searchFacets
		});
	}
};

export const setPageByNum = (num, updateUrl, params) => {
	const { searchParams, searchCategory, searchFacets } = params;

	if (searchParams.page !== parseInt(num, 10)) {
		updateUrl({
			params: {
				...searchParams,
				page: parseInt(num, 10)
			},
			"f.category": searchCategory,
			facets: searchFacets
		});
	}
};

export const setFacet = (facet, updateUrl, params, missing) => {
	const { searchParams, searchFacets, searchCategory } = params;

	const facetAttr = `f.${Object.keys(facet)[0]}`;
	const facetVal = facet[Object.keys(facet)[0]];

	const hasFacet = searchFacets && searchFacets.filter(item => Object.keys(item)[0] === facetAttr).length;

	updateUrl({
		params: {
			...searchParams,
			page: 1,
			pageSize: 30
		},
		"f.category": searchCategory,
		facets: hasFacet
			? searchFacets.map(item => (Object.keys(item)[0] === facetAttr ? { [facetAttr]: facetVal } : item))
			: [...searchFacets, { ...newFacet(facet) }],
		...missing
	});
};

export const setCategory = (obj, updateUrl, params) => {
	const { searchParams } = params;

	updateUrl({
		params: {
			...searchParams,
			page: 1,
			pageSize: 30
		},
		"f.category": obj.category,
		facets: []
	});
};

export const resetCategory = (updateUrl, params) => {
	const { searchParams } = params;

	updateUrl({
		params: {
			...searchParams,
			page: 1,
			pageSize: 30
		}
	});
};

export const resetQuery = updateUrl => {
	updateUrl({
		params: {
			q: "*",
			page: 1,
			pageSize: 30
		}
	});
};

export const resetFacet = (facet, updateUrl, params) => {
	const { searchFacets, searchCategory, searchParams } = params;

	updateUrl({
		params: {
			...searchParams,
			page: 1,
			pageSize: 30
		},
		"f.category": searchCategory,
		facets: [...searchFacets.filter(item => Object.keys(item)[0] !== `f.${facet}`)]
	});
};

export const resetAllFacets = (updateUrl, params) => {
	const { searchParams, searchCategory } = params;

	updateUrl({
		params: {
			...searchParams,
			page: 1,
			pageSize: 30
		},
		"f.category": searchCategory,
		facets: []
	});
};
