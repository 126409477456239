import queryString from "query-string";

export const setUrlFromParams = (type, params) => {
	const queryParamsStr = queryString.stringify(
		{ ...params.params, field: type },
		{
			arrayFormat: "delimiter"
		}
	);
	return `${queryParamsStr}`;
};

export const setPageSize = (size, updateUrl, params) => {
	const { searchParams } = params;

	if (searchParams.pageSize !== size) {
		updateUrl({
			params: {
				...searchParams,
				pageSize: size,
				page: 1
			}
		});
	}
};

export const setParams = (query, searchParams) => {
	const queryObj = queryString.parse(query);

	const page = queryObj.page;
	const pageSize = queryObj.pageSize;

	const newParams = {
		params: {
			...searchParams,
			page: page ? parseInt(page, 10) : searchParams.page,
			pageSize: pageSize ? parseInt(pageSize, 10) : searchParams.pageSize,
			prefix: queryObj.prefix
		}
	};
	return newParams;
};

export const setPageByNum = (num, updateUrl, params) => {
	const { searchParams } = params;

	if (searchParams.page !== parseInt(num, 10)) {
		updateUrl({
			params: {
				...searchParams,
				page: parseInt(num, 10)
			}
		});
	}
};
