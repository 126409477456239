import React from "react";

import withContexts from "@/scripts/withContext.js";

import Button from "@/components/button/Button.js";
import FacetChosenItem from "./FacetChosenItem";

import styles from "./FacetChosen.module.sass";

const FacetChosen = ({ removeFacetForm, clearFacetForm, facetValues, facetName, facetMissing, changeMissingTime, langContext: { dict } }) => {
	if (!facetValues || !facetValues[`f.${facetName}`]) {
		return null;
	}

	return (
		<div className={styles["chosen"]}>
			<ul className={styles["chosen__list"]}>
				{facetMissing === 'time' && 
					<FacetChosenItem
						facetName={facetName}
						facetValue={dict.facets.form.timeline} 
						removeAction={removeFacetForm}
						facetMissing={facetMissing === 'time'}
						changeMissingTime={changeMissingTime} 
					/>
				}
				{ facetMissing !== 'time' && facetValues[`f.${facetName}`].map((value, index) => (
					<FacetChosenItem
						key={`${value}${index}`}
						index={index}
						facetName={facetName}
						facetValue={value}
						removeAction={removeFacetForm}
					/>
				))}
			</ul>
			<div className={styles["chosen__clear"]}>
				<Button variant="link" onClick={() => clearFacetForm()} className={styles["chosen__button"]}>
					{dict.sidebar.filters.clear}
				</Button>
			</div>
		</div>
	);
};

export default withContexts(FacetChosen);
