import React, { Component } from "react";
import { Field } from "redux-form";

import withContexts from "@/scripts/withContext.js";
import Select from "./fields/Select";
import Checkbox from "./fields/Checkbox";
import DateInput from "./fields/DateInput";

import styles from "./FacetFormDateFields.module.sass";

const era = ["ad", "bc"];


class FacetFormDateFields extends Component { 
	render() {
		const {
			missingValues, 
			langContext: { dict }, 
			error,
			changeMissingTime,
			activeMissingTime
		} = this.props;

		return(
			<div className={styles["date"]}>
				{ missingValues.time > 0 && 
				<div>
					<div className={styles["date__container"]}>
						<Field 
							name={`timeline`} 
							component={Checkbox} 
							type={`checkbox`}
							label={dict.facets.form.timeline} 
							count={missingValues.time}
							actionChange={changeMissingTime}
						/>
					</div> 
					<div className={!activeMissingTime ? styles["date__shadow"] : ''}>
						<div className={styles["date__container"]}>{dict.facets.form.dateRange}:</div>
					</div>
				</div>
				}
				
				<div className={!activeMissingTime ? styles["date__shadow"] : ''}>
					<div className={styles["date__container"]}>
						{dict.facets.form.from}:
						<Field
							name={`from`}
							label={dict.facets.form.dateFrom}
							placeholder={dict.facets.form.datePlaceholder}
							component={DateInput}
						/>
						<Field name={`fromEra`} component={Select} label={dict.facets.form.type} options={era} />
					</div>
					<div className={styles["date__container"]}>
						{dict.facets.form.to}:
						<Field
							name={`to`}
							type="text"
							label={dict.facets.form.dateTo}
							placeholder={dict.facets.form.datePlaceholder}
							component={DateInput}
						/>
						<Field name={`toEra`} component={Select} label={dict.facets.form.type} options={era} />
					</div>
				</div>
				
				
				{error && (
					<div className={styles["date__container"]}>
						<span className={styles["date__error"]}>{dict.facets.form.errors[error]}</span>
					</div>
				)}
			</div>
		)
	}
};

export default withContexts(FacetFormDateFields);
