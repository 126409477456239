import React from "react";

import withContexts from "@/scripts/withContext.js";

import Button from "@/components/button/Button.js";
import Facet from "./Facet/Facet";

import styles from "./Facets.module.sass";
import fields from "@/fields.json";

const Facets = ({
	langContext: { dict },
	setFacet,
	setMissing,
	facets,
	resetFacet,
	searchFacets,
	searchCategory,
	resetAllFacets
}) => {
	const facetFields = fields.fieldSets.filter(field => field.categoryName === searchCategory)[0];
	const otherFields = facetFields ? facetFields.otherFields : [];

	if (otherFields.includes("time") && !facets.filter(item => item.label === "time").length) {
		facets.push({ label: "time", value: "" });
	}

	return (
		<div className={styles["facets"]}>
			<div className={styles["facets__title"]}>
				<h3 className={styles["facets__text--title"]}>
					{dict.sidebar.filters.catalogFilters}: {dict.fields[searchCategory]}
				</h3>
				<Button variant="link" onClick={resetAllFacets} disabled={!searchFacets || !searchFacets.length}>
					{dict.sidebar.filters.clear}
				</Button>
			</div>
			<div className={styles["facets__group"]}>
				{facets.map(facet => (
					<Facet
						facet={facet.label}
						value={facet.value}
						key={facet.label}
						setFacet={setFacet}
						setMissing={setMissing}
						resetFacet={resetFacet}
						searchFacets={searchFacets}
					/>
				))}
			</div>
		</div>
	);
};

export default withContexts(Facets);
