export default [
	{
		id: 1,
		name: "classification"
	},
	{
		id: 2,
		name: "electronicDocumentType"
	},
	{
		id: 3,
		name: "title"
	},
	{
		id: 4,
		name: "person"
	},
	{
		id: 5,
		name: "group"
	},
	{
		id: 6,
		name: "place"
	},
	{
		id: 7,
		name: "event"
	},
	{
		id: 8,
		name: "collection"
	},
	{
		id: 9,
		name: "language"
	},
	{
		id: 10,
		name: "period"
	},
	{
		id: 11,
		name: "material"
	},
	{
		id: 12,
		name: "technique"
	},
	{
		id: 13,
		name: "signature"
	},
	{
		id: 14,
		name: "dataSource"
	},
	{
		id: 15,
		name: "uwrUnit"
	},
	{
		id: 16,
		name: "keywords"
	}
];
