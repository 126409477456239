import React from "react";
import { FieldArray, reduxForm, SubmissionError } from "redux-form";
import Form from "react-bootstrap/Form";

import withContexts from "../../scripts/withContext.js";
import validate from "../../scripts/advancedSearchFormValidation.js";

import Button from "../../components/button/Button.js";
import AdvancedSearchFormFieldset from "./AdvancedSearchFormFieldset.js";

import styles from "./AdvancedSearch.module.sass";

const AdvancedSearchForm = ({
	handleSubmit,
	pristine,
	reset,
	dirty,
	submitting,
	submitForm,
	submitFailed,
	conjOptions,
	getAutocompleteList,
	types,
	era,
	error,
	suggestions,
	resetAdvancedSearchSuggestions,
	langContext: { dict }
}) => {
	return (
		<Form
			onSubmit={handleSubmit(values => {
				return new Promise((resolve, reject) => {
					const errors = validate(values);
					if (Object.keys(errors).length) {
						reject(new SubmissionError(errors));
					} else {
						resolve(values);
					}
				}).then(val => submitForm(val));
			})}
			autoComplete="off"
		>
			<Form.Group>
				<FieldArray
					name="searchQueries"
					types={types}
					era={era}
					conjOptions={conjOptions}
					component={AdvancedSearchFormFieldset}
					getAutocompleteList={getAutocompleteList}
					resetAdvancedSearchSuggestions={resetAdvancedSearchSuggestions}
					suggestions={suggestions}
				/>
			</Form.Group>
			{dirty && error && submitFailed && (
				<div className={styles["advanced-search__form-error"]}>
					{dict.advanced_search.form.errors[error]}
				</div>
			)}
			<div className={styles["advanced-search__buttons"]}>
				<Button
					className={styles["advanced-search__button--reset"]}
					disabled={pristine || submitting}
					onClick={reset}
					variant="link"
				>
					{dict.advanced_search.form.removeAll}
				</Button>
				<Button
					className={styles["advanced-search__button--submit"]}
					type="submit"
					disabled={submitting || pristine}
				>
					{dict.advanced_search.form.search}
				</Button>
			</div>
		</Form>
	);
};

export default withContexts(
	reduxForm({
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		destroyOnUnmount: false,
		form: "advancedSearch"
	})(AdvancedSearchForm)
);
