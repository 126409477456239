import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Map, TileLayer } from 'react-leaflet'

import * as Actions from '@/actions';
import withContexts from "@/scripts/withContext.js";
import { setPointsUrl } from "@/scripts/geoMapService.js";
import Button from "@/components/button/Button.js";
import GeoMapMarkers from '@/components/geomap/GeoMapMarkers';

import styles from "@/components/geomap/GeoMap.module.sass";
import 'leaflet/dist/leaflet.css';

import imgMarkerObject from '@/assets/images/map/markerObject.svg';
import imgMarkerCluster from '@/assets/images/map/markerCluster.svg';
import imgLoading from '@/assets/images/map/loading.gif';

class GeoMap extends Component {

    loadPoints() {
        const { actions, location } = this.props;
        const map = this.refs.map.leafletElement;

        map.closePopup(); 
        actions.loadPoints(`?${setPointsUrl(map, location.search)}`);
    }

    componentDidMount() {
        const map = this.refs.map.leafletElement;

        this.loadPoints();
        map.zoomControl.setPosition('bottomleft');
        map.doubleClickZoom.disable();
      }

    render() {
        const handleMove = () => {
            this.loadPoints();
        }

        const position = [process.env.REACT_APP_MAP_LAT, process.env.REACT_APP_MAP_LNG];
        const map = this.refs.map ? this.refs.map.leafletElement : {};

        const {
            langContext: { dict },
            closeModal,
            count,
            points,
            isFetchingPoints
        } = this.props;

        return (
            <div className={styles["map"]}>
                <div className={styles["map__header"]}>
                    <h2 className={styles["map__text--title"]}>{dict.map.title}</h2>
                    <Button 
                        variant="link"
                        onClick={closeModal}
                        className={styles["map__close"]}
                    >
                        <span>{dict.map.close}</span> 
                        <i className="icon-close" />
                    </Button>
                </div>
                <div className={styles["map__info"]}>
                    <h5 className={styles["map__text--info-main"]}>{dict.map.objectWithGeo}</h5>
                    <p className={styles["map__text--info"]}>
                        {dict.map.numberOfObjectsInLeopoldina}: <strong>{count.results.toLocaleString("ru-RU")}</strong>, {dict.map.includingObjectsWithGeolocation}: <strong>{count.geo.toLocaleString("ru-RU")}</strong>
                    </p>
                </div>
                <div className={styles["map__legend"]}>
                    <h6 className={styles["map__text--legend-title"]}>{dict.map.legend.title}:</h6>
                    <ul className={styles["map__legend--list"]}>
                        <li className={styles["map__legend--item"]}>
                            <img
                                src={imgMarkerCluster}
                                className={styles["map__legend--img-cluster"]}
                                alt={dict.map.legend.group}
                            />
                            <span className={styles["map__text--legend-span"]}>{dict.map.legend.group}</span>
                        </li>
                        <li className={styles["map__legend--item"]}>
                            <img
                                src={imgMarkerObject}
                                className={styles["map__legend--img-object"]}
                                alt={dict.map.legend.object}
                            />
                            <span className={styles["map__text--legend-span"]}>{dict.map.legend.object}</span>
                        </li>
                    </ul>
                </div>
                <div className={styles["map__box"]}>
                    { isFetchingPoints && 
                        <div className={styles["map__box--loading"]}>
                            <img src={imgLoading} alt={dict.map.loading} />
                        </div>
                    }
                    <Map 
                        center={position} 
                        zoom={process.env.REACT_APP_MAP_ZOOM} 
                        onMoveend={handleMove}
                        className={styles["map__container"]} 
                        ref='map'
                        >
                        <TileLayer
                            minZoom={process.env.REACT_APP_MAP_MINZOOM}
                            maxZoom={process.env.REACT_APP_MAP_MAXZOOM}
                            attribution={dict.map.copyrights}
                            url={process.env.REACT_APP_MAP_TILE_URL}
                        />
                        {points.map((item, index) => 
                            <GeoMapMarkers key={index} item={item} map={map}  />
                        )}
                    </Map>
                </div>
            </div>                            
        );
    };
}

GeoMap.propTypes = {
    count: PropTypes.object,
    points: PropTypes.array,
};

const mapStateToProps = state => ({
    count: state.results.count,
    points: state.map.points,
    isFetchingPoints: state.map.isFetchingPoints,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default withContexts(withRouter(connect(mapStateToProps, mapDispatchToProps)(GeoMap)));
