import React from 'react';
import Form from 'react-bootstrap/Form';

import withContexts from '../../scripts/withContext.js';

import styles from './AdvancedSearch.module.sass';

const AdvancedSearchFormQuerySelect = ({
  input,
  options,
  dict,
  label,
  meta: { touched, error, submitFailed }
}) => (
  <div className={styles['advanced-search__field-select']}>
    <Form.Label srOnly>{label}</Form.Label>
    <Form.Control
      as="select"
      {...input}
      className={styles['advanced-search__form-control']}
    >
      {options.map(option => (
        <option key={option} value={option}>
          {dict[option]}
        </option>
      ))}
    </Form.Control>
    {touched && error && submitFailed && (
      <div className={styles['advanced-search__error']}>{error}</div>
    )}
  </div>
);

export default withContexts(AdvancedSearchFormQuerySelect);
