import React from "react";
import withContexts from "@/scripts/withContext.js";
import styles from "../IndexesList/IndexesList.module.sass";
import Pagination from "@/components/pagination/Pagination.js";
import PageSize from "@/components/pageSize/PageSize.js";

const IndexesNav = ({ setPageByNum, setPageSize, searchParams, totalPages }) => (
	<div className={styles["results__nav"]}>
		<div className={styles["results__page-size"]}>
			<PageSize setPageSize={setPageSize} searchParams={searchParams} />
		</div>
		<Pagination setPageByNum={setPageByNum} searchParams={searchParams} totalPages={totalPages} />
	</div>
);

export default withContexts(IndexesNav);
