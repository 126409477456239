import React from "react";

import withContexts from "@/scripts/withContext.js";

import styles from "@/components/pageSize/PageSize.module.sass";

const pageSizes = [30, 60, 90];

const PageSize = ({
	setPageSize,
	searchParams: { pageSize },
	langContext: { dict }
}) => {
	return (
		<div className={styles["page-size"]}>
			<p className={styles["page-size__text"]}>{dict.pageSize.onPage}:</p>
			<ul className={styles["page-size__list"]}>
				{pageSizes.map(item => (
					<li className={styles["page-size__item"]} key={item}>
						<button
							className={`${styles["page-size__button"]} ${
								item === pageSize ? styles["page-size__button--active"] : ""
							}`}
							onClick={() => setPageSize(item)}
							title={dict.pageSize.size}
						>
							{item}
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

export default withContexts(PageSize);
