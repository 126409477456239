import React from 'react';
import withContexts from '../../scripts/withContext.js';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';


const HeaderTopLang = ({setLang, currentLang, langContext: {langs}}) => {
  return (
    <Navbar bg="transparent" expand="lg" sticky="top" className="header__lang">
      <div className="header__lang-menu">
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title={currentLang.name} id="basic-nav-dropdown" className="header__lang-current">
              {langs.map(lang => 
                <NavDropdown.Item   key={lang.id} onClick={() => setLang(lang)} className={`header__lang-item ${lang.active === true ? 'header__lang-item--current' : ''}`}>
                  <i className="icon-accept header__lang-item--icon"></i>
                  {lang.name}
                </NavDropdown.Item>
                )}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
  </Navbar>
  );
}

export default withContexts(HeaderTopLang);
