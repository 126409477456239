import React from "react";
import PropTypes from "prop-types";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";

import { connect } from "react-redux";

import withContexts from "../../scripts/withContext.js";

import menuIndexes from "../../scripts/indexesList.js";

const HeaderMenu = ({ langContext: { dict }, menuItems, closeMenu }) => {
	return (
		<div className="header__bar-menu">
			<Navbar.Toggle aria-controls="basic-navbar-nav" className="header__bar-menu--hamburger">
				<span></span>
				<span></span>
				<span></span>
			</Navbar.Toggle>
			<Navbar.Collapse id="basic-navbar-nav">
				<div className="header__menu-title">{dict.headers.menu}</div>
				<Nav className="mr-auto" role="basic-nav-dropdown">
					<NavDropdown
						title={dict.indexes.titles}
						id="basic-nav-dropdown"
						className="header__menu-item pull-right"
						menuRole="basic-nav-dropdown"
					>
						{menuIndexes.map(item => (
							<NavDropdown.Item
								key={item.id}
								className="header__menu-list--item"
								as={NavLink}
								to={`/index/${item.name}`}
								exact
								onClick={closeMenu}
							>
								{dict.indexes[item.name]}
							</NavDropdown.Item>
						))}
					</NavDropdown>

					<NavDropdown
						title={dict.know_leopoldina.title}
						id="basic-nav-dropdown"
						className="header__menu-item"
					>
						{menuItems.length &&
							menuItems.map(item => (
								<NavDropdown.Item
									key={item.id}
									className="header__menu-list--item"
									as={NavLink}
									to={`/get-to-know-leopoldina/${item.xfn}`}
									exact
									onClick={closeMenu}
								>
									{item.title}
								</NavDropdown.Item>
							))}
					</NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</div>
	);
};

HeaderMenu.propTypes = {
	menuItems: PropTypes.array
};

const mapStateToProps = state => ({
	menuItems: state.mainPage.menuData
});

export default withContexts(connect(mapStateToProps)(HeaderMenu));
