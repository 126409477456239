let menuItemsFlattened = [];

function flattenArray(arr) {
	arr.forEach(item => {
		menuItemsFlattened.push(item);

		if (item.children && item.children.length) {
			flattenArray(item.children);
		}
	});
}

export default flattenArray;
