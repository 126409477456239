import parseQuery from '@/scripts/parseQuery.js'

const initialState = {
	params: {
		q: "",
		page: 1,
		pageSize: 30
	},
	"f.category": "",
	facets: [],
	totalPages: 1,
	suggestions: [],
	isLoadingSuggestions: false,
	parseQuery: [],
};

export default function search(state = initialState, action) {
	switch (action.type) {
		case "SET_SEARCH_PARAMS":
			const { params, facets, count, europeana, missing } = action.params;
			const category = action.params["f.category"];
			const totalPages = count ? Math.ceil(count / state.params.pageSize) : state.totalPages;

			return {
				params: {
					...state.params,
					...params
				},
				"f.category": category,
				facets: facets,
				totalPages: totalPages,
				europeana: europeana,
				missing: missing,
				parseQuery: parseQuery(action.params.params.q) 
			};

		case "RESET_SEARCH_PARAMS":
			return {
				...initialState
			};
		case "REQUEST_SEARCH_SUGGESTIONS":
			return {
				...state,
				isLoadingSuggestions: true
			};
		case "RECEIVE_SEARCH_SUGGESTIONS":
			return {
				...state,
				suggestions: action.data.suggestion.allFields,
				isLoadingSuggestions: false
			};
		case "RESET_SEARCH_SUGGESTIONS":
			return {
				...state,
				suggestions: []
			};
		default:
			return state;
	}
}
