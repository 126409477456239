import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import withContexts from '@/scripts/withContext.js';
import Button from '@/components/button/Button.js';

class CopyButton extends Component {
  copiedUrl = () => {
    ReactTooltip.show(this.copyUrlTip)
    setTimeout(
      function() {
        ReactTooltip.hide(this.copyUrlTip)
      }
      .bind(this),
      1000
    );
  };

  render() {
    const {
      langContext: {dict},
      text,
      className,
      children
    } = this.props;
    return (
      <CopyToClipboard text={text} onCopy={() => { this.copiedUrl() }}>
        <Button variant="link" 
          className={className} 
          data-tip='tooltip'>
          {<i className="icon-link"/>}
          {children}
          <span 
            ref={copyUrlTip => this.copyUrlTip = copyUrlTip} 
            data-tip={dict.copied}
            data-type="info" 
            data-class="tt"
            data-place="right"
            data-effect="float"
          />
        </Button>
      </CopyToClipboard>
    );
  }
}

CopyButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
};

export default withContexts(CopyButton);
