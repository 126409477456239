const dict = {
	pl: {
		headers: {
			share: "Podziel się opinią o Leopoldinie",
			logo_alt: "Logo portalu Leopoldina",
			uwr_logo_alt: "Logo Uniwersytetu Wrocławskiego",
      eu_logo_alt: "Logo Unii Europejskiej",
			menu: "Menu",
			contrast: "Kontrast",
			contrast_tip: "Zmień kontrast",
			font_size: "Wielkość czcionki",
			font_size_tip: "Zmień wielkość czcionki"
		},
		footers: {
			partners_desc:
				"Projekt pn. ”Leopoldina online – platforma integracji i udostępniania elektronicznych zasobów Uniwersytetu Wrocławskiego dla nauki, edukacji i popularyzacji wiedzy” współfinansowany przez Unię Europejską z Europejskiego Funduszu Rozwoju Regionalnego",
			partner_1: {
				logo: "Polska Cyfrowa Fundusze Europejskie",
				link: "https://www.polskacyfrowa.gov.pl/"
			},
			partner_2: {
				logo: "Uniwersytet Wrocławski",
				link: "https://uni.wroc.pl/"
			},
			partner_3: {
				logo: "Europejski Fundusz Rozwoju Regionalnego",
				link: "https://ec.europa.eu/regional_policy/pl/funding/erdf/"
			},
			copyright: "Copyright 2019",
			university: "Uniwersytet Wrocławski",
			university_link: "https://uni.wroc.pl/",
			policy_privacy: "Polityka Prywatności",
			policy_privacy_link: "/get-to-know-leopoldina/privacy-policy",
			accessibility_statement: "Deklaracja Dostępności",
			accessibility_statement_link: "/get-to-know-leopoldina/accessibility-statement",
			credits: "Ten serwis działa dzięki oprogramowniu opracowanemu przez ",
			pcss_long: "Poznańskie Centrum Superkomputerowo-Sieciowe",
			pcss_short: "PCSS",
			pcss_link: "http://www.man.poznan.pl/online/pl/"
		},
		resources: {
			title: "Szukaj w wybranych zasobach",
			link: "Zobacz wszystkie zasoby Leopoldiny"
		},
		sidebar: {
			info: {
				title: "Zapytanie",
				edit: "Edytuj",
				clear: "Wyczyść",
				number_of_objects_in_leopoldina: "Liczba obiektów w Leopoldinie",
				including_objects_with_geolocation: "- w tym obiektów z geolokalizacją",
				including_objects_with_geolocation_tip:
					'Kliknij w "Pokaż na mapie", aby zobaczyć mapkę przezentującą wyniki ze współrzędnymi geograficznymi.',
				show_on_map: "Pokaż na mapie",
				number_of_objects_in_europeana: "Liczba obiektów w Europeana.eu",
				go_to_europeana: "Przejdź do portalu Europeana.eu",
				copy_query_link: "Kopiuj link zapytania",
				copy_query_link_tip: "Skopiowano",
				allResources: "wszystkie zasoby Leopoldiny"
			},
			filters: {
				clear: "Wyczyść",
				selectAll: "Zaznacz wszystkie",
				apply: "Zastosuj",
				catalogFilters: "Filtry dla katalogu",
				noGroup: "Brak grupy filtrów",
				filters: "Filtry",
				catalogue: "Katalog zasobów",
				selectedCatalogue: "Wybrany katalog zasobów"
			}
		},
		fields: {
			titleAlternative: "Tytuł równoległy",
			titleOther: "Wariant tytułu",
			description: "Opis",
			dataSource: "Źródło danych",
			person: "Miano",
			personAlternative: "Miano opcjonalne",
			classification: "Klasyfikacja",
			electronicDocumentType: "Typ dokumentu elektronicznego",
			place: "Miejsce",
			time: "Czas",
			group: "Ciało zbiorowe",
			subgroup: "Nazwa oddziału",
			language: "Język",
			placeParent: "Jednostka nadrzędna dla miejsca",
			placeType: "Miejsce - typ",
			event: "Konferencja",
			eventType: "Wydarzenie - typ",
			collection: "Kolekcja",
			period: "Epoka",
			material: "Materiał",
			technique: "Technika",
			signature: "Identyfikator",
			uwrUnit: "Jednostka UWr",
			recommendedConcept: "Pojęcie zalecane",
			broadConcept: "Pojęcie szersze",
			narrowConcept: "Pojęcie węższe",
			unrecommendedConcept: "Pojęcie niezalecane",
			conceptScope: "Zakres stosowania pojęcia",
			keywords: "Słowa kluczowe",
			externalUrl: "Zewnętrzny adres URL",
			institutions: "Instytucje",
			places: "Miejsca",
			"group-publications": "Publikacja grupowa",
			collections: "Kolekcje",
			objects: "Obiekty",
			people: "Osoby",
			events: "Wydarzenia",
			concepts: "Pojęcia",
			contentType: "Typ zawartości",
			doi: "DOI",
			source: "Źródło",
			licence: "Prawa",
			accessRights: "Prawa",
			rights: "Prawa",
			objectType: "Typ obiektu",
			shareUrl: "Link do zasobu"
		},
		search: {
			title: "Otwarty dostęp do zasobów naukowych Uniwersytetu Wrocławskiego",
			image_alt: "Logo Uniwersytetu Wrocławskiego",
			image_decoration_alt1: "Element decoracyjny 1",
			image_decoration_alt2: "Element decoracyjny 2",
			placeholder: "Wpisz, czego szukasz",
			search_in_europeana: "Szukaj także w Europeana.eu",
			search_in_europeana_tip:
				"Zaznaczając opcję „Szukaj także w Europeana.eu” system wyszuka zapytanie nie tylko w zasobach Leopodiny, ale także w zasobach Europeany. Wyszukane obiekty, nie pokażą się na liście wspólnej wraz z obiektami Leopoldiny. System wyszuka zapytanie w zasobach Europeany i wyświetli liczbę dostępnych w niej obiektów wraz z linkiem do zapytania po lewej stronie wyników wyszukiwania tuż nad filtrami.",
			advanced_search: "Wyszukiwanie zaawansowane"
		},
		results: {
			no_results: "Brak wyników",
			search_results: "Wyniki wyszukiwania",
			titleAlternative: "Tytuł równoległy",
			titleOther: "Wariant tytułu",
			description: "Opis",
			dataSource: "Źródło danych",
			person: "Miano",
			personAlternative: "Miano opcjonalne",
			classification: "Klasyfikacja",
			electronicDocumentType: "Typ dokumentu elektronicznego",
			place: "Miejsce",
			time: "Czas",
			group: "Ciało zbiorowe",
			groupInfo:
				"Ciałem zbiorowym nazywa się organizację lub grupę osób i/lub organizacji występującą pod własną nazwą jednoznacznie ją identyfikującą. Ciałem zbiorowym określa się też posiadające wyróżniającą nazwę okolicznościowe grupy lub imprezy, jak np. spotkania, konferencje, kongresy, zjazdy, ekspedycje, festiwale, wystawy, targi itp.",
			subgroup: "Nazwa oddziału",
			language: "Język",
			placeParent: "Jednostka nadrzędna dla miejsca",
			placeType: "Miejsce - typ",
			event: "Wydarzenie - nazwa",
			eventType: "Wydarzenie - typ",
			collection: "Kolekcja",
			period: "Epoka",
			material: "Materiał",
			technique: "Technika",
			signature: "Sygnatura źródła",
			uwrUnit: "Jednostka UWr",
			recommendedConcept: "Pojęcie zalecane",
			licence: "Licencja",
			broadConcept: "Pojęcie szersze",
			narrowConcept: "Pojęcie węższe",
			unrecommendedConcept: "Pojęcie niezalecane",
			conceptScope: "Zakres stosowania pojęcia",
			keywords: "Słowa kluczowe",
			externalUrl: "Zewnętrzny adres URL",
			copy: "Kopiuj link obiektu",
			share_object: "Udostępnij obiekt",
			with_geo: "Obiekt z geolokalizacją",
			plan_digit_repr: "Obiekt planowany",
			with_digit_repr: "Obiekt z reprezentacją cyfrową",
			no_digit_repr: "Obiekt bez reprezentacji cyfrowej",
			limit_access: "Obiekt z dostępem ograniczonym",
			in: "W:",
			ed: "(red.)"
		},
		roles: {
			publisher: "Wydawca",
			creator: "Twórca",
			contributor: "Współtwórca",
			editor: "Edytor",
			midas4100: "Nazwisko osoby",
			midas4600: "Nazwa instytucji",
			midas4620: "Oddział instytucji"
		},
		facets: {
			form: {
				dateFrom: "Data od",
				dateTo: "Data do",
				dateRange: "lub wskaż zakres dat",
				from: "Od",
				to: "Do",
				datePlaceholder: "rrrr.mm.dd",
				timeline: "Pokaż tylko obiekty bez daty",
				query: "Wpisz, czego szukasz",
				remove: "Usuń",
				add: "Dodaj kolejne kryterium zapytania",
				removeAll: "Wyczyść wszystkie kryteria zapytania",
				search: "Szukaj",
				era: {
					ad: "n.e.",
					bc: "p.n.e."
				},
				errors: {
					required: "Pole wymagane.",
					to_exceeds_from: "Data od jest późniejsza niż data do.",
					out_of_range: "Nieprawidłowa data.",
					format: "Nieprawidłowy format daty.",
					no_fields: "Przynajmniej jedno pole musi być wypełnione."
				}
			},
			search: {
				placeholder: "Wpisz pierwszą literę lub szukaną frazę",
				submit: "Szukaj filtra"
			},
			no_facets: "Brak filtrów",
			labels: {
				with_geo: "Obiekt z geolokalizacją",
				plan_digit_repr: "Obiekt planowany",
				with_digit_repr: "Obiekt z reprezentacją cyfrową",
				no_digit_repr: "Obiekt bez reprezentacji cyfrowej",
				limit_access: "Obiekt z dostępem ograniczonym"
			}
		},
		pagination: {
			from: "z",
			prev: "Poprzednia",
			next: "Następna",
			nextTitle: "Wybierz następną stronę.",
			prevTitle: "Wybierz poprzednią stronę.",
			input: "Wpisz numer strony",
			submit: "Wybierz stronę",
			lastPage: "Ostatnia strona",
			pageNumber: "Numer strony",
			currentPage: "Aktualna strona"
		},
		pageSize: {
			size: "Ilość elementów na stronie.",
			onPage: "Wyników na stronie"
		},
		indexes: {
			titles: "Indeksy",
			literals: "Wszystkie litery",
			classification: "Klasyfikacje",
			electronicDocumentType: "Typy dokumentów elektronicznych",
			title: "Tytuły obiektów",
			person: "Osoby zaangażowane",
			group: "Ciała zbiorowe związane",
			place: "Miejsca związane",
			event: "Wydarzenia",
			collection: "Kolekcje",
			language: "Języki",
			period: "Epoki/ style/ kultury",
			material: "Materiały",
			technique: "Techniki wykonania",
			signature: "Sygnatury źródeł",
			dataSource: "Źródła danych",
			uwrUnit: "Jednostki Uniwersytetu Wrocławskiego",
			keywords: "Słowa kluczowe",
			choosen_index: "Wybrany indeks",
			number_of_values: "Liczba wartości"
		},
		know_leopoldina: {
			title: "Poznaj Leopoldinę",
			"about-project": "O Projekcie",
			"data-sources": "Źródła danych",
			"digital-services": "Narzędzia badawcze",
			"university-units": "Jednostki uniwersyteckie",
			partners: "Partnerzy",
			"privacy-policy": "Polityka prywatności",
			"how-to-search-in-the-portal": "Jak szukać w serwisie",
			licenses: "Licencje",
			contact: "Kontakt",
			menu_title: "Sekcja tematyczna 1",
			"accessibility-statement": "Deklaracja dostępności",
			table: {
				resources: "Zobacz zasoby w Leopoldinie"
			}
		},
		advanced_search: {
			title: "Wyszukiwanie zaawansowane",
			text: {
				title: "Więcej opcji wyszukiwania",
				content:
					"Skorzystaj z domyślnej listy kryteriów lub dopasuj ją według swojego uznania. Możesz dowolnie zmieniać, dodawać lub usuwać kryteria. <br /> Jeśli potrzebujesz pomocy, przeczytaj",
				howToSearch: {
					text: "Jak efektywnie szukać w serwisie Leopoldina?",
					link: "/get-to-know-leopoldina/how-to-search-in-the-portal"
				}
			},
			form: {
				type: "Typ pola",
				conj: "Spójnik",
				types: {
					allFields: "Wszystkie pola opisu obiektu",
					title: "Tytuł / nazwa",
					personInvolved: "Osoba zaangażowana",
					classification: "Klasyfikacja",
					time: "Czas",
					docType: "Typ dokumentu elektronicznego",
					colBody: "Ciało zbiorowe związane",
					person: "Osoba zaangażowana",
					electronicDocumentType: "Typ dokumentu elektronicznego",
					group: "Ciało zbiorowe związane",
					language: "Język",
					place: "Miejsce związane",
					event: "Wydarzenie związane",
					collection: "Kolekcja",
					epoch: "Epoka/styl/kultura",
					stuff: "Materiał",
					number: "Sygnatura źródła",
					data: "Źródło danych",
					UW: "Jednostka UWr",
					period: "Epoka/styl/kultura",
					material: "Materiał",
					technique: "Technika wykonania",
					signature: "Sygnatura źródła",
					dataSource: "Źródło danych",
					uwrUnit: "Jednostka UWr",
					iconclass: "Iconclass",
					keywords: "Słowa kluczowe"
				},
				conjs: {
					and: "i",
					or: "lub"
				},
				era: {
					ad: "n.e.",
					bc: "p.n.e."
				},
				errors: {
					required: "Pole wymagane.",
					to_exceeds_from: "Data od jest późniejsza niż data do.",
					out_of_range: "Nieprawidłowa data.",
					format: "Nieprawidłowy format daty.",
					no_fields: "Przynajmniej jedno pole musi być wypełnione."
				},
				dateFrom: "Data od",
				dateTo: "Data do",
				from: "Od",
				to: "Do",
				datePlaceholder: "rrrr.mm.dd",
				timeline: "Tylko obiekty bez oznaczenia czasu",
				query: "Wpisz, czego szukasz",
				remove: "Usuń",
				add: "Dodaj kolejne kryterium zapytania",
				removeAll: "Wyczyść wszystkie kryteria zapytania",
				search: "Szukaj"
			}
		},
		cookies: {
			accept: "Rozumiem",
			desc: "Używamy plików cookies, by ułatwić korzystanie z naszego serwisu. Sprawdź informacje o plikach ",
			link1: "cookies",
			link1_path: "/get-to-know-leopoldina/privacy-policy",
			and: " i ",
			link2: "polityce prywatności",
			link2_path: "/get-to-know-leopoldina/privacy-policy"
		},
		map: {
			title: "Wyniki wyszukiwania na mapie",
			close: "Zamknij",
			objectWithGeo: "Obiekty w wynikach wyszukiwania z przypisanymi współrzędnymi geograficznymi",
			numberOfObjectsInLeopoldina: "Liczba obiektów w Leopoldinie",
			includingObjectsWithGeolocation: "w tym obiektów z geolokalizacją",
			legend: {
				title: "Legenda",
				group: "lokalizacja grup obiektów",
				object: "dokładna lokalizacja obiektu (obiekty dostępne w serwisie źródłowym)"
			},
			copyrights: '&copy <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> | &copy LEOPOLDINA',
			buttons: {
				showObjectOnTheSourceSite: "Pokaż obiekt w serwisie źródłowym",
				showObjectOnTheGAIKKSite: "Pokaż obiekt w serwisie GAIKK"
			},
			loading: "Trwa wczytywanie danych..."
		},
		error: {
			header: "Niestety strona, którą chcesz odwiedzić, nie istnieje.",
			code: "Kod błędu: 404",
			causes: "Możliwe przyczyny:",
			cause: {
				first: "błąd w adresie - sprawdź jego poprawność,",
				second: "strona nie istnieje (nie istaniała lub została usunięta),",
				third: "strona zmieniła nazwę lub lokalizację"
			},
			apologize: "Przepraszamy za niedogodności.",
			back: "Przejdź do strony głównej"
		},
		expand: "Rozwiń",
		collapse: "Zwiń",
		copied: "Skopiowano"
	},
	en: {
		headers: {
			share: "Share your opinion about Leopoldina portal",
			logo_alt: "Leopoldina logo",
			uwr_logo_alt: "University of Wrocław logo",
      eu_logo_alt: "European Union logo",
			menu: "Menu",
			contrast: "Contrast",
			contrast_tip: "Change contrast",
			font_size: "Font size",
			font_size_tip: "Change font size"
		},
		footers: {
			partners_desc:
				'Project "Leopoldina online - a platform for integration and sharing of electronic resources of the University of Wrocław for science, education and knowledge dissemination" co-financed by the European Union from the European Regional Development Fund',
			partner_1: {
				logo: "Digital Poland European Funds",
				link: "https://www.polskacyfrowa.gov.pl/"
			},
			partner_2: {
				logo: "University of Wroclaw",
				link: "https://uni.wroc.pl/en/"
			},
			partner_3: {
				logo: "European Regional Development Fund",
				link: "https://ec.europa.eu/regional_policy/en/funding/erdf/"
			},
			copyright: "Copyright 2019",
			university: "University of Wroclaw",
			university_link: "https://uni.wroc.pl/en/",
			policy_privacy: "Privacy Policy",
			policy_privacy_link: "/get-to-know-leopoldina/privacy-policy",
			accessibility_statement: "Accessibility Statement",
			accessibility_statement_link: "/get-to-know-leopoldina/accessibility-statement",
			credits: "This site works thanks to software developed by ",
			pcss_long: "Poznań Supercomputing and Networking Center",
			pcss_short: "PSNC",
			pcss_link: "http://www.man.poznan.pl/online/en/"
		},
		resources: {
			title: "Search in selected resources",
			link: "See all Leopoldina resources"
		},
		sidebar: {
			info: {
				title: "Search query",
				edit: "Edit",
				clear: "Clear",
				number_of_objects_in_leopoldina: "Number of objects in Leopoldina",
				including_objects_with_geolocation: "- including objects with geolocation",
				including_objects_with_geolocation_tip: "Tip",
				show_on_map: "Show on map",
				number_of_objects_in_europeana: "Number of objects in Europeana.eu",
				go_to_europeana: "Go to Europeana.eu",
				copy_query_link: "Copy the query link",
				copy_query_link_tip: "Copied",
				allResources: "all Leopoldina resources"
			},
			filters: {
				clear: "Clear",
				selectAll: "Select all",
				apply: "Apply",
				catalogFilters: "Catalog filters",
				noGroup: "Blank filters group",
				filters: "Filters",
				catalogue: "Resource catalog",
				selectedCatalogue: "Selected resource catalog"
			}
		},
		fields: {
			titleAlternative: "Title alternative",
			titleOther: "Title other",
			description: "Description",
			dataSource: "Data source",
			person: "Person",
			personAlternative: "Person alternative",
			classification: "Classification",
			electronicDocumentType: "Electronic document type",
			place: "Place",
			time: "Time",
			group: "Group",
			subgroup: "Subgroup",
			language: "Language",
			placeParent: "Jednostka nadrzędna dla miejsca",
			placeType: "Place - type",
			event: "Conference",
			eventType: "Event - type",
			collection: "Collection",
			period: "period",
			material: "Material",
			technique: "Technique",
			signature: "Identifier",
			uwrUnit: "UWr unit",
			recommendedConcept: "Recommended concept",
			broadConcept: "Broad concept",
			narrowConcept: "Narrow concept",
			unrecommendedConcept: "Unrecommended concept",
			conceptScope: "Concept scope",
			keywords: "Keywords",
			externalUrl: "External URL",
			institutions: "Institutions",
			places: "Places",
			"group-publications": "Group publications",
			collections: "Collections",
			objects: "Objects",
			people: "People",
			events: "Events",
			concepts: "Concepts",
			contentType: "Content type",
			doi: "DOI",
			source: "Source",
			licence: "Rights",
			accessRights: "Rights",
			rights: "Rights",
			objectType: "Object type",
			shareUrl: "Resource link"
		},
		facets: {
			form: {
				dateFrom: "Date from",
				dateTo: "Data to",
				from: "From",
				to: "To",
				datePlaceholder: "yyyy.mm.dd",
				timeline: "Only objects without time stamp",
				query: "Enter what you're looking for",
				remove: "Delete",
				add: "Add another query criterion",
				removeAll: "Clear all query criteria",
				search: "Search",
				dateRange: "or write date range",
				era: {
					ad: "a.d.",
					bc: "b.c."
				},
				errors: {
					required: "Required field.",
					to_exceeds_from: "Date from is later than date to.",
					out_of_range: "Wrong date.",
					format: "Wrong date format.",
					no_fields: "At least one field must be completed."
				}
			},
			search: {
				placeholder: "Enter the first letter or phrase you are looking for",
				submit: "Search for facet"
			},
			no_facets: "No facets",
			labels: {
				with_geo: "With geolocalization",
				plan_digit_repr: "Object with planned digit reprezantation",
				with_digit_repr: "Object with digit reprezantation",
				no_digit_repr: "No digit reprezantation",
				limit_access: "Object with limited access"
			}
		},
		search: {
			title: "Open access to scientific resources of the University of Wrocław",
			image_alt: "University of Wroclaw logo",
			image_decoration_alt1: "Decoration element 1",
			image_decoration_alt2: "Decoration element 2",
			placeholder: "Type what you are looking for",
			search_in_europeana: "Also search on Europeana.eu",
			search_in_europeana_tip:
				"By selecting the 'Search also in Europeana.eu' option, the system will search the query not only in Leopodina's resources, but also in Europeana's resources. The objects found will not show up on the shared list with the objects of Leopoldina. The system will search for a query in Europeana's resources and display the number of objects available in it along with a link to the query to the left of the search results just above the filters",
			advanced_search: "Advanced search"
		},
		results: {
			no_results: "No results",
			search_results: "Search results",
			titleAlternative: "Title alternative",
			titleOther: "Title other",
			description: "Description",
			dataSource: "Data source",
			person: "Person",
			personAlternative: "Person alternative",
			classification: "Classification",
			electronicDocumentType: "Electronic document type",
			place: "Place",
			time: "Time",
			group: "Group",
			groupInfo:
				"A collective body is an organization or group of people and / or organizations appearing under its own name that uniquely identifies it. A collective body is also defined by occasional groups or events having a distinctive name, e.g. meetings, conferences, congresses, conventions, expeditions, festivals, exhibitions, fairs, etc.",
			subgroup: "Subgroup",
			language: "Language",
			placeParent: "Jednostka nadrzędna dla miejsca",
			placeType: "Place - type",
			event: "Event - name",
			eventType: "Event - type",
			collection: "Collection",
			period: "period",
			material: "Material",
			technique: "Technique",
			signature: "Signature",
			uwrUnit: "UWr unit",
			recommendedConcept: "Recommended concept",
			licence: "Licence",
			broadConcept: "Broad concept",
			narrowConcept: "Narrow concept",
			unrecommendedConcept: "Unrecommended concept",
			conceptScope: "Concept scope",
			keywords: "Keywords",
			externalUrl: "External URL",
			copy: "Copy object link",
			share_object: "Share object",
			with_geo: "Object with geolocation",
			plan_digit_repr: "Planned object",
			with_digit_repr: "Object with digital representation",
			no_digit_repr: "Object without digital representation",
			limit_access: "Object with limitted access",
			in: "In:",
			ed: "(ed.)"
		},
		roles: {
			publisher: "Publisher",
			creator: "Creator",
			contributor: "Contributor",
			editor: "Editor",
			midas4100: "Person name",
			midas4600: "Institution name",
			midas4620: "Institution branch"
		},
		pagination: {
			from: "from",
			prev: "Previous",
			next: "Next",
			nextTitle: "Choose next page.",
			prevTitle: "Choose previous page.",
			input: "Type number of the page",
			submit: "Choose page",
			lastPage: "Last page",
			pageNumber: "Number of the page",
			currentPage: "Current page"
		},
		pageSize: {
			size: "Number of results on page.",
			onPage: "Results on page"
		},
		indexes: {
			titles: "Indexes",
			literals: "All letters",
			classification: "Classifications",
			electronicDocumentType: "Electronic document type",
			title: "Titles",
			person: "Person",
			group: "Group",
			place: "Places",
			event: "Events",
			collection: "Collections",
			language: "Languages",
			period: "Epochs/ styles/ cultures",
			material: "Material",
			technique: "Technique",
			signature: "Signatures",
			dataSource: "Data sources",
			uwrUnit: "Units of the University of Wrocław",
			keywords: "Keywords",
			choosen_index: "Chosen index",
			number_of_values: "Number of values"
		},
		know_leopoldina: {
			title: "Learn about Leopoldina",
			"about-project": "About project",
			"data-sources": "Data sources",
			"digital-services": "Research tools",
			"university-units": "University units",
			partners: "Partners",
			"privacy-policy": "Privacy policy",
			"how-to-search-in-the-portal": "How to search in the portal",
			licenses: "Licenses",
			contact: "Contact",
			menu_title: "Thematic section 1",
			"accessibility-statement": "Accessibility statement",
			table: {
				resources: "See resources in Leopoldina"
			}
		},
		advanced_search: {
			title: "Advanced search",
			text: {
				title: "More search options",
				content:
					"Use the default criteria list or adjust it as you like. You can freely change, add or remove criteria. If you need help read",

				howToSearch: {
					text: "How to search effectively on Leopoldina?",
					link: "/get-to-know-leopoldina/how-to-search-in-the-portal"
				}
			},
			form: {
				type: "Field type",
				conj: "Conjuction",
				types: {
					allFields: "All object description fields",
					title: "Title / name",
					personInvolved: "Person involved",
					classification: "Classification",
					time: "Time",
					docType: "Document type",
					colBody: "Collective body",
					person: "Person involved",
					electronicDocumentType: "Document type",
					group: "Collective body",
					language: "Language",
					place: "Connected place",
					event: "Connected event",
					collection: "Collection",
					epoch: "Epoch/style/culture",
					stuff: "Stuff",
					technique: "Technique",
					number: "Number",
					data: "Date",
					UW: "UWr unit",
					period: "Epoch/style/culture",
					material: "Material",
					signature: "Signature",
					dataSource: "Data source",
					uwrUnit: "UWr unit",
					iconclass: "Iconclass",
					keywords: "Keywords"
				},
				conjs: {
					and: "and",
					or: "or"
				},
				era: {
					ad: "ad",
					bc: "bc"
				},
				errors: {
					required: "Required field.",
					to_exceeds_from: "Date from is later than date to.",
					out_of_range: "Wrong date.",
					format: "Wrong date format.",
					no_fields: "At least one field must be completed."
				},
				dateFrom: "Date from",
				dateTo: "Data to",
				from: "From",
				to: "To",
				datePlaceholder: "yyyy.mm.dd",
				timeline: "Only objects without time stamp",
				query: "Enter what you're looking for",
				remove: "Delete",
				add: "Add another query criterion",
				removeAll: "Clear all query criteria",
				search: "Search"
			}
		},
		cookies: {
			accept: "I agree",
			desc: "We use cookies to facilitate the use of our website. Check file information ",
			link1: "cookies",
			link1_path: "/get-to-know-leopoldina/privacy-policy",
			and: " and ",
			link2: "privacy policy",
			link2_path: "/get-to-know-leopoldina/privacy-policy"
		},
		map: {
			title: "Search results on map",
			close: "Close",
			objectWithGeo: "Objects in search results with geographic coordinates assigned",
			numberOfObjectsInLeopoldina: "Number of objects in Leopoldinia",
			includingObjectsWithGeolocation: "including objects with geolocation",
			legend: {
				title: "Legend",
				group: "location of object groups",
				object: "the exact location of the object (objects available on the source site)"
			},
			copyrights: '&copy <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> | &copy LEOPOLDINA',
			buttons: {
				showObjectOnTheSourceSite: "Show object on the source site",
				showObjectOnTheGAIKKSite: "Show object on the GAIKK site"
			},
			loading: "Loading data..."
		},
		error: {
			header: "Unfortunately, the page you want to visit does not exist.",
			code: "Error code: 404",
			causes: "Possible causes:",
			cause: {
				first: "error in address - check its correctness,",
				second: "page does not exist (did not exist or was deleted),",
				third: "page has changed name or location"
			},
			apologize: "Sorry for the inconvenience.",
			back: "Go to the home page"
		},
		expand: "Expand",
		collapse: "Collapse",
		copied: "Copied"
	}
};

export default dict;
