import React, { Component } from "react";

import withContexts from "@/scripts/withContext.js";

import PaginationPrevious from "@/components/pagination/PaginationPrevious.js";
import PaginationNext from "@/components/pagination/PaginationNext.js";
import PaginationTotal from "@/components/pagination/PaginationTotal.js";

import styles from "@/components/pagination/Pagination.module.sass";

class Pagination extends Component {
	state = {
		tmpPage: 1
	};

	componentDidMount() {
		const {
			searchParams: { page }
		} = this.props;

		this.setState({ tmpPage: page });
	}

	componentDidUpdate(prevProps) {
		const {
			searchParams: { page }
		} = this.props;

		if (page !== prevProps.searchParams.page) {
			this.setState({ tmpPage: page });
		}
	}

	setCurrentPage = val => {
		this.setState({ tmpPage: val });
	};

	setPreviousPage = () => {
		const { setPageByNum } = this.props;

		this.setState(
			state => ({
				tmpPage: state.tmpPage > 1 ? state.tmpPage - 1 : state.tmpPage
			}),
			() => setPageByNum(this.state.tmpPage)
		);
	};

	setNextPage = () => {
		const { setPageByNum, totalPages } = this.props;

		this.setState(
			state => ({
				tmpPage: state.tmpPage < totalPages ? state.tmpPage + 1 : state.tmpPage
			}),
			() => setPageByNum(this.state.tmpPage)
		);
	};

	handleSubmit = e => {
		const { setPageByNum } = this.props;
		const { tmpPage } = this.state;

		e.preventDefault();

		if (tmpPage) {
			setPageByNum(tmpPage);
		}
	};

	render() {
		const { tmpPage } = this.state;
		const {
			setPageByNum,
			searchParams: { page },
			totalPages,
			langContext: { dict }
		} = this.props;

		const isPrev = page > 1 && page <= totalPages && totalPages !== 1;
		const isNext = page >= 1 && page < totalPages;

		return (
			<div className={styles["pagination"]}>
				{isPrev && <PaginationPrevious setPreviousPage={this.setPreviousPage} />}
				<div className={styles["pagination__pages"]}>
					<form
						className={styles["pagination__form"]}
						onSubmit={e => this.handleSubmit(e)}
						id="paginationForm"
					>
						<label htmlFor="paginationInput" className={styles["pagination__input-label"]}>
							{dict.pagination.input}
						</label>
						{totalPages === 1 && (
							<button
								className={`${styles["pagination__item-button--active"]} 
                                    ${styles["pagination__item-button"]}`}
								disabled
								title={dict.pagination.currentPage}
							>
								{tmpPage}
							</button>
						)}
						{totalPages !== 1 && (
							<>
								<input
									className={styles["pagination__input"]}
									pattern="[0-9]*"
									id="paginationInput"
									value={tmpPage}
									onChange={event => {
										const val = event.target.value;
										if (event.target.validity.valid && val <= totalPages && (val > 0 || !val)) {
											this.setCurrentPage(val);
										}
									}}
									onBlur={event => {
										const val = event.target.value;
										if (val) {
											setPageByNum(val);
										} else {
											this.setCurrentPage(page);
										}
									}}
								/>
								<button type="submit" className={styles["pagination__button"]}>
									{dict.pagination.submit}
								</button>
							</>
						)}
					</form>
					<PaginationTotal
						totalPages={totalPages}
						currentPage={page}
						setPageByNum={val => this.setState({ tmpPage: val }, () => setPageByNum(val))}
					/>
				</div>
				{isNext && <PaginationNext setNextPage={this.setNextPage} />}
			</div>
		);
	}
}

export default withContexts(Pagination);
