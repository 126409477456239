import React from 'react';
import withContexts from '../../scripts/withContext.js';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ImageLink from '../../components/imageLink/ImageLink.js';

import partnerLogo1 from '../../images/partner_polska_cyfrowa.svg'
import partnerLogo2 from '../../images/partner_uwr.svg'
import partnerLogo3 from '../../images/partner_unia_europejska.svg'

import partnerLogo1White from '../../images/partner_polska_cyfrowa_white.png'
import partnerLogo2White from '../../images/partner_uwr_white.svg'
import partnerLogo3White from '../../images/partner_unia_europejska_white.svg'

const FooterPartners = ({langContext: {dict}}) => (
  <div className="footer__partners">
    <Container>
      <Row>
        <div className="footer__partners-text">
          {dict.footers.partners_desc}
        </div>
        <div className="footer__partners-logos">
          <ImageLink href={dict.footers.partner_1.link} target="_blank" src={partnerLogo1} alt={dict.footers.partner_1.logo} className="footer__partners-logos--regular"/>
          <ImageLink href={dict.footers.partner_2.link} target="_blank" src={partnerLogo2} alt={dict.footers.partner_2.logo} className="footer__partners-logos--regular"/>
          <ImageLink href={dict.footers.partner_3.link} target="_blank" src={partnerLogo3} alt={dict.footers.partner_3.logo} className="footer__partners-logos--regular"/>
        </div>
        <div className="footer__partners-logos--contrast">
          <ImageLink href={dict.footers.partner_1.link} target="_blank" src={partnerLogo1White} alt={dict.footers.partner_1.logo} className="footer__partners-logos--white"/>
          <ImageLink href={dict.footers.partner_2.link} target="_blank" src={partnerLogo2White} alt={dict.footers.partner_2.logo} className="footer__partners-logos--white"/>
          <ImageLink href={dict.footers.partner_3.link} target="_blank" src={partnerLogo3White} alt={dict.footers.partner_3.logo} className="footer__partners-logos--white"/>
        </div>
      </Row>
    </Container>
  </div>
);

FooterPartners.propTypes = {};

export default withContexts(FooterPartners);
