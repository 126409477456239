import React from "react";

import Checkbox from "react-simple-checkbox";
import Form from "react-bootstrap/Form";

import withContexts from "@/scripts/withContext.js";
import styles from "./FacetForm.module.sass";

const FacetFormItem = ({ input, label, count }) => {
	return (
		<li className={styles["form__list-item"]}>
			<Form.Label
				className={styles["form__list-item--label"]}
			>
				<Checkbox
					{...input}
					id={label}
					color={{ backgroundColor: "#fff" }}
					checked={input.value}
					onChange={input.onChange}
					className="checkbox--light"
					label={label}
					size="2"
				/>
				<span className={styles["form__text--value"]}>{label}</span>
				<span className={styles["form__text--count"]}>{count}</span>
			</Form.Label>
		</li>
	);
};

export default withContexts(FacetFormItem);
