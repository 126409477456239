import React from "react";
import { Field } from "redux-form";
import Checkbox from "react-simple-checkbox";
import Form from "react-bootstrap/Form";

import withContexts from "@/scripts/withContext.js";
import AdvancedSearchFormQuerySelect from "./AdvancedSearchFormQuerySelect.js";
import AutoInput from "@/components/autoInput/AutoInput.js";

import styles from "./AdvancedSearch.module.sass";

const renderDate = ({ input, label, placeholder, dict, meta: { touched, error, submitFailed } }) => (
	<div className={styles["advanced-search__field-date"]}>
		<Form.Label srOnly>{label}</Form.Label>
		<input
			{...input}
			placeholder={placeholder}
			pattern="[0-9.]*$"
			onChange={e => {
				if (e.target.validity.valid) {
					input.onChange(e);
				}
			}}
			className={styles["advanced-search__field-date--form-control"]}
		/>
		{touched && error && submitFailed && (
			<div className={styles["advanced-search__field-error"]}>{dict.advanced_search.form.errors[error]}</div>
		)}
	</div>
);

const renderCheckbox = ({ input, label, meta: { touched, error, submitFailed }, dict }) => (
	<div className={styles["advanced-search__checkbox"]}>
		<Checkbox
			{...input}
			id={label}
			color={{ backgroundColor: "#fff" }}
			checked={input.value}
			onChange={input.onChange}
			className="checkbox--light"
			label={label}
			size="2"
		/>
		<Form.Label htmlFor={input.name}>{label}</Form.Label>
		{touched && error && submitFailed && (
			<div className={styles["advanced-search__field-error"]}>{dict.advanced_search.form.errors[error]}</div>
		)}
	</div>
);

const AdvancedSearchFormQueryField = ({
	member,
	type,
	era,
	getAutocompleteList,
	resetAdvancedSearchSuggestions,
	suggestions,
	langContext: { dict }
}) => {
	if (type === "time")
		return (
			<div className={styles["advanced-search__date"]}>
				<div className={styles["advanced-search__date--from"]}>
					{dict.advanced_search.form.from}:
					<Field
						name={`${member}.from`}
						dict={dict}
						label={dict.advanced_search.form.dateFrom}
						placeholder={dict.advanced_search.form.datePlaceholder}
						component={renderDate}
					/>
					<Field
						name={`${member}.fromEra`}
						component={AdvancedSearchFormQuerySelect}
						label={dict.advanced_search.form.type}
						options={era}
						dict={dict.advanced_search.form.era}
					/>
				</div>
				<div className={styles["advanced-search__date--to"]}>
					{dict.advanced_search.form.to}:
					<Field
						name={`${member}.to`}
						type="text"
						dict={dict}
						label={dict.advanced_search.form.dateTo}
						placeholder={dict.advanced_search.form.datePlaceholder}
						component={renderDate}
					/>
					<Field
						name={`${member}.toEra`}
						component={AdvancedSearchFormQuerySelect}
						label={dict.advanced_search.form.type}
						options={era}
						dict={dict.advanced_search.form.era}
					/>
				</div>
				<div className={styles["advanced-search__date--timeline"]}>
					<Field
						name={`${member}.timeline`}
						component={renderCheckbox}
						label={dict.advanced_search.form.timeline}
					/>
				</div>
			</div>
		);

	return (
		<Field
			name={`${member}.query`}
			type="text"
			component={AutoInput}
			label={dict.advanced_search.form.query}
			variant="small"
			placeholder={dict.advanced_search.form.query}
			customClass={styles["advanced-search__field-text"]}
			customError={dict.advanced_search.form.errors}
			getAutocompleteList={getAutocompleteList}
			resetSuggestionsList={resetAdvancedSearchSuggestions}
			suggestions={suggestions}
		/>
	);
};

export default withContexts(AdvancedSearchFormQueryField);
