import React from "react";

import Info from "@/containers/Results/ResultsSidebar/info/Info.js";
import Categories from "@/containers/Results/ResultsSidebar/categories/Categories.js";
import Facets from "@/containers/Results/ResultsSidebar/facets/Facets.js";

import styles from "@/containers/Results/ResultsSidebar/Sidebar.module.sass";

const Sidebar = ({
	searchParams,
	searchFacets,
	searchCategory,
	category,
	facets,
	count,
	links,
	setFacet,
	setMissing,
	setCategory,
	resetCategory,
	resetFacet,
	resetAllFacets,
	isFetching,
	resetQuery
}) => (
	<div>
		<Info searchParams={searchParams} count={count} links={links} resetQuery={resetQuery} isFetching={isFetching} />
		<div className={styles["sidebar"]}>
			{category && category.value && (
				<Categories
					searchCategory={searchCategory}
					category={category}
					setCategory={setCategory}
					resetCategory={resetCategory}
				/>
			)}
			{!!facets.length && (
				<Facets
					searchCategory={searchCategory}
					searchFacets={searchFacets}
					facets={facets}
					resetAllFacets={resetAllFacets}
					setFacet={setFacet}
					setMissing={setMissing}
					resetFacet={resetFacet}
				/>
			)}
		</div>
	</div>
);

export default Sidebar;
