import queryString from "query-string";

const getGeoRectangle = (map) => {
    const y = map.getSize().y,
          x = map.getSize().x,
          center = map.getCenter();

    const ne = map.containerPointToLatLng([x, 0]);
    const sw = map.containerPointToLatLng([0, y]);

    return {ne, sw, center};
}

export const setPointsUrl = (map, searchLocation) => {
    const { sw, ne } = getGeoRectangle(map)

    const queryObj = queryString.parse(searchLocation);
    delete queryObj['page'];
    delete queryObj['pageSize'];

    queryObj['neLat'] = ne.lat;
    queryObj['neLng'] = ne.lng;
    queryObj['swLat'] = sw.lat;
    queryObj['swLng'] = sw.lng;

    return queryString.stringify(queryObj);
};

export const setLocationsUrl = (map, coveredArea, searchLocation, page = 0, pageSize = 100) => {
    const queryObj = queryString.parse(searchLocation);
    delete queryObj['page'];
    delete queryObj['pageSize'];

    queryObj['neLat'] = coveredArea.northEast.lat;
    queryObj['neLng'] = coveredArea.northEast.lng;
    queryObj['swLat'] = coveredArea.southWest.lat;
    queryObj['swLng'] = coveredArea.southWest.lng;

    queryObj['page'] = page;
    queryObj['pageSize'] = pageSize;

    return queryString.stringify(queryObj);
};
