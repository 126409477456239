import React from "react";
import { Field, reduxForm } from "redux-form";

import withContexts from "@/scripts/withContext.js";

import CategoriesFormField from "./CategoriesFormField";

import styles from "./Categories.module.sass";

const CategoriesForm = ({ langContext: { dict }, handleSubmit, category }) => (
	<form onSubmit={handleSubmit}>
		<fieldset>
			<legend className="d-none">{dict.sidebar.filters.filters}</legend>
			<ul className={styles["categories__catalogue-list"]}>
				{category.value.map(facet => (
					<li key={facet.label} className={styles["categories__catalogue-item"]}>
						<Field
							name="category"
							component={CategoriesFormField}
							value={facet.label}
							type="radio"
							count={facet.value}
							label={dict.fields[facet.label]}
						/>
					</li>
				))}
			</ul>
		</fieldset>
	</form>
);

export default withContexts(
	reduxForm({
		form: "categories",
		enableReinitialize: true,

		onChange: (values, action, props) => {
			if (values.category !== props.initialValues.category) {
				props.setCategory(values);
			}
		}
	})(CategoriesForm)
);
