import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Marker, Tooltip, Popup } from 'react-leaflet'
import L from 'leaflet';

import * as Actions from '@/actions';
import withContexts from "@/scripts/withContext.js";
import { setLocationsUrl } from "@/scripts/geoMapService.js";
import Button from "@/components/button/Button.js";

import styles from "@/components/geomap/GeoMap.module.sass";

import imgMarkerObject from '@/assets/images/map/markerObject.svg';
import imgMarkerCluster from '@/assets/images/map/markerCluster.svg';
import imgLoading from '@/assets/images/map/loading.gif';

const markerObject = new L.Icon({
    iconUrl: imgMarkerObject,
    iconRetinaUrl: imgMarkerObject,
    iconAnchor: null,
    popupAnchor: [0, -20],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(45, 45),
    className: 'map__marker--object'
});

const markerCluster = new L.Icon({
    iconUrl: imgMarkerCluster,
    iconRetinaUrl: imgMarkerCluster,
    iconAnchor: null,
    popupAnchor: [0, -20],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(60, 60),
    className: 'map__marker--cluster'
});

class GeoMapMarkers extends Component {
    render() {
        const {
            item,
            langContext: { dict },
            locations,
            isFetchingLocations,
            map,
            actions,
            location
        } = this.props;

        const iconType = (type) => {
            return (type === 1) ? markerObject : markerCluster;
        }
    
        const clickCluster = (cluster) => {
            const zoom = map.getZoom() + 1;
            const {lat, lng} = cluster.centralLocation;
    
            if ((cluster.locationsNum > 1) && (map.getMaxZoom() > map.getZoom())) {
                map.closePopup();
                map.flyTo([lat, lng], zoom);
            } else {
                loadLocations(cluster);
            }
        }

        const loadLocations = (point) => {
            const { coveredArea } = point;
        
            actions.loadLocations(`?${setLocationsUrl(map, coveredArea, location.search)}`);
        }
        
        return (
            <Marker position={item.centralLocation} icon={ iconType(item.locationsNum) } onClick={() => clickCluster(item)}>
                { (item.locationsNum > 1) && <Tooltip direction='right' offset={[-20, 0]} opacity={1} permanent className={styles["map__tooltip--cluster"]}>
                    <span>{item.locationsNum}</span>
                </Tooltip> }
                <Popup autoPan={false} className={styles["map__popup"]} >
                    { !isFetchingLocations ? ( <ul className={styles["map__popup--list"]}>
                        {locations.map((obj) => 
                            <li key={ obj.title } className={styles["map__popup--item"]}>
                                <h5 className={styles["map__text--popup-title"]}>{ obj.title }</h5>
                                <p className={styles["map__text--popup-text"]}>{obj.dataSource} | {obj.organizationalUnit}</p>
                                <div className={styles["map__popup--buttons"]}>
                                    { obj.resourceUrl && 
                                    <div>
                                        <Button 
                                            variant="link-external"
                                            href={obj.resourceUrl}
                                            target="_blank"
                                            className={styles["map__popup--button"]}
                                        >
                                            {dict.map.buttons.showObjectOnTheSourceSite}
                                            <i className="icon-external" />
                                        </Button>
                                    </div>
                                    }
                                    { obj.gaikkUrl && 
                                    <div>
                                        <Button 
                                            variant="link-external"
                                            href={obj.gaikkUrl}
                                            target="_blank"
                                            className={styles["map__popup--button"]}
                                        >
                                            {dict.map.buttons.showObjectOnTheGAIKKSite}
                                            <i className="icon-external" />
                                        </Button>
                                    </div>
                                    }
                                </div>
                            </li>
                        )}
                    </ul>
                    ) : (
                        <div className={styles["map__popup--loading"]}>
                            <img src={imgLoading} alt={dict.map.loading} />
                        </div>
                    )}
                    
                </Popup>            
            </Marker>
        );
    };
};

GeoMapMarkers.propTypes = {
    locations: PropTypes.array,
    isFetchingLocations: PropTypes.bool,
};

const mapStateToProps = state => ({
    locations: state.map.locations,
    isFetchingLocations: state.map.isFetchingLocations,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default withContexts(withRouter(connect(mapStateToProps, mapDispatchToProps)(GeoMapMarkers)));
