import React, {Component} from 'react';

import withContexts from '@/scripts/withContext.js';

import Button from '../button/Button.js';

class HeaderAccess extends Component {

    render() {
        const { 
            setContrast,
            fontSize,
            langContext: { dict }
        } =this.props;
        return (
            <div className="header__access">
                <Button onClick={() => fontSize()} className="header__font-btn">
                    <span className="header__font-btn-span">{dict.headers.font_size}</span>
                    <i className="icon-font-reversed header__font-normal"
                        data-tip={dict.headers.font_size_tip}
                        data-type="info"
                        data-class="tt"
                    />
                    <i className="icon-font-active header__font-medium"
                        data-tip={dict.headers.font_size_tip}
                        data-type="info"
                        data-class="tt"
                    />
                    <i className="icon-font-double-active header__font-big"
                        data-tip={dict.headers.font_size_tip}
                        data-type="info"
                        data-class="tt"
                    />
                </Button>

                <Button className="header__contrast-btn" onClick={() => setContrast()}
                >
                    <span className="header__contrast-btn-span">{dict.headers.contrast}</span>
                    <i className="icon-contrast header__contrast-normal"
                        data-tip={dict.headers.contrast_tip}
                        data-type="info"
                        data-class="tt"
                    />
                    <i className="icon-contrast-active header__contrast-active"
                        data-tip={dict.headers.contrast_tip}
                        data-type="info"
                        data-class="tt"
                    />
                </Button>
            </div>
        );
    }
}

export default withContexts(
    HeaderAccess
);