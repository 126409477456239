import React from "react";

import withContexts from "@/scripts/withContext.js";

import styles from "@/components/pagination/Pagination.module.sass";

const PaginationNext = ({ setNextPage, langContext: { dict } }) => (
	<button
		className={styles["pagination__next"]}
		onClick={() => setNextPage()}
		title={dict.pagination.nextTitle}
	>
		<span>{dict.pagination.next}</span>
	</button>
);

export default withContexts(PaginationNext);
