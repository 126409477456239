import React, { Component } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import { Redirect, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "@/actions";

import withContexts from "@/scripts/withContext.js";
import debounce from "@/scripts/debounce.js";

import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import Content from "@/components/content/Content.js";
import AdvancedSearchForm from "./AdvancedSearchForm.js";

import styles from "./AdvancedSearch.module.sass";

const conjOptions = ["and", "or"];
const types = [
	"allFields",
	"title",
	"person",
	"time",
	"classification",
	"electronicDocumentType",
	"group",
	"language",
	"place",
	"event",
	"collection",
	"period",
	"material",
	"technique",
	"signature",
	"dataSource",
	"uwrUnit",
	"iconclass",
	"keywords"
];

const era = ["ad", "bc"];

class AdvancedSearch extends Component {
	static propTypes = {
		q: PropTypes.string,
		suggestions: PropTypes.object
	};

	state = {
		fireRedirect: false,
		query: ""
	};

	componentDidUpdate(nextProps) {
		//prevents unexpected redirect when going back
		if (nextProps.location !== this.props.location) {
			this.setState({
				fireRedirect: false
			});
		}
	}

	createQuery = arr => {
		let str = "";

		arr.forEach((qPart, index) => {
			const isTime = qPart.type === "time";
			let conjExp = index !== arr.length - 1 ? ` ${qPart.conj.toUpperCase()} ` : "";

			if (isTime) {
				const { from, to, fromEra, toEra, timeline, type } = qPart;

				if ((!from && !to) || timeline) {
					str += `-${type}:[*TO*]`;
				} else {
					str += `${type}:[${fromEra === "bc" ? "-" : ""}${
						from ? moment(from, "Y.MM.DD").format("Y.MM.DD") : "*"
					}TO${toEra === "bc" ? "-" : ""}${to ? moment(to, "Y.MM.DD").format("Y.MM.DD") : "*"}]`;
				}

				str += conjExp;
			} else {
				str += `${qPart.type !== "allFields" ? `${qPart.type}:(${qPart.query})` : `${qPart.query}`}${conjExp}`;
			}
		});

		return str.trim();
	};

	submitForm = values => {
		const { history, location } = this.props;
		const filledValues = values.searchQueries.filter(
			val => (val.type !== "time" && val.query) || val.type === "time"
		);

		history.push({
			pathname: "/results",
			search: "?q=" + this.createQuery(filledValues),
			state: {
				prevPath: location.pathname
			}
		});
	};

	getAutocompleteList = (prefix, field, index) => {
		const { actions } = this.props;
		actions.resetAdvancedSearchSuggestions(index);
		this.getList(prefix, field, index);
	};

	getList = debounce((prefix, field, index) => {
		const { actions } = this.props;

		if (prefix) {
			actions.getSuggestions(field === "allFields" ? "primary" : field, prefix, index);
		}
	}, 500);

	render() {
		const {
			langContext: { dict },
			suggestions,
			actions
		} = this.props;
		const { query, fireRedirect } = this.state;

		const breadcrumbsList = [{ label: "home" }, { label: dict.advanced_search.title }];

		return (
			<Content type={`light`}>
				<div className={styles["advanced-search"]}>
					<div className={styles["advanced-search__breadcrumbs"]}>
						<Breadcrumbs breadcrumbsList={breadcrumbsList} />
					</div>
					<h1 className={styles["advanced-search__header"]}>{dict.advanced_search.title}</h1>
					<div className={styles["advanced-search__text"]}>
						<h2 className={styles["advanced-search__title"]}>{dict.advanced_search.text.title}</h2>
						<div className={styles["advanced-search__content"]}>
							<span
								dangerouslySetInnerHTML={{
									__html: dict.advanced_search.text.content
								}}
							/>
							<Link to={dict.advanced_search.text.howToSearch.link} className={`${styles["advanced-search__link"]}`}>
								{dict.advanced_search.text.howToSearch.text}
							</Link>
						</div>
					</div>
					<div className={styles["advanced-search__form"]}>
						<AdvancedSearchForm
							conjOptions={conjOptions}
							types={types}
							era={era}
							submitForm={this.submitForm}
							getAutocompleteList={this.getAutocompleteList}
							resetAdvancedSearchSuggestions={actions.resetAdvancedSearchSuggestions}
							suggestions={suggestions}
							initialValues={{
								searchQueries: [
									{
										type: "allFields",
										query: "",
										conj: "and"
									},
									{
										type: "title",
										query: "",
										conj: "and"
									},
									{
										type: "personInvolved",
										query: "",
										conj: "and"
									}
								]
							}}
						/>
					</div>
					{fireRedirect && (
						<Redirect
							push
							to={{
								pathname: "/results",
								search: `?${query}`,
								state: {
									referrer: "/"
								}
							}}
						/>
					)}
				</div>
			</Content>
		);
	}
}

const mapStateToProps = state => ({
	q: state.search.params.q,
	suggestions: state.advancedSearch.suggestions
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withContexts(withRouter(AdvancedSearch)));
